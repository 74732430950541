import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { DescricaoConclusao } from '../../../../models/Helper/DescricaoConclusao';
import { TermosGenericos } from '../../../../models/Helper/TermosGenericos';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../../../utils/dialog/confirm-dialog/confirm-dialog.component';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Injuries, Term } from '../../../../models/BO/Term';
import { Validacoes } from '../../../../utils/validacoes';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { TermResult } from '../../../../models/BO/TermResult';
import { TermosDinamicos } from 'src/app/models/Helper/TermosDinamicos';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

@Component({
  selector: 'app-arteri-aorta',
  templateUrl: './arteri-aorta.component.html',
  styleUrls: ['./arteri-aorta.component.css'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
})
export class ArteriAortaComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  public htmlContent: string;
  public mask: string;
  public Subtitulo: string;
  public subtituloBloco: string;
  public ArteriaAorta: Boolean = false;
  public ArteriaRenalDireita: Boolean = false;
  public ArteriaRenalEsquerda: Boolean = false;
  public ArteriasSegmentaresDireitas: Boolean = false;
  public ArteriasSegmentaresEsquerdas: Boolean = false;
  public TabelaMetodoDireto: Boolean = false;
  public TabelaMetodoIndireto: Boolean = false;
  public bexiga: Boolean = false;
  public rimdireito: Boolean = true;
  public rimesquerdo: Boolean = false;
  public medicalReportTypeID = 34;
  public ArteriaAortaID = 164;
  public ArteriaRenalDireitaID = 165;
  public ArteriaRenalEsquerdaID = 166;
  public ArteriasSegmentaresDireitasID = 167;
  public ArteriasSegmentaresEsquerdasID = 168;
  public bexigaID = 9;
  public rimdireitoID = 10;
  public rimesquerdoID = 11;
  public indicacao: Boolean = false;
  public achadosTexto: Boolean = false;
  public cabecalhoTexto = '';
  public cabecalho:Boolean = false;
  public indicacaoTexto: string = '';
  public achadosAdTexto: string = '';
  public rodape: Boolean = false;
  public rodapeTexto: any = '';
  public lateralidade: Boolean = true;
  public generoLateralidade: string;
  public term: any = new Term();
  public objetoGrupoLesao: any;
  public termResultsAPI: Array<TermResult>;
  public termConclusionAPI: Array<TermResult>;
  public MetodoDireto = new Array<string>();
  public MetodoIndireto = new Array<any>();
  public CalculoDireto: any = 0;
  public CalculoEsquerdo: Number = 0;
  public CalculoIndiretoSeg: any = 0;
  public CalculoIndiretoSegEsq: any = 0;

  public dinamicLesions: TermosDinamicos[] = [];
  public dinamicLesion: any;
  public lesionRow: Number = 1;
  public cenarioCorrente: any = 0;
  public companyID: Number = 0;
  public injuries = new Injuries();
  public medicaoNodulo: String = '';
  public showForm: Boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  unidadeMedida: string;
  editLaudoMode: boolean = false;


  public blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: false
    },
    {
      id: this.rimdireitoID,
      name: 'rim-direito',
      titulo: 'Rim Direito',
      selected: true,
    },
    {
      id: this.rimesquerdoID,
      name: 'rim-esquerdo',
      titulo: 'Rim Esquerdo',
      selected: false,
    },
    {
      id: this.bexigaID,
      name: 'bexiga',
      titulo: 'Bexiga',
      selected: false,
    },
    {
      titulo: 'Aorta',
      id: this.ArteriaAortaID,
      name: 'Aorta',
      selected: false,
    },
    {
      titulo: 'Artéria Renal Direita',
      id: this.ArteriaRenalDireitaID,
      name: 'Artéria Renal Direita',
      selected: false,
    },
    {
      titulo: 'Artéria Renal Esquerda',
      id: this.ArteriaRenalEsquerdaID,
      name: 'Artéria Renal Esquerda',
      selected: false,
    },
    {
      titulo: 'Artérias Segmentares Direitas',
      id: this.ArteriasSegmentaresDireitasID,
      name: 'Artérias Segmentares Direitas',
      selected: false,
    },
    {
      titulo: 'Artérias Segmentares Esquerdas',
      id: this.ArteriasSegmentaresEsquerdasID,
      name: 'Artérias Segmentares Esquerdas',
      selected: false,
    },
    {
      titulo: 'Tabela Método Direto',
      id: 'tabela-metodo-direto',
      name: 'Tabela Método Direto',
      selected: false,
    },
    {
      titulo: 'Tabela Método Indireto',
      id: 'tabela-metodo-indireto',
      name: 'Tabela Método Indireto',
      selected: false,
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  listTermDefault=[
    {listTerm:[1961],blockID:164},
    {listTerm:[1951],blockID:165},
    {listTerm:[1954],blockID:166},
    {listTerm:[1957],blockID:167},
    {listTerm:[1959],blockID:168},
    {listTerm:[185],blockID:9},
    {listTerm:[236],blockID:10},
    {listTerm:[271],blockID:11}
  ]

  public Descricoes: DescricaoConclusao[] = [
    {
      value: 'Rins em topografia habitual, com forma e contornos preservados, de dimensões normais.</br>Parênquima renal com espessura e ecogenicidade preservadas. Relação córtico-medular preservada.' +
        '</br>Ausência de imagens sugestivas de cálculos renais. Não há sinais de hidronefrose.', blockID: 10, textTermID: 0, visivel: true
    },
    { value: '', blockID: 11, textTermID: 0, visivel: true },
    { value: 'Bexiga com adequada repleção, paredes regulares e finas. O conteúdo vesical é anecoico e homogêneo.', blockID: 9, textTermID: 104, visivel: true },
    {
      value: 'Aorta com trajeto, calibre e contornos preservados. Fluxo com padrão espectral e velocidades de pico sistólico (VPS) normais.',
      blockID: 164, textTermID: 0, visivel: true
    },
    {
      value: 'Artéria renal direita pérvia, com calibre preservado, apresentando fluxo com padrão espectral e velocidades de pico sistólico (VPS) normais.',
      blockID: 165, textTermID: 0, visivel: true
    },
    {
      value: 'Artéria renal esquerda pérvia, com calibre preservado, apresentando fluxo com padrão espectral e velocidades de pico sistólico (VPS) normais.',
      blockID: 166, textTermID: 0, visivel: true
    },
    {
      value: 'Artérias segmentares direitas pérvias, com morfologia e padrão de onda preservados.',
      blockID: 167, textTermID: 0, visivel: true
    },
    {
      value: 'Artérias segmentares esquerdas pérvias, com morfologia e padrão de onda preservados.',
      blockID: 168, textTermID: 0, visivel: true
    },


  ];

  public conclusoes: DescricaoConclusao[] = [
    {
      value: 'Estudo com Doppler colorido das artérias renais dentro dos limites da normalidade.',
      blockID: 0, textTermID: 0, visivel: true
    },
    { value: '', blockID: 164, textTermID: 0, visivel: true },
    { value: '', blockID: 165, textTermID: 0, visivel: true },
    { value: '', blockID: 166, textTermID: 0, visivel: true },
    { value: '', blockID: 167, textTermID: 0, visivel: true },
    { value: '', blockID: 168, textTermID: 0, visivel: true },
    { value: '', blockID: 10, textTermID: 0, visivel: true },
    { value: '', blockID: 11, textTermID: 0, visivel: true },
    { value: '', blockID: 9, textTermID: 0, visivel: true },
  ];


  genericTermsAorta: TermosGenericos[] = [
    { value: '', selected: false, termID: 1969, term: '[Calibre]' },
  ];
  genericTermsRimDireito: TermosGenericos[] = [
    {
      value: '', selected: false, termID: 237, term: '[Medida]'
    },
    {
      value: '', selected: false, termID: 238, term: '[Medida Parenquima]'
    },
    {
      value: '', selected: false, termID: 241, term: '[Medida]'
    },
    {
      value: '', selected: false, termID: 242, term: '[Medida Parenquima]'
    },
    {
      value: '', selected: false, termID: 245, term: '[Medida]'
    },
    {
      value: '', selected: false, termID: 246, term: '[Localização]'
    },
    {
      value: '', selected: false, termID: 248, term: '[Medida]'
    },
    {
      value: '', selected: false, termID: 249, term: '[Localização]'
    },
    {
      value: '', selected: false, termID: 252, term: '[Medida]',
    },
    {
      value: '', selected: false, termID: 253, term: '[Localização]'
    },
    {
      value: '', selected: false, termID: 254, term: '[Grau dilatação]'
    },
    {
      value: '', selected: false, termID: 259, term: '[Medida]'
    },
    {
      value: '', selected: false, termID: 260, term: '[Localização]'
    },
    {
      value: '', selected: false, termID: 265, term: '[Medida]'
    },
    {
      value: '', selected: false, termID: 266, term: '[Ecogenicidade]'
    },
    {
      value: '', selected: false, termID: 267, term: '[Margens]'
    },
    {
      value: '', selected: false, termID: 268, term: '[Localização]'
    },
    {
      value: '', selected: false, termID: 270, term: '[Grau]'
    }
  ];

  genericTermsRimEsquerdo: TermosGenericos[] = [
    {
      value: '', selected: false, termID: 272, term: '[Medida]'
    },
    {
      value: '', selected: false, termID: 273, term: '[Medida Parenquima]'
    },
    {
      value: '', selected: false, termID: 276, term: '[Medida]'
    },
    {
      value: '', selected: false, termID: 277, term: '[Medida Parenquima]'
    },
    {
      value: '', selected: false, termID: 280, term: '[Medida]'
    },
    {
      value: '', selected: false, termID: 281, term: '[Localização]'
    },
    {
      value: '', selected: false, termID: 283, term: '[Medida]'
    },
    {
      value: '', selected: false, termID: 284, term: '[Localização]'
    },
    {
      value: '', selected: false, termID: 286, term: '[Medida]'
    },
    {
      value: '', selected: false, termID: 287, term: '[Localização]'
    },
    {
      value: '', selected: false, termID: 288, term: '[Grau dilatação]'
    },
    {
      value: '', selected: false, termID: 293, term: '[Medida]'
    },
    {
      value: '', selected: false, termID: 294, term: '[Localização]'
    },
    {
      value: '', selected: false, termID: 298, term: '[Medida]'
    },
    {
      value: '', selected: false, termID: 299, term: '[Ecogenicidade]'
    },
    {
      value: '', selected: false, termID: 300, term: '[Margens]'
    },
    {
      value: '', selected: false, termID: 301, term: '[Localização]'
    },
    {
      value: '', selected: false, termID: 303, term: '[Grau]'
    }
  ];

  public switchFormReport = true;

  constructor(private readonly _selecteditememiter: SelectedItemEmiter,
    private readonly _toastr: ToastrManager,
    private readonly _copyPaste: CopyPaste,
    private _validacoes: Validacoes,
    private readonly _laudoservice: LaudosService,
    private readonly _serviceMedicalReportLog: MedicalReportLogService,
    private readonly _serviceMedicalReport: MedicalReportService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    public readonly dialog: MatDialog,
    public _addText: AddText,
    private _config: ConfigLaudoService,
    private dataService:DataTermService) { }

  ngOnInit() {
    this._pouchService.loadDatabase("arteria-renal-aortaAnalise", this.Descricoes, this.conclusoes);
    this.utilizarEditor();
    this.resetaDialog();
    this.Subtitulo = 'Rim direito';
    this.subtituloBloco = 'Rim direito';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });

    const listBlocks = [
      this.ArteriaAortaID,
      this.ArteriaRenalDireitaID,
      this.ArteriaRenalEsquerdaID,
      this.ArteriasSegmentaresDireitasID,
      this.ArteriasSegmentaresEsquerdasID,
      this.bexigaID,
      this.rimdireitoID,
      this.rimesquerdoID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "arteria-renal-aortaMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "arteriasRenais");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-arteria-aorta-doppler');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-arteria-aorta-doppler');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo(){
    this._pouchService.getAll("arteria-renal-aortaAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.Descricoes[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.achadosAdTexto = "";
    this.rodapeTexto = "";
    this.showForm = false;
    this.MetodoDireto = [];
    this.MetodoIndireto = [];
    this.CalculoDireto = 0;
    this.CalculoEsquerdo = 0;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  public selecionar(itemSelecionado): any {
    const arrayAtualizado = this._selecteditememiter.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }

    this.lateralidade = (itemSelecionado.id === 'lateralidade');
    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.rimdireito = (itemSelecionado.id === this.rimdireitoID);
    this.rimesquerdo = (itemSelecionado.id === this.rimesquerdoID);
    this.bexiga = (itemSelecionado.id === this.bexigaID);
    this.ArteriaAorta = (itemSelecionado.id === this.ArteriaAortaID);
    this.ArteriaRenalDireita = (itemSelecionado.id === this.ArteriaRenalDireitaID);
    this.ArteriaRenalEsquerda = (itemSelecionado.id === this.ArteriaRenalEsquerdaID);
    this.ArteriasSegmentaresDireitas = (itemSelecionado.id === this.ArteriasSegmentaresDireitasID);
    this.ArteriasSegmentaresEsquerdas = (itemSelecionado.id === this.ArteriasSegmentaresEsquerdasID);
    this.TabelaMetodoDireto = (itemSelecionado.id === 'tabela-metodo-direto');
    this.TabelaMetodoIndireto = (itemSelecionado.id === 'tabela-metodo-indireto');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.achadosTexto = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;
    this.dinamicLesions = [];


    this.genericTermsAorta = [
      { value: '', selected: false, termID: 1969, term: '[Calibre]' },
    ];

    this.genericTermsRimDireito = [
      {
        value: '', selected: false, termID: 237, term: '[Medida]'
      },
      {
        value: '', selected: false, termID: 238, term: '[Medida Parenquima]'
      },
      {
        value: '', selected: false, termID: 241, term: '[Medida]'
      },
      {
        value: '', selected: false, termID: 242, term: '[Medida Parenquima]'
      },
      {
        value: '', selected: false, termID: 245, term: '[Medida]'
      },
      {
        value: '', selected: false, termID: 246, term: '[Localização]'
      },
      {
        value: '', selected: false, termID: 248, term: '[Medida]'
      },
      {
        value: '', selected: false, termID: 249, term: '[Localização]'
      },
      {
        value: '', selected: false, termID: 252, term: '[Medida]',
      },
      {
        value: '', selected: false, termID: 253, term: '[Localização]'
      },
      {
        value: '', selected: false, termID: 254, term: '[Grau dilatação]'
      },
      {
        value: '', selected: false, termID: 259, term: '[Medida]'
      },
      {
        value: '', selected: false, termID: 260, term: '[Localização]'
      },
      {
        value: '', selected: false, termID: 265, term: '[Medida]'
      },
      {
        value: '', selected: false, termID: 266, term: '[Ecogenicidade]'
      },
      {
        value: '', selected: false, termID: 267, term: '[Margens]'
      },
      {
        value: '', selected: false, termID: 268, term: '[Localização]'
      },
      {
        value: '', selected: false, termID: 270, term: '[Grau]'
      }
    ];

    this.genericTermsRimEsquerdo = [
      {
        value: '', selected: false, termID: 272, term: '[Medida]'
      },
      {
        value: '', selected: false, termID: 273, term: '[Medida Parenquima]'
      },
      {
        value: '', selected: false, termID: 276, term: '[Medida]'
      },
      {
        value: '', selected: false, termID: 277, term: '[Medida Parenquima]'
      },
      {
        value: '', selected: false, termID: 280, term: '[Medida]'
      },
      {
        value: '', selected: false, termID: 281, term: '[Localização]'
      },
      {
        value: '', selected: false, termID: 283, term: '[Medida]'
      },
      {
        value: '', selected: false, termID: 284, term: '[Localização]'
      },
      {
        value: '', selected: false, termID: 286, term: '[Medida]'
      },
      {
        value: '', selected: false, termID: 287, term: '[Localização]'
      },
      {
        value: '', selected: false, termID: 288, term: '[Grau dilatação]'
      },
      {
        value: '', selected: false, termID: 293, term: '[Medida]'
      },
      {
        value: '', selected: false, termID: 294, term: '[Localização]'
      },
      {
        value: '', selected: false, termID: 298, term: '[Medida]'
      },
      {
        value: '', selected: false, termID: 299, term: '[Ecogenicidade]'
      },
      {
        value: '', selected: false, termID: 300, term: '[Margens]'
      },
      {
        value: '', selected: false, termID: 301, term: '[Localização]'
      },
      {
        value: '', selected: false, termID: 303, term: '[Grau]'
      }
    ];

    this.lesionRow = 1;
    this.medicaoNodulo = '';

  }

  public incluirLesoesDinamicas($event) {
    const lesoesValue = this._validacoes.formataDecimal($event.elemento.value);

    if ($event.linha == 1 && ($event.posicao == 0 || $event.posicao == 1)) {
      if ($event.isPrimeiraLesao == true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaLesoes($event.elemento);
      }
    }

    switch ($event.elemento.id) {
      case '209':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 209,
          lesionID: 20,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]',
          termView: 'slNoduloEcogenicidade'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '198':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 198,
          lesionID: 20,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Segmento]',
          termView: 'slNoduloSegmento'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '210':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 210,
          lesionID: 20,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Contorno]',
          termView: 'slNoduloContorno'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;

      case '43':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 43,
          lesionID: 40,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medindo]',
          termView: 'inCistoMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '207':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 207,
          lesionID: 40,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Segmento]',
          termView: 'slCistoSegmento'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;

      case '59':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 59,
          lesionID: 58,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medindo]',
          termView: 'inCalcificacaoMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '208':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 208,
          lesionID: 58,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Segmento]',
          termView: 'slCalcificacaoSegmento'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;

      case '202':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 202,
          lesionID: 191,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medindo]',
          termView: 'inColecaoMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '203':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 203,
          lesionID: 191,
          linha: $event.linha,
          posicao: 0,
          term: '[Segmento]',
          termView: 'slColecaoSegmento'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '195':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 195,
          lesionID: 193,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medindo]',
          termView: 'inHemangiomasMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '197':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 197,
          lesionID: 193,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Segmento]',
          termView: 'slHemangiomasSegmento'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '43':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 43,
          lesionID: 184,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medindo]',
          termView: 'inCistoMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '207':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 207,
          lesionID: 184,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Segmento]',
          termView: 'slCistoSegmento'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '259':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 259,
          lesionID: 255,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida]',
          termView: 'inDimensao1Cisto'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '260':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 260,
          lesionID: 255,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizaco1Cisto'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '293':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 293,
          lesionID: 289,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida]',
          termView: 'inDimensao1Cisto'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '294':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 294,
          lesionID: 289,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizaco1Cisto'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '265':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 265,
          lesionID: 262,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida]',
          termView: 'inDimensaoNodulo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '266':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 266,
          lesionID: 262,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]',
          termView: 'slEcogenicidadeNodulo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '267':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 267,
          lesionID: 262,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Margens]',
          termView: 'slMargensNodulo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '268':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 268,
          lesionID: 262,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizacaoNodulo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '293':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 293,
          lesionID: 289,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida]',
          termView: 'inDimensao1Cisto'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '294':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 294,
          lesionID: 289,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizaco1Cisto'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '298':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 298,
          lesionID: 295,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida]',
          termView: 'inDimensaoNodulo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '299':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 299,
          lesionID: 295,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]',
          termView: 'slEcogenicidadeNodulo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '300':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 300,
          lesionID: 295,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Margens]',
          termView: 'slMargensNodulo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '301':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 301,
          lesionID: 295,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizacaoNodulo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '245':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 245,
          lesionID: 244,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida]',
          termView: 'inDimensaoCalcNaoObstrutivo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '246':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 246,
          lesionID: 244,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizacoCalcNaoObstrutivo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '280':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 280,
          lesionID: 279,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida]',
          termView: 'inDimensaoCalcNaoObstrutivo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '281':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 281,
          lesionID: 279,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizacoCalcNaoObstrutivo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }

    this.lesionRow = $event.linha;
  }

  public enviaLesoes($event) {

    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {

      //Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      //adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  public adicionaSelecaoRimDireito($event) {
    this.genericTermsRimDireito.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  public adicionaSelecaoRimEsquerdo($event) {
    this.genericTermsRimEsquerdo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  public MontaDescricaoAorta(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1969) {
        this.genericTermsAorta.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoAorta(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1969) {
        this.genericTermsAorta.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public adicionaSelecaoAorta($event): any {
    this.genericTermsAorta.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public consultarAPI(): any {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
    let desc;
    if (this.ArteriaAorta) {
      this.term.blockID = this.ArteriaAortaID;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.Descricoes.forEach(obj => {
          if (obj.blockID === this.ArteriaAortaID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoAorta(resultado.text);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.Descricoes.findIndex(x => x.blockID === this.ArteriaAortaID);
            if (index > -1) {
              this.Descricoes[index].value = obj.value;
              this.Descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        let obj = {
          value: '',
          blockID: this.ArteriaAortaID,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclussaoAorta(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const descricoes = this.conclusoes.find(x => x.blockID === this.ArteriaAortaID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaAortaID) {
              conclusaoNull.value = obj.value;
              descricoes.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            descricoes.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          descricoes.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.ArteriaAortaID,
            descricao: desc,
            conclusaoNull: conclusaoNull.value
          })
        }
      });

    } else if (this.ArteriaRenalDireita) {
      this.term.blockID = this.ArteriaRenalDireitaID;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.Descricoes.forEach(obj => {
          if (obj.blockID === this.ArteriaRenalDireitaID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            this.termResultsAPI.forEach(resultado => {
              obj.value = resultado.text;
            });

            let index = this.Descricoes.findIndex(x => x.blockID === this.ArteriaRenalDireitaID);
            if (index > -1) {
              this.Descricoes[index].value = obj.value;
              this.Descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        let obj = {
          value: '',
          blockID: this.ArteriaRenalDireitaID,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = resultado.conclusion;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const descricoes = this.conclusoes.find(x => x.blockID === this.ArteriaRenalDireitaID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaRenalDireitaID) {
              conclusaoNull.value = obj.value;
              descricoes.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            descricoes.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          descricoes.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.ArteriaRenalDireitaID,
            descricao: desc,
            conclusaoNull: conclusaoNull.value
          })
        }
      });

    } else if (this.ArteriaRenalEsquerda) {
      this.term.blockID = this.ArteriaRenalEsquerdaID;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.Descricoes.forEach(obj => {
          if (obj.blockID === this.ArteriaRenalEsquerdaID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            this.termResultsAPI.forEach(resultado => {
              obj.value = resultado.text;
            });

            let index = this.Descricoes.findIndex(x => x.blockID === this.ArteriaRenalEsquerdaID);
            if (index > -1) {
              this.Descricoes[index].value = obj.value;
              this.Descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        let obj = {
          value: '',
          blockID: this.ArteriaRenalEsquerdaID,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = resultado.conclusion;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const descricoes = this.conclusoes.find(x => x.blockID === this.ArteriaRenalEsquerdaID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaRenalEsquerdaID) {
              conclusaoNull.value = obj.value;
              descricoes.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            descricoes.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          descricoes.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.ArteriaRenalEsquerdaID,
            descricao: desc,
            conclusaoNull: conclusaoNull.value
          })
        }
      });

    } else if (this.ArteriasSegmentaresDireitas) {
      this.term.blockID = this.ArteriasSegmentaresDireitasID;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.Descricoes.forEach(obj => {
          if (obj.blockID === this.ArteriasSegmentaresDireitasID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            this.termResultsAPI.forEach(resultado => {
              obj.value = resultado.text;
            });

            let index = this.Descricoes.findIndex(x => x.blockID === this.ArteriasSegmentaresDireitasID);
            if (index > -1) {
              this.Descricoes[index].value = obj.value;
              this.Descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        let obj = {
          value: '',
          blockID: this.ArteriasSegmentaresDireitasID,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = resultado.conclusion;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const descricoes = this.conclusoes.find(x => x.blockID === this.ArteriasSegmentaresDireitasID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriasSegmentaresDireitasID) {
              conclusaoNull.value = obj.value;
              descricoes.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            descricoes.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          descricoes.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.ArteriasSegmentaresDireitasID,
            descricao: desc,
            conclusaoNull: conclusaoNull.value
          })
        }
      });

    } else if (this.ArteriasSegmentaresEsquerdas) {
      this.term.blockID = this.ArteriasSegmentaresEsquerdasID;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.Descricoes.forEach(obj => {
          if (obj.blockID === this.ArteriasSegmentaresEsquerdasID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            this.termResultsAPI.forEach(resultado => {
              obj.value = resultado.text;
            });

            let index = this.Descricoes.findIndex(x => x.blockID === this.ArteriasSegmentaresEsquerdasID);
            if (index > -1) {
              this.Descricoes[index].value = obj.value;
              this.Descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        let obj = {
          value: '',
          blockID: this.ArteriasSegmentaresEsquerdasID,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = resultado.conclusion;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const descricoes = this.conclusoes.find(x => x.blockID === this.ArteriasSegmentaresEsquerdasID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriasSegmentaresEsquerdasID) {
              conclusaoNull.value = obj.value;
              descricoes.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            descricoes.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          descricoes.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.ArteriasSegmentaresEsquerdasID,
            descricao: desc,
            conclusaoNull: conclusaoNull.value
          })
        }
      });

    }
    if (this.rimdireito) {
      this.term.blockID = this.rimdireitoID;

      this.term.injuries.push(this.injuries);
      this.cenarioCorrente++;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.Descricoes.forEach(obj => {
          if (obj.blockID === this.rimdireitoID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoRimDireito(resultado.text, contadorTexto);
              espaco = '\n';
              contadorTexto++;
            });

            let index = this.Descricoes.findIndex(x => x.blockID == this.rimdireitoID);
            if (index > -1) {
              this.Descricoes[index].value = obj.value;
              this.Descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        let obj = {
          value: '',
          blockID: this.rimdireitoID,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.blockID = this.rimesquerdoID;
            obj.value = obj.value + espaco + this.MontarConclusaoRimDireito(resultado.conclusion, contadorTexto);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const descricoes = this.conclusoes.find(x => x.blockID === this.rimdireitoID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.rimdireitoID) {
              conclusaoNull.value = obj.value;
              descricoes.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            descricoes.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          descricoes.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.rimdireitoID,
            descricao: desc,
            conclusaoNull: conclusaoNull.value
          })
        }
      });

    } else if (this.rimesquerdo) {
      this.term.blockID = this.rimesquerdoID;

      this.term.injuries.push(this.injuries);
      this.cenarioCorrente++;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.Descricoes.forEach(obj => {
          if (obj.blockID === this.rimesquerdoID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoRimEsquerdo(resultado.text, contadorTexto);
              espaco = '\n';
              contadorTexto++;
            });

            let index = this.Descricoes.findIndex(x => x.blockID == this.rimesquerdoID);
            if (index > -1) {
              this.Descricoes[index].value = obj.value;
              this.Descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        let obj = {
          value: '',
          blockID: this.rimesquerdoID,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.blockID = this.rimesquerdoID;
            obj.value = obj.value + espaco + this.MontarConclusaoRimEsquerdo(resultado.conclusion, contadorTexto);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });
        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const descricoes = this.conclusoes.find(x => x.blockID === this.rimesquerdoID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.rimesquerdoID) {
              conclusaoNull.value = obj.value;
              descricoes.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            descricoes.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          descricoes.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.rimesquerdoID,
            descricao: desc,
            conclusaoNull: conclusaoNull.value
          })
        }
      });
    } else if (this.bexiga) {
      this.term.blockID = this.bexigaID;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.Descricoes.forEach(obj => {
          if (obj.blockID === this.bexigaID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoBexiga(resultado.text, contadorTexto);
              espaco = '\n';
              contadorTexto++;
            });

            let index = this.Descricoes.findIndex(x => x.blockID == this.bexigaID);
            if (index > -1) {
              this.Descricoes[index].value = obj.value;
              this.Descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        let obj = {
          value: '',
          blockID: this.bexigaID,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = resultado.conclusion;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              obj.blockID = 0;
              obj.value = '';
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const descricoes = this.conclusoes.find(x => x.blockID === this.bexigaID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.bexigaID) {
              conclusaoNull.value = obj.value;
              descricoes.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            descricoes.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          descricoes.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.bexigaID,
            descricao: desc,
            conclusaoNull: conclusaoNull.value
          })
        }
      });
      }
    }
  }

  MontaDescricaoRimEsquerdo(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoRimEsquerdoDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoRimEsquerdoDescNormal(texto, contadorTexto);
    }
    return texto;
  }

  TextoRimEsquerdoDescNormal(texto, contadorTexto) {
    // Descrição para lesões normais (1 ou múltiplas)
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';

    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '\n' + textoSplit[1];
    }

    if (contadorTexto == 0) { // Caso seja a primeira lesão
      this.term.listTermID.forEach(term => {
        if (term >= 271 && term <= 303) {
          this.genericTermsRimEsquerdo.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)

      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (term >= 271 && term <= 303) {
          this.genericTermsRimEsquerdo.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    }
    return texto;
  }

  TextoRimEsquerdoDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    // Lista term
    this.term.listTermID.forEach(term => {
      if (+term === 272 || +term === 273 || +term === 276 || +term === 277 || +term === 303) {
        this.genericTermsRimEsquerdo.forEach(generic => {
          if (+generic.termID === +term) {
            textoRetorno = textoRetorno.replace(generic.term, generic.value);
          }
        });
      }
    });

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID >= 271 && item.termID <= 303) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico + '\n';

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
          }

          if (item.termID >= 271 && item.termID <= 303) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
    //   return lesaoID != din.lesionID;
    // });

    return texto;
  }

  MontarConclusaoRimEsquerdo(texto, contadorTexto) {
    let textoSplit = '';
    let textoRetorno = '';

    if (contadorTexto == 0) { // Caso seja a primeira lesão
      textoSplit = texto.split('|', 2);
      if (textoSplit.length > 1) {
        texto = textoSplit[0] + '\n' + textoSplit[1];
      }
      this.term.listTermID.forEach(term => {
        if (term == 272 || term == 273 || term == 276 || term == 277
          || term == 280 || term == 281 || term == 283 || term == 284
          || term == 286 || term == 287 || term == 288 || term == 293
          || term == 294 || term == 298 || term == 299 || term == 300
          || term == 301 || term == 303) {
          this.genericTermsRimEsquerdo.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (term == 272 || term == 273 || term == 276 || term == 277
          || term == 280 || term == 281 || term == 283 || term == 284
          || term == 286 || term == 287 || term == 288 || term == 293
          || term == 294 || term == 298 || term == 299 || term == 300
          || term == 301 || term == 303) {
          this.genericTermsRimEsquerdo.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaDescricaoRimDireito(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoRimDireitoDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoRimDireitoDescNormal(texto, contadorTexto);
    }
    return texto;
  }

  TextoRimDireitoDescNormal(texto, contadorTexto) {
    // Descrição para lesões normais (1 ou múltiplas)
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';

    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '\n' + textoSplit[1];
    }

    if (contadorTexto == 0) { // Caso seja a primeira lesão
      this.term.listTermID.forEach(term => {
        if (term == 237 || term == 238 || term == 241 || term == 242
          || term == 265 || term == 266 || term == 267 || term == 268
          || term == 245 || term == 246 || term == 248 || term == 249
          || term == 252 || term == 253 || term == 254 || term == 259
          || term == 260 || term == 270) {
          this.genericTermsRimDireito.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)

      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (term == 237 || term == 238 || term == 241 || term == 242
          || term == 265 || term == 266 || term == 267 || term == 268
          || term == 245 || term == 246 || term == 248 || term == 249
          || term == 252 || term == 253 || term == 254 || term == 259
          || term == 260 || term == 270) {
          this.genericTermsRimDireito.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    }
    return texto;
  }

  TextoRimDireitoDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    // Lista term
    this.term.listTermID.forEach(term => {
      if (+term === 237 || +term === 238 || +term === 241 || +term === 242 || +term === 270) {
        this.genericTermsRimDireito.forEach(generic => {
          if (+generic.termID === +term) {
            textoRetorno = textoRetorno.replace(generic.term, generic.value);
          }
        });
      }
    });

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID == 237 || item.termID == 238 || item.termID == 241 || item.termID == 242
            || item.termID == 265 || item.termID == 266 || item.termID == 267 || item.termID == 268
            || item.termID == 245 || item.termID == 246 || item.termID == 248 || item.termID == 249
            || item.termID == 252 || item.termID == 253 || item.termID == 254 || item.termID == 259
            || item.termID == 260 || item.termID == 270) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico + '\n';

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
          }

          if (item.termID == 237 || item.termID == 238 || item.termID == 241 || item.termID == 242
            || item.termID == 265 || item.termID == 266 || item.termID == 267 || item.termID == 268
            || item.termID == 245 || item.termID == 246 || item.termID == 248 || item.termID == 249
            || item.termID == 252 || item.termID == 253 || item.termID == 254 || item.termID == 259
            || item.termID == 260 || item.termID == 270) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
    //   return lesaoID != din.lesionID;
    // });

    return texto;
  }

  MontarConclusaoRimDireito(texto, contadorTexto) {
    let textoSplit = '';
    let textoRetorno = '';

    // if (contadorTexto === 0 && this.conclusoes.length <= 1) { // Caso seja a primeira lesão
    if (contadorTexto === 0) { // Caso seja a primeira lesão
      textoSplit = texto.split('|', 2);
      if (textoSplit.length > 1) {
        texto = textoSplit[0] + '\n' + textoSplit[1];
      }
      this.term.listTermID.forEach(term => {
        if (term == 237 || term == 238 || term == 241 || term == 242
          || term == 265 || term == 266 || term == 267 || term == 268
          || term == 245 || term == 246 || term == 248 || term == 249
          || term == 252 || term == 253 || term == 254 || term == 259
          || term == 260 || term == 270) {
          this.genericTermsRimDireito.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (term == 237 || term == 238 || term == 241 || term == 242
          || term == 265 || term == 266 || term == 267 || term == 268
          || term == 245 || term == 246 || term == 248 || term == 249
          || term == 252 || term == 253 || term == 254 || term == 259
          || term == 260 || term == 270) {
          this.genericTermsRimDireito.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoBexiga(texto, contadorTexto) {
    return texto;
  }

  MontarConclusaoBexiga(texto) {
    let textoSplit = '';

    textoSplit = texto.split('|', 2);
    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '\n' + textoSplit[1];
    }
    return texto;
  }

  public atribuiMedidasDireto($element): any {
    // Preenchendo as medidas
    let accDir, aciDir, arrDir, accEsq, aciEsq, arrEsq: number;
    let value = this._validacoes.formataDecimal($element.value);

    if ($element.id === 'ACCAortaCm') {
      this.MetodoDireto[0] = value;
    } else if ($element.id === 'ACCRenalDirCm') {
      this.MetodoDireto[1] = value;
    } else if ($element.id === 'ACCRenalEsqCm') {
      this.MetodoDireto[2] = value;
    } else if ($element.id === 'ACCRRADir') {
      this.MetodoDireto[3] = value;
    } else if ($element.id === 'ACCRRAEsq') {
      this.MetodoDireto[4] = value;
    }

    //Logica para calcular a relção
    if (this.MetodoDireto[0] && this.MetodoDireto[1]) {

      accDir = parseFloat(this.MetodoDireto[0].replace(',', '.'));
      aciDir = parseFloat(this.MetodoDireto[1].replace(',', '.'));


      this.CalculoDireto = aciDir / accDir;

    } else if (this.CalculoDireto) {

      this.CalculoDireto = 0;
    }

    if (this.MetodoDireto[0] && this.MetodoDireto[1] && this.MetodoDireto[3]) {

      accDir = parseFloat(this.MetodoDireto[0].replace(',', '.'));
      aciDir = parseFloat(this.MetodoDireto[1].replace(',', '.'));
      arrDir = parseFloat(this.MetodoDireto[3].replace(',', '.'));

      this.CalculoDireto = arrDir;

    }


    if (this.MetodoDireto[0] && this.MetodoDireto[2]) {

      accEsq = parseFloat(this.MetodoDireto[0].replace(',', '.'));
      aciEsq = parseFloat(this.MetodoDireto[2].replace(',', '.'));


      this.CalculoEsquerdo = accEsq / aciEsq;

    } else if (this.CalculoEsquerdo) {

      this.CalculoEsquerdo = 0;
    }

    if (this.MetodoDireto[0] && this.MetodoDireto[2] && this.MetodoDireto[4]) {

      accEsq = parseFloat(this.MetodoDireto[0].replace(',', '.'));
      aciEsq = parseFloat(this.MetodoDireto[2].replace(',', '.'));
      arrEsq = parseFloat(this.MetodoDireto[4].replace(',', '.'));

      this.CalculoEsquerdo = arrEsq;

    }
  }

  public atribuiMedidasIndereto($element): any {
    // Preenchendo as medidas
    // let accDir, aciDir, accEsq, aciEsq: number;
    const value = this._validacoes.formataDecimal($element.value);

    if ($element.id === 'ACCRRSDir') {
      this.MetodoIndireto[0] = value;
    } else if ($element.id === 'ACCRRSEsq') {
      this.MetodoIndireto[1] = value;
    } else if ($element.id === 'ACCAceleracaoSegmentarDir') {
      this.MetodoIndireto[2] = value;
    } else if ($element.id === 'ACCAceleracaoSegmentarEsq') {
      this.MetodoIndireto[3] = value;
    } else if ($element.id === 'ACCTempoDeAceleracaoDir') {
      this.MetodoIndireto[4] = value;
    } else if ($element.id === 'ACCTempoDeAceleracaoEsq') {
      this.MetodoIndireto[5] = value;
    } else if ($element.id === 'ACCIrDir') {
      this.MetodoIndireto[6] = value;
    } else if ($element.id === 'ACCIrEsq') {
      this.MetodoIndireto[7] = value;
    }
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape
	}: IAtualizaLinha): void {
    debugger;
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.Descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
            header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {

          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
            footer.innerHTML = rodape;
          }
        }

			} else if (indicacao || achados) {
				if(indicacao === undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados === undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor(){
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    if(this.indicacaoTexto){
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-indicacao`,
          'name': ''
        },
        'data': {
          'text': `<p>${this.indicacaoTexto}</p>`,
        }
      });
    }

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.Descricoes.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });

    this.Descricoes.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-tabela-metodo-direto`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.TableMetodoDireto()}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-tabela-metodo-indireto`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.TableMetodoIndireto()}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao, index) => {
      if (conclusao.value && this.Descricoes[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

  }

  public adicionaTextoOldEditor(): any {
    let i;
    let maskSplit = this.mask.split('|');


    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.indicacaoTexto;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>';

    this.htmlContent += maskSplit[1];

    // POG - AJUSTAR ISSO ass.:Gabs
    if (this.Descricoes[0].textTermID != 0 && this.Descricoes[1].textTermID == 0) {
      this.Descricoes[1].value = 'Rim esquerdo em topografia habitual, com forma e contornos preservados, de dimensões normais.</br>Parênquima renal esquerdo com espessura e ecogenicidade preservadas. Relação córtico-medular preservada.</br>Ausência de imagens sugestivas de cálculos no rim esquerdo. Não há sinais de hidronefrose à esquerda.'.toString();
      this.Descricoes[1].textTermID = 187;
    } else if (this.Descricoes[0].textTermID == 0 && this.Descricoes[1].textTermID != 0) {
      this.Descricoes[0].value = 'Rim direito em topografia habitual, com forma e contornos preservados, de dimensões normais.</br>Parênquima renal direito com espessura e ecogenicidade preservadas. Relação córtico-medular preservada.</br>Ausência de imagens sugestivas de cálculos no rim direito. Não há sinais de hidronefrose à direita.'.toString();
      this.Descricoes[0].textTermID = 186;
    }

    for (i = 0; i < this.Descricoes.length; i++) {
      var newValue = this.Descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.Descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.Descricoes.length; i++) {
      if (this.Descricoes[i].value) {
        this.htmlContent += '<br/>' + this.Descricoes[i].value + '<br/>';
      }
    }
    // Tabela Direto
    this.htmlContent += '<br/>' + this.TableMetodoDireto() + '<br/>';

    // Tabela Indireto
    this.htmlContent += this.TableMetodoIndireto() + '<br/>';

    this.htmlContent += maskSplit[2];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }

    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';
    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        achados: this.rodapeTexto
      });
    }
  }

  public RetornaConclusaoNull(Texto): any {
    let texto = '';
    texto = 'Exame dentro dos padrões da normalidade.';
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  public TableMetodoDireto(): any {
    let strTable = '<style>' +
      'table {' +
      'width: 80%;' +
      '}' +
      'table, th, td {' +
      'border: 1px solid #c2c0c2;' +
      'border-collapse: collapse;' +
      'th, td {' +
      '  padding: 5px;' +
      '  text-align: left;' +
      '}' +
      '</style>' +
      '<table>';

    if (this.MetodoDireto[0]) {
      strTable += '  <tr>';
      strTable += '    <th></th>';
      strTable += '    <th>MÉTODO DIRETO</th>';
      strTable += '    <th></th>' +
        '  </tr>';
    }

    if (this.MetodoDireto[0]) {

      strTable += '  <tr>' +
        '    <td>Aorta (VPS)</td>';
      strTable += '    <td>' + this.MetodoDireto[0] + ' '+ this.unidadeMedida +'/s</td>';
      strTable += '    <td> </td>';
      strTable += '  </tr>';
    }

    if (this.MetodoDireto[1]) {

      strTable += '  <tr>' +
        '    <td>Renal Direita (VPS)</td>';
      strTable += '    <td>' + this.MetodoDireto[1] + ' '+ this.unidadeMedida +'/s</td>';
      if(this.unidadeMedida == 'mm'){
        strTable += '     <td>normal < 1500-2000 mm/s</td>';
      }else{
        strTable += '     <td>normal < 150-200 cm/s</td>';
      }

      strTable += '  </tr>';
    }

    if (this.MetodoDireto[2]) {

      strTable += '  <tr>' +
        '    <td>Renal Esquerda (VPS)</td>';
      strTable += '    <td>' + this.MetodoDireto[2] + ' '+ this.unidadeMedida +'/s</td>';
      if(this.unidadeMedida == 'mm'){
        strTable += '     <td>normal < 1500-2000 mm/s</td>';
      }else{
        strTable += '     <td>normal < 150-200 cm/s</td>';
      }
      strTable += '  </tr>';
    }

    if (this.CalculoDireto > 0) {

      strTable += '  <tr>' +
        '    <td>Relação Renal Direita / Aorta</td>';
      strTable += '<td>' + this._validacoes.formataDecimal(this.CalculoDireto.toFixed(1)) + '</td>';
      strTable += '     <td>normal < 3,0 - 3,5</td>';
      strTable += '  </tr>';
    }

    if (this.CalculoEsquerdo > 0) {

      strTable += '  <tr>' +
        '    <td>Relação Renal Esquerda / Aorta</td>';
      strTable += '<td>' + this._validacoes.formataDecimal(this.CalculoEsquerdo.toFixed(1)) + '</td>';
      strTable += '     <td>normal < 3,0 - 3,5</td>';
      strTable += '  </tr>';
    }

    strTable += '</tr>' +
      '</table>';

    return strTable;
  }



  public TableMetodoIndireto(): any {
    let strTable = '<style>' +
      'table {' +
      'width: 80%;' +
      '}' +
      'table, th, td {' +
      'border: 1px solid #c2c0c2;' +
      'border-collapse: collapse;' +
      'th, td {' +
      '  padding: 5px;' +
      '  text-align: left;' +
      '}' +
      '</style>' +
      '<table>';
    if (this.MetodoIndireto[0]) {
      strTable += '  <tr>';
      strTable += '    <th></th>';
      strTable += '    <th>MÉTODO INDIRETO</th>';
      strTable += '    <th></th>';
      strTable += '  </tr>';
    }
    if (this.MetodoIndireto[0]) {
      strTable += '  <tr>' +
        '    <td>Relação Renal/ Segmentar Direita (RRS)</td>';
      strTable += '    <td>' + this.MetodoIndireto[0] + '</td>';
      strTable += '     <td>normal < 5,0</td>';
      strTable += '  </tr>';

    }

    if (this.MetodoIndireto[1]) {
      strTable += '  <tr>' +
        '    <td>Relação Renal/ Segmentar Esquerda (RRS)</td>';
      strTable += '    <td>' + this.MetodoIndireto[1] + '</td>';
      strTable += '     <td>normal < 5,0</td>';
      strTable += '  </tr>';
    }

    if (this.MetodoIndireto[2]) {
      strTable += '  <tr>' +
        '    <td>Aceleração sistólica inicial segmentar Direita</td>';
      strTable += '    <td>' + this.MetodoIndireto[2] + ' '+ this.unidadeMedida +'/s²</td>';
      if(this.unidadeMedida == 'mm'){
        strTable += '     <td>normal > 3000 mm/s²</td>';
      }else{
        strTable += '     <td>normal > 300 cm/s²</td>';
      }
      strTable += '  </tr>';
    }

    if (this.MetodoIndireto[3]) {
      strTable += '  <tr>' +
        '    <td>Aceleração sistólica inicial segmentar Esquerda</td>';
      strTable += '    <td>' + this.MetodoIndireto[3] + ' '+ this.unidadeMedida +'/s²</td>';
      if(this.unidadeMedida == 'mm'){
        strTable += '     <td>normal > 3000 mm/s²</td>';
      }else{
        strTable += '     <td>normal > 300 cm/s²</td>';
      }
      strTable += '  </tr>';
    }

    if (this.MetodoIndireto[4]) {
      strTable += '  <tr>' +
        '    <td>Tempo de aceleração sistólica inicial Direita</td>';
      strTable += '    <td>' + this.MetodoIndireto[4] + ' seg</td>';
      strTable += '     <td>normal < 0,07-0,08 seg</td>';
      strTable += '  </tr>';
    }

    if (this.MetodoIndireto[5]) {
      strTable += '</tr>' +
        '    <td>Tempo de aceleração sistólica inicial Esquerda</td>';
      strTable += '    <td>' + this.MetodoIndireto[5] + ' seg</td>';
      strTable += '     <td>normal < 0,07-0,08 seg</td>';
      strTable += '  </tr>';
    }


    if (this.MetodoIndireto[6]) {
      strTable += '</tr>' +
        '    <td>Índice de Resistência (segmentar Direita)</td>';
      strTable += '    <td>' + this.MetodoIndireto[6] + '</td>';
      strTable += '     <td>normal < 0,8</td>';
      strTable += '  </tr>';
    }

    if (this.MetodoIndireto[7]) {
      strTable += '</tr>' +
        '    <td>Índice de Resistência (segmentar Esquerda)</td>';
      strTable += '    <td>' + this.MetodoIndireto[7] + '</td>';
      strTable += '     <td>normal < 0,8</td>';
      strTable += '  </tr>';
    }

    strTable += '</tr>' +
      '</table>';

    return strTable;
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }
}
