import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Validacoes } from '../../../../../../utils/validacoes';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-hernias-inguinal',
  templateUrl: './hernias-inguinal.component.html',
  styleUrls: ['./hernias-inguinal.component.css']
})
export class HerniasInguinalComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();


  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdAlargamentoCanalInguinal') rdAlargamentoCanalInguinal: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  // Hérnia Inguinal
  @ViewChild('ckHerniaInguinal') ckHerniaInguinal: MatCheckbox;
  @ViewChild('ckSinaisInflamatoriosInguinal') ckSinaisInflamatoriosInguinal: MatCheckbox;
  @ViewChildren('inguinalChildren') inguinalChildren: QueryList<any>;


  // Hérnia Inguinal Escrotal
  @ViewChild('ckHerniaInguinoescrotal') ckHerniaInguinoescrotal: MatCheckbox;
  @ViewChild('slLadoInguinoescrotal') slLadoInguinoescrotal: MatSelect;
  @ViewChild('slConteudoInguinoescrotal') slConteudoInguinoescrotal: MatSelect;
  @ViewChild('inMedidaInguinoescrotal') inMedidaInguinoescrotal: ElementRef;
  @ViewChild('ckSinaisInflamatoriosInguinoescrotal') ckSinaisInflamatoriosInguinoescrotal: MatCheckbox;

  // Hérnia Inguinal Femoral
  @ViewChild('ckHerniaFemoral') ckHerniaFemoral: MatCheckbox;
  @ViewChild('slLadoFemoral') slLadoFemoral: MatSelect;
  @ViewChild('slConteudoFemoral') slConteudoFemoral: MatSelect;
  @ViewChild('inMedidaFemoral') inMedidaFemoral: ElementRef;
  @ViewChild('inColoFemoral') inColoFemoral: ElementRef;
  @ViewChild('ckSinaisInflamatoriosFemoral') ckSinaisInflamatoriosFemoral: MatCheckbox;



  lados: ArrGenerico[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ];

  conteudos: ArrGenerico[] = [
    { value: 'adiposo', viewValue: 'Adiposo' },
    { value: 'alças intestinais', viewValue: 'Alças intestinais' },
    { value: 'gordura e alças intestinais', viewValue: 'Gordura e alças intestinais' }
  ];

  vasos: ArrGenerico[] = [
    { value: 'medialmente', viewValue: 'Medialmente' },
    { value: 'lateralmente', viewValue: 'Lateralmente' }
  ];

  showAndHideTerms: any;
  lesoesForm: FormGroup;
  linha = 0;
  objeto: any;
  objetofilho: any;
  countLesoes = 0;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes, private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahLesao: false,
      sahInguinal: false,
      sahInguinoescrotal: false,
      sahFemoral: false,
    };
    this.lesoesForm = this._fb.group({
      inguinal: this._fb.array([this.addInguinalGrup()]),
    });
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  // HÉRNIA INGUINAL
  addInguinalGrup() {
    return this._fb.group({
      lado: [],
      conteudo: [],
      medida: [],
      vasos: []
    });
  }

  addInguinal() {
    this.inguinalArray.push(this.addInguinalGrup());
    this.catchIDs();
  }

  removeInguinal(index) {
    this.inguinalArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get inguinalArray() {
    return <FormArray>this.lesoesForm.get('inguinal');
  }



  clickAusente() {
    this.showAndHideTerms.sahLesao = false;
    this.showAndHideTerms.sahInguinal = false;
    this.showAndHideTerms.sahInguinoescrotal = false;
    this.showAndHideTerms.sahFemoral = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahLesao = true;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickAlargamentoCanalInguinal() {
    this.showAndHideTerms.sahLesao = false;
    this.showAndHideTerms.sahInguinal = false;
    this.showAndHideTerms.sahInguinoescrotal = false;
    this.showAndHideTerms.sahFemoral = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickInguinal() {
    this.rdPresente.checked = true;
    if (this.showAndHideTerms.sahInguinal == false) {
      this.showAndHideTerms.sahInguinal = true;
    } else {
      this.showAndHideTerms.sahInguinal = false;
      this.ajustaArrayDinamico();
      this.verificaAlterado();
    }
    this.catchIDs();
  }

  clickInguinoescrotal() {
    this.rdPresente.checked = true;
    if (this.showAndHideTerms.sahInguinoescrotal == false) {
      this.showAndHideTerms.sahInguinoescrotal = true;
    } else {
      this.showAndHideTerms.sahInguinoescrotal = false;
      this.verificaAlterado();
    }
    this.catchIDs();
  }

  clickFemoral() {
    this.rdPresente.checked = true;
    if (this.showAndHideTerms.sahFemoral == false) {
      this.showAndHideTerms.sahFemoral = true;
    } else {
      this.showAndHideTerms.sahFemoral = false;
      this.verificaAlterado();
    }
    this.catchIDs();
  }

  clickDefault() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }


  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if(this.rdAlargamentoCanalInguinal.checked){
      this.termoSelecionado.emit(this.rdAlargamentoCanalInguinal.id);
    }

    if (this.rdPresente.checked) {

      // Hérnia Inguinal
      this.emitInguinal();

      // Hérnia Inguinoescrotal
      if (this.countLesoes > 0) {
        this.emitInguinoescrotalLesao();
      } else {
        this.emitInguinoescrotalSimples();
      }

      // Hérnia Femoral
      if (this.countLesoes > 0) {
        this.emitFemoralLesao();
      } else {
        this.emitFemoralSimples();
      }

    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }


  emitInguinal() {

    if (this.ckHerniaInguinal !== undefined && this.ckHerniaInguinal.checked) {
      this.termoSelecionado.emit(this.ckHerniaInguinal.id);
      this.countLesoes++;
      this.linha = 0;

      if (this.ckSinaisInflamatoriosInguinal !== undefined && this.ckSinaisInflamatoriosInguinal.checked) {
        this.termoSelecionado.emit(this.ckSinaisInflamatoriosInguinal.id);
      }

      this.inguinalChildren.forEach(obj => {

        if (obj.nativeElement && obj.nativeElement.value) {

            this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
            this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
            this.lesoesDinamicas.emit(this.objeto);
        } else if (!obj.nativeElement) {

          if (+obj.id === 947) this.linha++;

          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.objeto);
        }
      });
    }
  }


  emitInguinoescrotalSimples() {
    if (this.ckHerniaInguinoescrotal !== undefined && this.ckHerniaInguinoescrotal.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHerniaInguinoescrotal.id);

      if (this.slLadoInguinoescrotal != undefined && this.slLadoInguinoescrotal.value != undefined) {
        const objeto = { elemento: this.slLadoInguinoescrotal, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoInguinoescrotal != undefined && this.slConteudoInguinoescrotal.value != undefined) {
        const objeto = { elemento: this.slConteudoInguinoescrotal, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inMedidaInguinoescrotal !== undefined &&
        this.inMedidaInguinoescrotal.nativeElement.id !== undefined &&
        this.inMedidaInguinoescrotal.nativeElement.value != '') {

        const objeto = { elemento: this.inMedidaInguinoescrotal.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckSinaisInflamatoriosInguinoescrotal !== undefined && this.ckSinaisInflamatoriosInguinoescrotal.checked) {
        this.termoSelecionado.emit(this.ckSinaisInflamatoriosInguinoescrotal.id);
      }
    }
  }

  emitInguinoescrotalLesao() {
    if (this.ckHerniaInguinoescrotal !== undefined && this.ckHerniaInguinoescrotal.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckHerniaInguinoescrotal.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLadoInguinoescrotal != undefined && this.slLadoInguinoescrotal.value != undefined) {
        const objeto = { elemento: this.slLadoInguinoescrotal, isPrimeiraLesao: false, cenario: this.countLesoes };
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoInguinoescrotal != undefined && this.slConteudoInguinoescrotal.value != undefined) {
        const objeto = { elemento: this.slConteudoInguinoescrotal, isPrimeiraLesao: false, cenario: this.countLesoes };
        this.termosGenericos.emit(objeto);
      }

      if (this.inMedidaInguinoescrotal !== undefined &&
        this.inMedidaInguinoescrotal.nativeElement.id !== undefined &&
        this.inMedidaInguinoescrotal.nativeElement.value != '') {
        const objeto = { elemento: this.inMedidaInguinoescrotal.nativeElement, isPrimeiraLesao: false, cenario: this.countLesoes };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckSinaisInflamatoriosInguinoescrotal !== undefined && this.ckSinaisInflamatoriosInguinoescrotal.checked) {
        const objeto = { id: this.ckSinaisInflamatoriosInguinoescrotal.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
    }
  }

  emitFemoralSimples() {
    if (this.ckHerniaFemoral !== undefined && this.ckHerniaFemoral.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHerniaFemoral.id);

      if (this.slLadoFemoral != undefined && this.slLadoFemoral.value != undefined) {
        const objeto = { elemento: this.slLadoFemoral, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoFemoral != undefined && this.slConteudoFemoral.value != undefined) {
        const objeto = { elemento: this.slConteudoFemoral, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inMedidaFemoral !== undefined &&
        this.inMedidaFemoral.nativeElement.id !== undefined &&
        this.inMedidaFemoral.nativeElement.value != '') {

        const objeto = { elemento: this.inMedidaFemoral.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inColoFemoral !== undefined &&
        this.inColoFemoral.nativeElement.id !== undefined &&
        this.inColoFemoral.nativeElement.value != '') {

        const objeto = { elemento: this.inColoFemoral.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckSinaisInflamatoriosFemoral !== undefined && this.ckSinaisInflamatoriosFemoral.checked) {
        this.termoSelecionado.emit(this.ckSinaisInflamatoriosFemoral.id);
      }
    }
  }

  emitFemoralLesao() {
    if (this.ckHerniaFemoral !== undefined && this.ckHerniaFemoral.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckHerniaFemoral.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLadoFemoral != undefined && this.slLadoFemoral.value != undefined) {
        const objeto = { elemento: this.slLadoFemoral, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoFemoral != undefined && this.slConteudoFemoral.value != undefined) {
        const objeto = { elemento: this.slConteudoFemoral, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inMedidaFemoral !== undefined &&
        this.inMedidaFemoral.nativeElement.id !== undefined &&
        this.inMedidaFemoral.nativeElement.value != '') {

        const objeto = { elemento: this.inMedidaFemoral.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inColoFemoral !== undefined &&
        this.inColoFemoral.nativeElement.id !== undefined &&
        this.inColoFemoral.nativeElement.value != '') {

        const objeto = { elemento: this.inColoFemoral.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckSinaisInflamatoriosFemoral !== undefined && this.ckSinaisInflamatoriosFemoral.checked) {
        const objeto = { id: this.ckSinaisInflamatoriosFemoral.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
    }
  }

  verificaAlterado() {
    if (!this.ckHerniaFemoral.checked &&
      !this.ckHerniaInguinoescrotal.checked &&
      !this.ckHerniaInguinal.checked) {
      // Marca normal
      this.rdAusente.checked = true;
    }
  }

  ajustaArrayDinamico() {
    const arrayTotal = this.inguinalArray.length;
    for (let i = 0; i <= arrayTotal; i++) {
      const index = this.inguinalArray.length - 1;
      if (index !== 0) {
        this.inguinalArray.removeAt(index);
      }
    }
    this.inguinalArray.reset();
  }

}
