import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Validacoes } from '../../../../utils/validacoes';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { TermosDinamicos } from 'src/app/models/Helper/TermosDinamicos';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

export interface DescricaoConclusao {
  value: string;
  textTermID: number;
  blockID: number;
}

export interface TermosGenericos {
  value: string;
  selected: boolean;
  termID: number;
  term: string;
}

@Component({
  selector: 'app-torax-us',
  templateUrl: './torax-us.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./torax-us.component.css']
})
export class ToraxUsComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {listTerm:[1859],blockID:156},
    {listTerm:[1900],blockID:157},
    {listTerm:[1925],blockID:159},
    {listTerm:[1921],blockID:158},
  ]

  descricoes: DescricaoConclusao[] = [
    { value: 'Exame direcionado para pesquisa de derrame pleural.', blockID: 156, textTermID: 0 },
    { value: 'Cúpulas diafragmáticas com boa mobilidade.', blockID: 159, textTermID: 0 },
    { value: '', blockID: 158, textTermID: 0 },
    { value: 'Não há sinais de derrame pleural.', blockID: 157, textTermID: 0 }
  ];

  conclusoes: DescricaoConclusao[] = [
    { value: 'Exame sem alterações significativas.', blockID: 0, textTermID: 0 },
    { value: '', blockID: 159, textTermID: 0 },
    { value: '', blockID: 158, textTermID: 0 },
    { value: '', blockID: 157, textTermID: 0 }
  ];
  //temos genericos dos exames

  genericDerramePleural: TermosGenericos[] = [
    { value: '', selected: false, termID: 1903, term: '[Quantidade dir]' },
    { value: '', selected: false, termID: 1904, term: '[Conteudo dir]' },
    { value: '', selected: false, termID: 1906, term: '[Espessura dir]' },
    { value: '', selected: false, termID: 1907, term: '[Distancia pele dir]' },
    { value: '', selected: false, termID: 1908, term: '[Quantidade esq]' },
    { value: '', selected: false, termID: 1909, term: '[Conteudo esq]' },
    { value: '', selected: false, termID: 1911, term: '[Espessura esq]' },
    { value: '', selected: false, termID: 1912, term: '[Distancia pele esq]' }
  ];

  genericLadosCons: TermosGenericos[] = [
    { value: '', selected: false, termID: 1922, term: '[Lado]' }
  ];

  genericLadosPara: TermosGenericos[] = [
    { value: '', selected: false, termID: 1927, term: '[Lado]' }
  ];

  Subtitulo: string;
  subtituloBloco: string;
  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();
  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  cabecalhoTexto = '';
  achadosAdTexto = '';
  rodapeTexto = '';

  cabecalho = false;
  indicacaoUs = true;
  derrameUs = false;
  consolidacaoUs = false;
  paralisiaUs = false;
  achadosTexto = false;
  rodape = false;

  medicalReportTypeID = 23;
  indicacaoUsID = 156;
  derrameUsID = 157;
  paralisiaUsID = 159;
  consolidacaoUsID = 158;
  public switchFormReport = true;
  public showForm: Boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  editLaudoMode: boolean = false;
  unidadeMedida: string;

  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _toastr: ToastrManager,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _dialog: MatDialog,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private dataService:DataTermService,
    private _config: ConfigLaudoService,
    public _addText: AddText,
    private _copyPaste: CopyPaste) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("torax-usAnalise", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Indicação';
    this.subtituloBloco = 'Preencha o campo indicação';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto(this.newEditor);
    // });
    const listBlocks = [
      this.indicacaoUsID,
      this.derrameUsID,
      this.paralisiaUsID,
      this.consolidacaoUsID
    ];
    this._pouchService.loadMask(this.medicalReportTypeID, "torax-usMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "toraxUs");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-torax');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-torax');
      }
    });
    let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo(){
    this._pouchService.getAll("torax-usAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricoes[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.showForm = false;
    this.cabecalhoTexto = "";
    this.achadosAdTexto = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  blocos = [
    {
      titulo: 'Informações',
      id: 'cabecalho',
      name: 'cabecalho',
      selected: false,
    },
    {
      titulo: 'Indicação',
      id: this.indicacaoUsID,
      name: 'indicacao',
      subtitulo: 'Preencha o campo indicação',
      selected: true,
    },
    {
      titulo: 'Paralisia diafragmática',
      id: this.paralisiaUsID,
      name: 'paralisiaDia',
      selected: false,
    },
    {
      titulo: 'Consolidação / Atelectasia',
      id: this.consolidacaoUsID,
      name: 'consolidacaoAte',
      selected: false,
    },
    {
      titulo: 'Derrame Pleural',
      id: this.derrameUsID,
      name: 'derramePleu',
      selected: false,
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.indicacaoUs = (itemSelecionado.id === this.indicacaoUsID);
    this.derrameUs = (itemSelecionado.id === this.derrameUsID);
    this.consolidacaoUs = (itemSelecionado.id === this.consolidacaoUsID);
    this.paralisiaUs = (itemSelecionado.id === this.paralisiaUsID);
    this.achadosTexto = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();

    this.genericDerramePleural = [
      { value: '', selected: false, termID: 1903, term: '[Quantidade dir]' },
      { value: '', selected: false, termID: 1904, term: '[Conteudo dir]' },
      { value: '', selected: false, termID: 1906, term: '[Espessura dir]' },
      { value: '', selected: false, termID: 1907, term: '[Distancia pele dir]' },
      { value: '', selected: false, termID: 1908, term: '[Quantidade esq]' },
      { value: '', selected: false, termID: 1909, term: '[Conteudo esq]' },
      { value: '', selected: false, termID: 1911, term: '[Espessura esq]' },
      { value: '', selected: false, termID: 1912, term: '[Distancia pele esq]' }
    ];

    this.genericLadosCons = [
      { value: '', selected: false, termID: 1922, term: '[Lado]' }
    ];

    this.genericLadosPara = [
      { value: '', selected: false, termID: 1927, term: '[Lado]' }
    ];
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  adicionaSelecaoDerramePleural($event) {
    this.genericDerramePleural.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoConsolidacao($event) {
    this.genericLadosCons.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoParalisia($event) {
    this.genericLadosPara.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }


  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      if (this.indicacaoUs) {
        this.term.blockID = this.indicacaoUsID;
        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.indicacaoUsID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              const index = this.descricoes.findIndex(x => x.blockID === this.indicacaoUsID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

              if(!this.newEditor) {
                this.AdicionaTexto(this.newEditor);
              } else {
                this.atualizaLinha({
                  blockId: this.indicacaoUsID,
                  descricao: desc,
                });
              }
            }
          });
        });

      } else if (this.derrameUs) {
        this.term.blockID = this.derrameUsID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.derrameUsID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDerramePleural(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.derrameUsID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto(this.newEditor);
            }
          });

          let obj = {
            value: '',
            blockID: this.derrameUsID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDerramePleural(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.derrameUsID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.derrameUsID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.derrameUsID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.consolidacaoUs) {
        this.term.blockID = this.consolidacaoUsID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.consolidacaoUsID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaConsolidacaoUs(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.consolidacaoUsID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto(this.newEditor);
            }
          });

          let obj = {
            value: '',
            blockID: this.consolidacaoUsID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConsolidacaoUs(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.consolidacaoUsID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.consolidacaoUsID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.consolidacaoUsID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.paralisiaUs) {
        this.term.blockID = this.paralisiaUsID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.paralisiaUsID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaParalisiaUs(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.paralisiaUsID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto(this.newEditor);
            }
          });

          let obj = {
            value: '',
            blockID: this.paralisiaUsID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaParalisiaUs(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.paralisiaUsID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.paralisiaUsID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.paralisiaUsID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  MontaDerramePleural(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1903 || +term === 1904
          || +term === 1906 || +term === 1907
          || +term === 1908 || +term === 1909
          || +term === 1911 || +term === 1912) {
          this.genericDerramePleural.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1903 || +term === 1904
          || +term === 1906 || +term === 1907
          || +term === 1908 || +term === 1909
          || +term === 1911 || +term === 1912) {
          this.genericDerramePleural.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaConsolidacaoUs(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1922) {
          this.genericLadosCons.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaParalisiaUs(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1927) {
          this.genericLadosPara.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-${this.indicacaoUsID}`,
        'name': ''
      },
      'data': {
        'text': `<p><b>INDICAÇÃO:<br></b>${this.descricoes[0].value}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      if (descricao.blockID !== this.indicacaoUsID) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}`,
            'name': ''
          },
          'data': {
            'text': `<p>${descricao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao) => {
      if (conclusao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.descricoes[0].value;
    this.htmlContent += maskSplit[1];

    for (i = 0; i < this.descricoes.length; i++) {
      var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }

    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value) {
        if (i > 0 && i < this.descricoes.length) {
          this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
        }
      }
    }
    this.htmlContent += maskSplit[2];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }
    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }
			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    // if (this.conclusoes.length <= 1) {
    texto = 'Exame sem alterações significativas.';
    // }
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
