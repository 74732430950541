import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Injuries, Term } from 'src/app/models/BO/Term';
import { TermResult } from 'src/app/models/BO/TermResult';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { TermosGenericos } from 'src/app/models/Helper/TermosGenericos';
import { CommonService } from 'src/app/services/common.service';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';
import { LaudosService } from 'src/app/services/laudos.service.spec';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { SelectedItemEmiter } from 'src/app/utils/SelectedItemEmiter';
import { CopyPaste } from 'src/app/utils/textEditor/CopyPaste';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-seios-da-face',
  templateUrl: './seios-da-face.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./seios-da-face.component.css']
})
export class SeiosDaFaceComponent implements OnInit {

  listTermDefault=[
    {listTerm:[4798],blockID:332},
    {listTerm:[4807],blockID:333},
    {listTerm:[4800],blockID:334},
    {listTerm:[4803],blockID:335},
    {listTerm:[4851],blockID:336},
  ]

  descricoes: DescricaoConclusao[] = [
    {
      value: '',//tecnica
        blockID: 332, textTermID: 0, visivel: true
    },
    {
      value: 'Transparência normal dos seios paranasais.',
        blockID: 333, textTermID: 0, visivel: true
    },
    {
      value: 'Septo nasal centrado.',
        blockID: 334, textTermID: 0, visivel: true
    },
    {
      value: 'Cornetos nasais com aspecto conservado.',
        blockID: 335, textTermID: 0, visivel: true
    },
    {
      value: 'Estruturas ósseas sem alterações.',
        blockID: 336, textTermID: 0, visivel: true
    }
  ];

  conclusoes: DescricaoConclusao[] = [
    { value: '', blockID: 332, textTermID: 0, visivel: true  },
    { value: '', blockID: 333, textTermID: 0, visivel: true  },
    { value: '', blockID: 334, textTermID: 0, visivel: true  },
    { value: '', blockID: 335, textTermID: 0, visivel: true  },
    { value: '', blockID: 336, textTermID: 0, visivel: true  },
  ];

  genericTecnica: TermosGenericos[] = [
    { value: '', selected: false, termID: 4797, term: '[incidencia]' },
  ];

  genericSeiosParanasais: TermosGenericos[] = [
    { value: '', selected: false, termID: 4809, term: '[EspessamentoEsq]' },
    { value: '', selected: false, termID: 4810, term: '[EspessamentoDir]' },
    { value: '', selected: false, termID: 4814, term: '[VelamentoEsq]' },
    { value: '', selected: false, termID: 4815, term: '[VelamentoDir]' },
    { value: '', selected: false, termID: 4819, term: '[CistoEsq]' },
    { value: '', selected: false, termID: 4820, term: '[CistoDir]' },
    { value: '', selected: false, termID: 4824, term: '[CistoPolipoEsq]' },
    { value: '', selected: false, termID: 4825, term: '[CistoPolipoDir]' },
  ];

  genericSeptoNasal: TermosGenericos[] = [
    { value: '', selected: false, termID: 4802, term: '[Lateralidade]' },
  ];

  genericCornetosNasais: TermosGenericos[] = [
    { value: '', selected: false, termID: 4805, term: '[Lateralidade]' },
  ];

  genericEstruturasOsseas: TermosGenericos[] = [
    { value: '', selected: false, termID: 4856, term: '[OssoDireito]' },
    { value: '', selected: false, termID: 4857, term: '[OssoEsquerdo]' },
    { value: '', selected: false, termID: 4860, term: '[OssoDireitoMult]' },
    { value: '', selected: false, termID: 4861, term: '[OssoEsquerdoMult]' },
  ];

  constructor(
    private _selected: SelectedItemEmiter,
    private _copyPaste: CopyPaste,
    public _dialog: MatDialog,
    private _toastr: ToastrManager,
    private _laudoService: LaudosService,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _serviceMedicalReport: MedicalReportService,
    private _config: ConfigLaudoService,
    public _addText: AddText,
    private dataService:DataTermService
  ) { }

  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();
  cenarioCorrente = 0;
  objetoGrupoLesao: any;

  Subtitulo: string;
  subtituloBloco: string;
  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  indicacaoTexto = '';
  achadosAdTexto = '';
  cabecalhoTexto = '';
  rodapeTexto = '';

  cabecalho = false;
  indicacao = false;
  tecnica = true;
  seiosParanasais = false;
  septoNasal = false;
  cornetosNasais = false;
  estruturasOsseas = false;
  achados = false;
  rodape = false;

  public showForm: Boolean = true;
  public unidadeMedida: string;
  public switchFormReport = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  editLaudoMode: boolean = false;

  medicalReportTypeID = 74;

  tecnicaId = 332;
  seiosParanasaisId = 333;
  septoNasalId = 334;
  cornetosNasaisId = 335;
  estruturasOsseasId = 336;

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("rxSeiosDaFaceAnalise", this.descricoes, this.conclusoes);
    this.subtituloBloco = this.Subtitulo;
    this.Subtitulo = 'Técnica';
    this.term.listTermID = new Array<number>();
    this.resetaDialog();

    const listBlocks = [
      this.tecnicaId,
      this.seiosParanasaisId,
      this.septoNasalId,
      this.cornetosNasaisId,
      this.estruturasOsseasId
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "rxSeiosDaFaceMask").then((obj: any) => {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "seiosDaFace");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'rx-seiosDaFace');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'rx-seiosDaFace');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  blocos = [
    {
      titulo: 'Informações',
      id: 'cabecalho',
      name: 'cabecalho',
      selected: false,
    },
    {
      titulo: 'Indicação',
      id: 'indicacao',
      name: 'indicacao',
      subtitulo:'preencha o campo indicação',
      selected: false
    },
    {
      titulo: 'Técnica',
      id: this.tecnicaId,
      name: 'tecnica',
      selected: true
    },
    {
      titulo: 'Seios Paranasais',
      id: this.seiosParanasaisId,
      name: 'seiosParanasais',
      selected: false
    },
    {
      titulo: 'Septo Nasal',
      id: this.septoNasalId,
      name: 'septoNasal',
      selected: false
    },
    {
      titulo: 'Cornetos Nasais',
      id: this.cornetosNasaisId,
      name: 'cornetosNasais',
      selected: false
    },
    {
      titulo: 'Estruturas Ósseas',
      id: this.estruturasOsseasId,
      name: 'estruturasOsseas',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo:'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.tecnica = (itemSelecionado.id === this.tecnicaId);
    this.seiosParanasais = (itemSelecionado.id === this.seiosParanasaisId);
    this.septoNasal = (itemSelecionado.id === this.septoNasalId);
    this.cornetosNasais = (itemSelecionado.id === this.cornetosNasaisId);
    this.estruturasOsseas = (itemSelecionado.id === this.estruturasOsseasId);
    this.achados = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;

    this.genericTecnica = [
      { value: '', selected: false, termID: 4797, term: '[incidencia]' },
    ];

    this.genericSeiosParanasais = [
      { value: '', selected: false, termID: 4809, term: '[EspessamentoEsq]' },
      { value: '', selected: false, termID: 4810, term: '[EspessamentoDir]' },
      { value: '', selected: false, termID: 4814, term: '[VelamentoEsq]' },
      { value: '', selected: false, termID: 4815, term: '[VelamentoDir]' },
      { value: '', selected: false, termID: 4819, term: '[CistoEsq]' },
      { value: '', selected: false, termID: 4820, term: '[CistoDir]' },
      { value: '', selected: false, termID: 4824, term: '[CistoPolipoEsq]' },
      { value: '', selected: false, termID: 4825, term: '[CistoPolipoDir]' },
    ];

    this.genericSeptoNasal = [
      { value: '', selected: false, termID: 4802, term: '[Lateralidade]' },
    ];

    this.genericCornetosNasais = [
      { value: '', selected: false, termID: 4805, term: '[Lateralidade]' },
    ];

    this.genericEstruturasOsseas = [
      { value: '', selected: false, termID: 4856, term: '[OssoDireito]' },
      { value: '', selected: false, termID: 4857, term: '[OssoEsquerdo]' },
      { value: '', selected: false, termID: 4860, term: '[OssoDireitoMult]' },
      { value: '', selected: false, termID: 4861, term: '[OssoEsquerdoMult]' },
    ];

  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaLesoes($event) {
    debugger;
    if (this.cenarioCorrente === 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario === 0 || $event.cenario === this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  adicionaSelecaoTecnica($event) {
    this.genericTecnica.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoSeiosParanasais($event) {
    this.genericSeiosParanasais.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }

  }

  adicionaSelecaoSeptoNasal($event) {
    this.genericSeptoNasal.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoCornetosNasais($event) {
    this.genericCornetosNasais.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoEstruturasOsseas($event) {
    debugger
    this.genericEstruturasOsseas.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }

  }

  consultarAPI() {
    let desc;
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      if (this.tecnica) {
        debugger
        this.term.blockID = this.tecnicaId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.tecnicaId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoTecnica(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.tecnicaId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.tecnicaId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoTecnica(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.tecnicaId);

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.seiosParanasais) {
        this.term.blockID = this.seiosParanasaisId;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.seiosParanasaisId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoSeiosParanasais(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.seiosParanasaisId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.seiosParanasaisId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoSeiosParanasais(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.seiosParanasaisId);

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.septoNasal) {
        this.term.blockID = this.septoNasalId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.septoNasalId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoSeptoNasal(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.septoNasalId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.septoNasalId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoSeptoNasal(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.septoNasalId);

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.cornetosNasais){
        this.term.blockID = this.cornetosNasaisId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.cornetosNasaisId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoCornetosNasais(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.cornetosNasaisId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.cornetosNasaisId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoCornetosNasais(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.septoNasalId);

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.estruturasOsseas){
        this.term.blockID = this.estruturasOsseasId;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.estruturasOsseasId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoEstruturasOsseas(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.estruturasOsseasId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.estruturasOsseasId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoEstruturasOsseas(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.estruturasOsseasId);

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }


  MontaDescricaoTecnica(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 4797 || +term === 4798) {
        this.genericTecnica.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoSeiosParanasais(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4809 || +term === 4810 || +term === 4814 || +term === 4815
        || +term === 4819 || +term === 4820 || +term === 4824 || +term === 4825) {
        this.genericSeiosParanasais.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4809 || +term === 4810 || +term === 4814 || +term === 4815
          || +term === 4819 || +term === 4820 || +term === 4824 || +term === 4825) {
          this.genericSeiosParanasais.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoSeptoNasal(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 4802) {
        this.genericSeptoNasal.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoCornetosNasais(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 4805) {
        this.genericCornetosNasais.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoEstruturasOsseas(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4856 || +term === 4857 || +term === 4860 || +term === 4861) {
        this.genericEstruturasOsseas.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4856 || +term === 4857 || +term === 4860 || +term === 4861) {
          this.genericEstruturasOsseas.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });


    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      if (descricao.value && descricao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}`,
            'name': ''
          },
          'data': {
            'text': `<p>${descricao.value}</p>`,
          }
        });
      }
    });

    this.conclusoes.forEach((conclusao) => {
      if (conclusao.value !== '' ) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }
    //Lógica Rules
    // if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
    //   this.htmlContent += '<br/><b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>';
    // }

    for (i = 0; i < this.descricoes.length; i++) {
      let newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      let newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }

    this.htmlContent += this.indicacaoTexto + ' </br>';
    this.htmlContent += maskSplit[1];
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value) {
        this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
      }
    }

    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }

    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
    conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
    calculadora,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }

        if(calculadora !== undefined) {
          const calculo = document.querySelector(`#e-${blockId}`);

          if(calculo) {
						calculo.innerHTML = calculadora;
          }
        }

			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  };

  RetornaConclusaoNull(Texto) {
    let texto = '';
    texto = '<p>' + Texto + '</p>';
    return texto;
  }

  public resetaLaudo() {
    this._pouchService.getAll("rxSeiosDaFaceAnalise").then((obj) => {
      console.log(obj)
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.descricoes[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.showForm = false;
    this.indicacaoTexto = "";
    this.achadosAdTexto = "";
    this.cabecalhoTexto = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
