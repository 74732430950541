import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ListaPlanosFavoritosService } from 'src/app/services/lista-planos-favoritos.service';
import { Speciality } from '../../../models/BO/Speciality';
import { ListaPlanosService } from '../../../services/lista-planos.service';
import { first } from 'rxjs/operators';
import { from } from 'rxjs';

@Component({
  selector: 'app-menu-tc',
  templateUrl: './menu-tc.component.html',
  styleUrls: ['./menu-tc.component.css', '../../../app.component.css']
})
export class MenuTcComponent implements OnInit {
  examesTomografia: Speciality[] = [];
  public loading: boolean = true;
  private planoData : Speciality[][];

  exameFilter: Speciality = {
    id: 0,
    description: '',
    parentMedicalReportTypeID: 0,
    specialityID: 0,
    title: '',
    image: '',
    url: '',
    visible: false,
    favorite: false
  };

  constructor(private router: Router,
    private _planoService: ListaPlanosService,
    private _favoritosService: ListaPlanosFavoritosService,
    private _firebase: FirebaseService) { }

  async ngOnInit() {
    this.planoData = await from(this._planoService.getPlanosPorUsuario()).pipe(first()).toPromise();

    this.planoData.forEach(item => {
      item.forEach(exame => {
        if(exame.specialityID === 5){ // Tomografia
          this.examesTomografia.push(exame);
        }
      });
    });

    this.loading = false;
  }

  goTo(where: string) {
    this._firebase.logEnterMedicalReport("Menu");
    this.router.navigate([`/laudos/${where}`]);
  }

  goToNewTab(where: string){
    window.open(`/laudos/${where}`,'_blank','noopener')
  }

  changeFavorito(exame: Speciality) {
    const userId = localStorage.getItem('userID');
    if (this._favoritosService.isFavorito(exame)) {
      exame.favorite = false;
      this._favoritosService.apagarFavoritoUsuario(+userId, exame)
    } else {
      exame.favorite = true;
      this._favoritosService.insereFavoritoUsuario(+userId, exame)
    }
  }

  isFavorito(exame: Speciality): boolean {
    return this._favoritosService.isFavorito(exame);
  }
}
