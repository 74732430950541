import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatCheckbox, MatDialog, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { ManejoRcuiDialogComponent } from 'src/app/utils/dialog/imagens/manejo-rciu-dialog/manejo-rciu-dialog.component';

@Component({
  selector: 'app-dopplervelocimetria',
  templateUrl: './dopplervelocimetria.component.html',
  styleUrls: ['./dopplervelocimetria.component.css']
})
export class DopplervelocimetriaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() enviaMedida = new EventEmitter();
  @Output() tabelaTexto = new EventEmitter();
  @Output() showTabela = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterada') rdAlterada: MatRadioButton;

  @ViewChild('inAUterinaDir') inAUterinaDir: ElementRef;
  @ViewChild('inAUterinaEsq') inAUterinaEsq: ElementRef;
  @ViewChild('inIPMedioAUterinas') inIPMedioAUterinas: ElementRef;
  @ViewChild('inPercentilAUterinas') inPercentilAUterinas: ElementRef;
  @ViewChild('inAUmbilicais') inAUmbilicais: ElementRef;
  @ViewChild('inPercentilAUmbilicais') inPercentilAUmbilicais: ElementRef;
  @ViewChild('inACerebral') inACerebral: ElementRef;
  @ViewChild('inPercentilAcerebral') inPercentilAcerebral: ElementRef;
  @ViewChild('inRelacao') inRelacao: ElementRef;
  @ViewChild('inPercentilRelacao') inPercentilRelacao: ElementRef;
  @ViewChild('inDucto') inDucto: ElementRef;
  @ViewChild('slAUmbilicais') slAUmbilicais: MatSelect;


  AUmbilicais: ArrayDropDown[] = [
    {value: 'diástole zero', viewValue: 'Diástole zero'},
    {value: 'diástole reversa', viewValue: 'Diástole reversa'}
  ];

  showAndHideTerms: any;
  unidadeMedida: string;
  calculoRelacao
  ipMedioAUterina!:any;
  switchTabelaTextoChecked: boolean = true;
  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahNormal: true,
      sahAlterada: false
    }
    let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal(){
    this.showAndHideTerms.sahNormal = true;
    this.showAndHideTerms.sahAlterada = false;
    this.catchIDs();
  }

  clickAlterada(){
    this.showAndHideTerms.sahNormal = false;
    this.showAndHideTerms.sahAlterada = true;
    this.catchIDs();
  }

  clickTabelaManejo(){
    this.dialog.open(ManejoRcuiDialogComponent, {});
  }

  blurParametros() {
    this.catchIDs();
    this.tabelaTexto.emit('element');
    this.handleCalculaRelacao();
  }
  handleCalculaAAMedio(aaEsquerda, aaDireita){
    let soma =+aaEsquerda + +aaDireita;
    this.ipMedioAUterina = (soma / 2).toFixed(2);
  }
  handleCalculaRelacao(){
    if(this.inACerebral && this.inACerebral.nativeElement.value && this.inAUmbilicais && this.inAUmbilicais.nativeElement.value){
      this.calculoRelacao = twoDecimalPlacesIfCents(this.inACerebral.nativeElement.value / this.inAUmbilicais.nativeElement.value);
    }
    function twoDecimalPlacesIfCents(amount){
      return (amount % 1 !== 0) ? amount.toFixed(2) : amount;
    }
  }

  changeSelect(){
    this.catchIDs();
  }

  switchTabelaTexto(){
    this.switchTabelaTextoChecked = !this.switchTabelaTextoChecked;
    this.showTabela.emit(this.switchTabelaTextoChecked);
  }

  catchIDs() {
    this.enviaMedida.emit('Clean');
    this.termoSelecionado.emit('Clean');

    if(this.rdNormal && this.rdNormal.checked){
      this.termoSelecionado.emit(this.rdNormal.id);
    } else if (this.rdAlterada && this.rdAlterada.checked){
      this.termoSelecionado.emit(this.rdAlterada.id);
      this.enviaMedida.emit('Clean');
    }
    if(this.inAUterinaEsq && this.inAUterinaEsq.nativeElement.value && this.inAUterinaDir && this.inAUterinaDir.nativeElement.value){
       this.handleCalculaAAMedio(this.inAUterinaEsq.nativeElement.value, this.inAUterinaDir.nativeElement.value);
    }

    if(this.inAUterinaEsq && this.inAUterinaEsq.nativeElement.value){
      const objeto = { elemento: this.inAUterinaEsq.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
    if(this.inAUterinaDir && this.inAUterinaDir.nativeElement.value){
      const objeto = { elemento: this.inAUterinaDir.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
    if(this.inIPMedioAUterinas && this.inIPMedioAUterinas.nativeElement.value){
      const objeto = { elemento: this.inIPMedioAUterinas.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
    if(this.inPercentilAUterinas){
      const objeto = { elemento: this.inPercentilAUterinas.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
    if(this.inAUmbilicais && this.inAUmbilicais.nativeElement.value){
      const objeto = { elemento: this.inAUmbilicais.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
    if(this.slAUmbilicais && this.slAUmbilicais.value){
      const newElement = {elemento: {id: '5579', value: 'DZ'}};
      if(this.slAUmbilicais.value == 'diástole zero'){
        newElement.elemento.value = 'DZ';
        const objeto2 = { elemento: newElement, isPrimeiraLesao: true };
        this.enviaMedida.emit(objeto2.elemento);
      } else if(this.slAUmbilicais.value == 'diástole reversa'){
        newElement.elemento.value = 'DR';
        const objeto3 = { elemento: newElement, isPrimeiraLesao: true };
        this.enviaMedida.emit(objeto3.elemento);
      }
    }
    if(this.inPercentilAUmbilicais){
      const objeto = { elemento: this.inPercentilAUmbilicais.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
    if(this.inACerebral && this.inACerebral.nativeElement.value){
      const objeto = { elemento: this.inACerebral.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
    if(this.inPercentilAcerebral){
      const objeto = { elemento: this.inPercentilAcerebral.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
    if(this.inRelacao && this.inRelacao.nativeElement.value){
      const objeto = { elemento: this.inRelacao.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
    if(this.inPercentilRelacao){
      const objeto = { elemento: this.inPercentilRelacao.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
    if(this.inDucto && this.inDucto.nativeElement.value){
      const objeto = { elemento: this.inDucto.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
    if(this.slAUmbilicais && this.slAUmbilicais.value){
      const objeto = { elemento: this.slAUmbilicais, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
    
    this.showTabela.emit(this.switchTabelaTextoChecked);
    this.listaCompleta.emit();
  }
}
