import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { DescricaoConclusao, } from '../../../../models/Helper/DescricaoConclusao';
import { TermosGenericos } from '../../../../models/Helper/TermosGenericos';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { MatDialog } from '@angular/material';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ConfirmDialogComponent } from '../../../../utils/dialog/confirm-dialog/confirm-dialog.component';
import { Validacoes } from '../../../../utils/validacoes';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { CommonService } from 'src/app/services/common.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

@Component({
  selector: 'app-musculos-tendoes-bilateral',
  templateUrl: './musculos-tendoes-bilateral.component.html',
  styleUrls: ['./musculos-tendoes-bilateral.component.css'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
})
export class MusculosTendoesBilateralComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  public LadoD: any = '';
  public LadoE: any = '';
  public UltraTextD: any = '';
  public UltraTextE: any = '';
  public Subtitulo: string;
  public subtituloBloco: string;
  public Ultrassonografia: Boolean = true;
  public PeleTecido: Boolean = false;
  public SuperficiesOsseas: Boolean = false;
  public Tendoes: Boolean = false;
  public MusculaturaRegional: Boolean = false;
  public DerrameArticular: Boolean = false;
  public htmlContent: string;
  public htmlContentDireita: string;
  public htmlContentEsquerda: string;
  public maskDireita: string;
  public maskEsquerda: string;
  public indicacaoTextoDireita: any = '';
  public indicacaoTextoEsquerda: any = '';
  public cabecalhoTexto = '';
  public cabecalho = false;
  public achadosAdTextoEsquerda: any = '';
  public achadosAdTextoDireita: any = '';
  public rodape = false;
  public rodapeTexto: any = '';
  public medicalReportTypeID: number = 62;
  public PeleTecidoID: Number = 114;
  public SuperficiesOsseasID: Number = 115;
  public TendoesID: Number = 116;
  public MusculaturaRegionalID: Number = 117;
  public DerrameArticularID: Number = 118;
  public term: any = new Term();
  public injuries: any = new Injuries();
  public cenarioCorrente: any = 0;
  public termResultsAPI: Array<TermResult>;
  public achadosTexto: Boolean = false;
  public indicacao: Boolean = false;
  public termConclusionAPI: Array<TermResult>;
  public objetoGrupoLesao: any;
  public generoLateralidade: string;
  public showForm: Boolean = true;
  public musculosTendoes: any;

  public blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo:'Preencha o campo indicação',
      selected: false
    },
    {
      titulo: 'Topografia',
      id: 'Topografia',
      name: 'Topografia',
      selected: true,
    },
    {
      titulo: 'Pele e tecido subcutâneo',
      id: this.PeleTecidoID,
      name: 'pele-tecido-subcutaneo',
      selected: false,
    },
    {
      titulo: 'Superfícies ósseas',
      id: this.SuperficiesOsseasID,
      name: 'superfícies-osseas',
      selected: false,
    },
    {
      titulo: 'Tendões',
      id: this.TendoesID,
      name: 'tendoes',
      selected: false,
    },
    {
      titulo: 'Musculatura regional',
      id: this.MusculaturaRegionalID,
      name: 'musculatura-regional',
      selected: false,
    },
    {
      titulo: 'Derrame articular',
      id: this.DerrameArticularID,
      name: 'derrame-articular',
      selected: false,
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados-adicionais',
      name: 'achados-adicionais',
      subtitulo:'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  listTermDefault=[
    {listTerm:[1368],blockID:114},
    {listTerm:[1375],blockID:115},
    {listTerm:[1376],blockID:116},
    {listTerm:[1377],blockID:117},
    {listTerm:[1382],blockID:118},
  ]

  public descricoesDireita: DescricaoConclusao[] = [
    { value: 'Pele e tecido subcutâneo preservados.', blockID: 114, textTermID: 0, visivel: true },
    { value: 'Superfícies ósseas sem alterações significativas ao método.', blockID: 115, textTermID: 0, visivel: true },
    { value: 'Tendões com padrão fibrilar e ecogenicidade usual.', blockID: 116, textTermID: 0, visivel: true },
    { value: 'Musculatura regional íntegra.', blockID: 117, textTermID: 0, visivel: true },
    { value: 'Articulações adjacentes sem derrame articular significativo.', blockID: 118, textTermID: 0, visivel: true },
  ];

  public descricoesEsquerda: DescricaoConclusao[] = [
    { value: 'Pele e tecido subcutâneo preservados.', blockID: 114, textTermID: 0, visivel: true },
    { value: 'Superfícies ósseas sem alterações significativas ao método.', blockID: 115, textTermID: 0, visivel: true },
    { value: 'Tendões com padrão fibrilar e ecogenicidade usual.', blockID: 116, textTermID: 0, visivel: true },
    { value: 'Musculatura regional íntegra.', blockID: 117, textTermID: 0, visivel: true },
    { value: 'Articulações adjacentes sem derrame articular significativo.', blockID: 118, textTermID: 0, visivel: true },
  ];

  public conclusoesDireita: DescricaoConclusao[] = [
    { value: 'Exame dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 114, textTermID: 0, visivel: true },
    { value: '', blockID: 115, textTermID: 0, visivel: true },
    { value: '', blockID: 116, textTermID: 0, visivel: true },
    { value: '', blockID: 117, textTermID: 0, visivel: true },
    { value: '', blockID: 118, textTermID: 0, visivel: true },
  ];

  public conclusoesEsquerda: DescricaoConclusao[] = [
    { value: 'Exame dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 114, textTermID: 0, visivel: true },
    { value: '', blockID: 115, textTermID: 0, visivel: true },
    { value: '', blockID: 116, textTermID: 0, visivel: true },
    { value: '', blockID: 117, textTermID: 0, visivel: true },
    { value: '', blockID: 118, textTermID: 0, visivel: true },
  ];

  public genericPeleTecidoDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1370, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1373, term: '[Medida]' },
    { value: '', selected: false, termID: 1374, term: '[Dist pele]' },
  ];

  public genericPeleTecidoEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1370, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1373, term: '[Medida]' },
    { value: '', selected: false, termID: 1374, term: '[Dist pele]' },
  ];

  public genericMusculaturaRegionalDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1380, term: '[Medida]' },
    { value: '', selected: false, termID: 1381, term: '[Volume]' },
  ];

  public genericMusculaturaRegionalEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1380, term: '[Medida]' },
    { value: '', selected: false, termID: 1381, term: '[Volume]' },
  ];

  public genericDerrameArticularDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1384, term: '[Volume]' },
    { value: '', selected: false, termID: 1453, term: '[Localizacao]' },
  ];

  public genericDerrameArticularEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1384, term: '[Volume]' },
    { value: '', selected: false, termID: 1453, term: '[Localizacao]' },
  ];

  public switchFormReport = true;
  public unidadeMedida: string;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  editLaudoMode: boolean = false;




  constructor(private readonly _selecteditememiter: SelectedItemEmiter,
    private readonly _laudoservice: LaudosService,
    public readonly dialog: MatDialog,
    private readonly _copyPaste: CopyPaste,
    private readonly _toastr: ToastrManager,
    private readonly _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    public _addText: AddText,
    private dataService:DataTermService,
    private _config: ConfigLaudoService
    ) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("musculosTendoesAnalise", this.descricoesDireita, this.conclusoesDireita);
    this._pouchService.loadDatabase("musculosTendoesAnalise", this.descricoesEsquerda, this.conclusoesEsquerda);
    this.resetaDialog();
    this.generoLateralidade = 'masculino';
    this.UltraTextD = 'MUSCULOS E TENDÕES';
    this.UltraTextE = 'MUSCULOS E TENDÕES';
    this.Subtitulo = 'TOPOGRAFIA';
    this.subtituloBloco= this.Subtitulo
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    const listBlocks = [this.PeleTecidoID,
      this.SuperficiesOsseasID,
      this.TendoesID,
      this.MusculaturaRegionalID,
      this.DerrameArticularID];

    this._pouchService.loadMask(this.medicalReportTypeID, "musculosTendoesBilateralMask").then((obj: any)=> {
        this._serviceMedicalReport.criaLaudo(obj, false);
        this.maskDireita = obj.mask.replace('{0}', 'DIREITO');
        this.maskEsquerda = obj.mask.replace('{0}', 'ESQUERDO');
        this.AdicionaTexto(this.newEditor);
        let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

        if (loadLaudosRule) {
          if (loadLaudosRule.active) {
            this._pouchService.createDatabase(listBlocks, "musculosTendoes");
          }
          if (!loadLaudosRule.active) {
            this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'musculos-tendoes');
          }
        }
        if (loadLaudosRule == null) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'musculos-tendoes');
        }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  }

  public resetaLaudo(){
    this._pouchService.getAll("musculosTendoesAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricoesDireita[i].value = obj[i].doc.value;
            this.descricoesEsquerda[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusoesDireita[concCalc].value = obj[i].doc.value;
            this.conclusoesEsquerda[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.UltraTextD = 'MUSCULOS E TENDÕES';
    this.UltraTextE = 'MUSCULOS E TENDÕES';
    this.LadoD = "";
    this.LadoE = "";
    this.cabecalhoTexto = "";
    this.indicacaoTextoDireita = "";
    this.indicacaoTextoEsquerda = "";
    this.achadosAdTextoEsquerda = "";
    this.achadosAdTextoDireita = "";
    this.rodapeTexto = "";
    this.showForm = false;

    setTimeout(() => {
      this.showForm = true;
      this.AdicionaTexto(this.newEditor);
    }, 500);
  }

  public selecionar(itemSelecionado): any {
    const arrayAtualizado = this._selecteditememiter.Selected(itemSelecionado.name, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

      if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
        let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
        this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
      }


    this.cabecalho =  (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.achadosTexto = (itemSelecionado.id === 'achados-adicionais');
    this.rodape =  (itemSelecionado.id === 'rodape');
    this.Ultrassonografia = (itemSelecionado.id === 'Topografia');
    this.PeleTecido = (itemSelecionado.id === this.PeleTecidoID);
    this.SuperficiesOsseas = (itemSelecionado.id === this.SuperficiesOsseasID);
    this.Tendoes = (itemSelecionado.id === this.TendoesID);
    this.MusculaturaRegional = (itemSelecionado.id === this.MusculaturaRegionalID);
    this.DerrameArticular = (itemSelecionado.id === this.DerrameArticularID);

  }

  public limparTermos(): any {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;

    this.genericPeleTecidoDireita = [
      { value: '', selected: false, termID: 1370, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1373, term: '[Medida]' },
      { value: '', selected: false, termID: 1374, term: '[Dist pele]' },
    ];

    this.genericPeleTecidoEsquerda = [
      { value: '', selected: false, termID: 1370, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1373, term: '[Medida]' },
      { value: '', selected: false, termID: 1374, term: '[Dist pele]' },
    ];

    this.genericMusculaturaRegionalDireita = [
      { value: '', selected: false, termID: 1380, term: '[Medida]' },
      { value: '', selected: false, termID: 1381, term: '[Volume]' },
    ];

    this.genericMusculaturaRegionalEsquerda = [
      { value: '', selected: false, termID: 1380, term: '[Medida]' },
      { value: '', selected: false, termID: 1381, term: '[Volume]' },
    ];

    this.genericDerrameArticularDireita = [
      { value: '', selected: false, termID: 1384, term: '[Volume]' },
      { value: '', selected: false, termID: 1453, term: '[Localizacao]' },
    ];

    this.genericDerrameArticularEsquerda = [
      { value: '', selected: false, termID: 1384, term: '[Volume]' },
      { value: '', selected: false, termID: 1453, term: '[Localizacao]' },
    ];
  }

  public enviaTermos($event): any {
    if ($event === 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  public MontaDescricaoPeleTecidoDireita(texto, contadorTexto): any {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1370 || +term === 1373 || +term === 1374) {
          this.genericPeleTecidoDireita.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1370 || +term === 1373 || +term === 1374) {
          this.genericPeleTecidoDireita.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  public MontaDescricaoPeleTecidoEsquerda(texto, contadorTexto): any {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1370 || +term === 1373 || +term === 1374) {
          this.genericPeleTecidoEsquerda.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1370 || +term === 1373 || +term === 1374) {
          this.genericPeleTecidoEsquerda.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  public MontaDescricaoMusculaturaRegionalDireita(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1380 || +term === 1381) {
        this.genericMusculaturaRegionalDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoMusculaturaRegionalEsquerda(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1380 || +term === 1381) {
        this.genericMusculaturaRegionalEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoDerrameArtircularDireita(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1384 || +term === 1453) {
        this.genericDerrameArticularDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoDerrameArtircularEsquerda(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1384 || +term === 1453) {
        this.genericDerrameArticularEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoPeleTecidoDireita(texto, contadorTexto): any {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1370 || +term === 1373 || +term === 1374) {
          this.genericPeleTecidoDireita.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1370 || +term === 1373 || +term === 1374) {
          this.genericPeleTecidoDireita.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  public MontaConclussaoPeleTecidoEsquerda(texto, contadorTexto): any {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1370 || +term === 1373 || +term === 1374) {
          this.genericPeleTecidoEsquerda.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1370 || +term === 1373 || +term === 1374) {
          this.genericPeleTecidoEsquerda.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  public MontaConclussaoMusculaturaRegionalDireita(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1380 || +term === 1381) {
        this.genericMusculaturaRegionalDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoMusculaturaRegionalEsquerda(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1380 || +term === 1381) {
        this.genericMusculaturaRegionalEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoDerrameArtircularDireita(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1384 || +term === 1453) {
        this.genericDerrameArticularDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoDerrameArtircularEsquerda(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1384 || +term === 1453) {
        this.genericDerrameArticularEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public adicionaSelecaoPeleTecidoDireita($event): any {
    this.genericPeleTecidoDireita.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao === true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  public adicionaSelecaoPeleTecidoEsquerda($event): any {
    this.genericPeleTecidoEsquerda.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao === true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  public adicionaSelecaoMusculaturaRegionalDireita($event): any {
    this.genericMusculaturaRegionalDireita.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoMusculaturaRegionalEsquerda($event): any {
    this.genericMusculaturaRegionalEsquerda.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoDerrameArticularDireita($event): any {
    this.genericDerrameArticularDireita.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoDerrameArticularEsquerda($event): any {
    this.genericDerrameArticularEsquerda.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public consultarAPIDireita(): any {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){

      let desc = '';
      if (this.PeleTecido) {
        this.term.blockID = this.PeleTecidoID;
        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.PeleTecidoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoPeleTecidoDireita(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.PeleTecidoID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.PeleTecidoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoPeleTecidoDireita(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.PeleTecidoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.PeleTecidoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.PeleTecidoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }

        });

      } else if (this.SuperficiesOsseas) {
        this.term.blockID = this.SuperficiesOsseasID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;


        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.SuperficiesOsseasID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });
              let index = this.descricoesDireita.findIndex(x => x.blockID === this.SuperficiesOsseasID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.SuperficiesOsseasID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.SuperficiesOsseasID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.SuperficiesOsseasID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.SuperficiesOsseasID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }

        });

      } else if (this.Tendoes) {
        this.term.blockID = this.TendoesID;


        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.TendoesID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });
              let index = this.descricoesDireita.findIndex(x => x.blockID === this.TendoesID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.TendoesID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.TendoesID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.TendoesID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.TendoesID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }

        });

      } else if (this.MusculaturaRegional) {
        this.term.blockID = this.MusculaturaRegionalID;


        this._pouchService.getLaudos(this.term).then((termResults) => {
            this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.MusculaturaRegionalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoMusculaturaRegionalDireita(resultado.text);
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.MusculaturaRegionalID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.MusculaturaRegionalID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclussaoMusculaturaRegionalDireita(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.MusculaturaRegionalID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.MusculaturaRegionalID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.MusculaturaRegionalID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }

        });
      } else if (this.DerrameArticular) {
        this.term.blockID = this.DerrameArticularID;


        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.DerrameArticularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoDerrameArtircularDireita(resultado.text);
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.DerrameArticularID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.DerrameArticularID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclussaoDerrameArtircularDireita(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.DerrameArticularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.DerrameArticularID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: +this.DerrameArticularID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }

        });
      }
    }
  }

  public consultarAPIEsquerda(): any {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){

      let desc;
      if (this.PeleTecido) {
        this.term.blockID = this.PeleTecidoID;
        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.PeleTecidoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoPeleTecidoEsquerda(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.PeleTecidoID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.PeleTecidoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoPeleTecidoEsquerda(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.PeleTecidoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.PeleTecidoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.PeleTecidoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }

        });

      } else if (this.SuperficiesOsseas) {
        this.term.blockID = this.SuperficiesOsseasID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;


        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.SuperficiesOsseasID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });
              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.SuperficiesOsseasID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.SuperficiesOsseasID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.SuperficiesOsseasID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.SuperficiesOsseasID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.SuperficiesOsseasID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }

        });

      } else if (this.Tendoes) {
        this.term.blockID = this.TendoesID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.TendoesID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });
              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.TendoesID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.TendoesID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.TendoesID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.TendoesID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.TendoesID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }

        });

      } else if (this.MusculaturaRegional) {
        this.term.blockID = this.MusculaturaRegionalID;


        this._pouchService.getLaudos(this.term).then((termResults) => {
            this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.MusculaturaRegionalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoMusculaturaRegionalEsquerda(resultado.text);
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.MusculaturaRegionalID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.MusculaturaRegionalID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclussaoMusculaturaRegionalEsquerda(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.MusculaturaRegionalID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.MusculaturaRegionalID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.MusculaturaRegionalID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }

        });
      } else if (this.DerrameArticular) {
        this.term.blockID = this.DerrameArticularID;


        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.DerrameArticularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoDerrameArtircularEsquerda(resultado.text);
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.DerrameArticularID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.DerrameArticularID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclussaoDerrameArtircularEsquerda(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.DerrameArticularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.DerrameArticularID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: +this.DerrameArticularID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }

        });
      }
    }
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  public adicionaTextoOldEditor(){
    this.AdicionaTextoDireita();
    this.AdicionaTextoEsquerda();
  }

  public adicionaTextoNewEditor(){
    let maskSplitD = this.maskDireita.split('|');
    let maskSplitE = this.maskEsquerda.split('|');

    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    //DIREITA
    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplitD[0]+this.UltraTextD.toUpperCase()+maskSplitD[1]+ this.LadoD.toUpperCase()+ maskSplitD[2]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTextoDireita}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplitD[3]}</p>`,
      }
    });

    this.descricoesDireita.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}-D`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplitD[4]}</p>`,
      }
    });

    this.conclusoesDireita.forEach((conclusao, index) => {
      if (conclusao.value && this.descricoesDireita[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao-D`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTextoDireita}</p>`,
      }
    });

    //ESQUERDA
    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplitE[0]+this.UltraTextE.toUpperCase()+maskSplitE[1]+ this.LadoE.toUpperCase()+ maskSplitE[2]}</p>`,
      }
    });


    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTextoEsquerda}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplitE[3]}</p>`,
      }
    });

    this.descricoesEsquerda.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}-E`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplitE[4]}</p>`,
      }
    });

    this.conclusoesEsquerda.forEach((conclusao, index) => {
      if (conclusao.value && this.descricoesEsquerda[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao-E`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTextoEsquerda}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });
  }

  public AdicionaTextoDireita(): any {
    let i;
    let maskSplit = this.maskDireita.split('|');
    let cabecalho = this.cabecalhoTexto;
    let rodape = this.rodapeTexto;

    this.htmlContentDireita = maskSplit[0];
    this.htmlContentDireita += this.UltraTextD.toUpperCase();
    this.htmlContentDireita += maskSplit[1];
    this.htmlContentDireita += this.LadoD.toUpperCase();
    this.htmlContentDireita += maskSplit[2];
    this.htmlContentDireita += this.indicacaoTextoDireita;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContentDireita += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContentDireita += maskSplit[3];

    for (i = 0; i < this.descricoesDireita.length; i++) {
      var newValue = this.descricoesDireita[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoesDireita[i].value = newValue2;
    }
    for (i = 0; i < this.descricoesDireita.length; i++) {
      if (this.descricoesDireita[i].value) {
        this.htmlContentDireita += '<br/>' + this.descricoesDireita[i].value + '<br/>';
      }
    }
    this.htmlContentDireita += maskSplit[4];
    for (i = 0; i < this.conclusoesDireita.length; i++) {
      if (this.conclusoesDireita[i].value) {
        this.htmlContentDireita += '<br/>' + this.conclusoesDireita[i].value + '<br/>';
      }
    }
    this.htmlContentDireita += this.achadosAdTextoDireita;
    if (this.cabecalhoTexto !== '' && this.rodapeTexto === '')
      this.htmlContent = cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    if (this.cabecalhoTexto === '' && this.rodapeTexto !== '')
      this.htmlContent = this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    if (this.cabecalhoTexto !== '' && this.rodapeTexto !== '')
      this.htmlContent = cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    if (this.cabecalhoTexto === '' && this.rodapeTexto === '')
      this.htmlContent = this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
  }

 public AdicionaTextoEsquerda(): any {
   debugger
    let i;
    let maskSplit = this.maskEsquerda.split('|');
    let cabecalho = this.cabecalhoTexto;
    let rodape = this.rodapeTexto;

    this.htmlContentEsquerda = maskSplit[0];
    this.htmlContentEsquerda += this.UltraTextE.toUpperCase();
    this.htmlContentEsquerda += maskSplit[1];
    this.htmlContentEsquerda += this.LadoE.toUpperCase();
    this.htmlContentEsquerda += maskSplit[2];
    this.htmlContentEsquerda += this.indicacaoTextoEsquerda;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContentEsquerda += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContentEsquerda += maskSplit[3];

    for (i = 0; i < this.descricoesEsquerda.length; i++) {
      var newValue = this.descricoesEsquerda[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoesEsquerda[i].value = newValue2;
    }
    for (i = 0; i < this.descricoesEsquerda.length; i++) {
      if (this.descricoesEsquerda[i].value) {
        this.htmlContentEsquerda += '<br/>' + this.descricoesEsquerda[i].value + '<br/>';
      }
    }
    this.htmlContentEsquerda += maskSplit[4];
    for (i = 0; i < this.conclusoesEsquerda.length; i++) {
      if (this.conclusoesEsquerda[i].value) {
        this.htmlContentEsquerda += '<br/>' + this.conclusoesEsquerda[i].value + '<br/>';
      }
    }
    this.htmlContentEsquerda += this.achadosAdTextoEsquerda;

    if (this.cabecalhoTexto !== '' && this.rodapeTexto === '')
      this.htmlContent = cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    if (this.cabecalhoTexto === '' && this.rodapeTexto !== '')
      this.htmlContent = this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    if (this.cabecalhoTexto !== '' && this.rodapeTexto !== '')
      this.htmlContent = cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    if (this.cabecalhoTexto === '' && this.rodapeTexto === '')
      this.htmlContent = this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
  }

  public enviaLadosDireita($event): any {
    this.LadoD = $event;
    this.AdicionaTexto(this.newEditor);
  }

  public enviaLadosEsquerda($event): any {
    this.LadoE = $event;
    this.AdicionaTexto(this.newEditor);
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';
    this.AdicionaTexto(this.newEditor);
  }

  enviaIndicacaoDireita(texto) {
    if (texto)
      this.indicacaoTextoDireita = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTextoDireita = '';
    this.AdicionaTexto(this.newEditor);
  }

  enviaIndicacaoEsquerda(texto) {
    if (texto)
      this.indicacaoTextoEsquerda = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTextoEsquerda = '';
    this.AdicionaTexto(this.newEditor);
  }

  enviaAchadosDireita(texto) {
    if (texto)
      this.achadosAdTextoDireita = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTextoDireita = '';

    this.AdicionaTexto(this.newEditor);
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';
    this.AdicionaTexto(this.newEditor);
  }

  enviaAchadosEsquerda(texto) {
    if (texto)
      this.achadosAdTextoEsquerda = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTextoEsquerda = '';

    this.AdicionaTexto(this.newEditor);
  }

  public EnviaUltraDireita($event): any {
    this.UltraTextD = $event;
    this.AdicionaTexto(this.newEditor);
  }

  public EnviaUltraEsquerda($event): any {
    this.UltraTextE = $event;
    this.AdicionaTexto(this.newEditor);
  }

  atualizaLinhaDireita({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoesDireita.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId}-D p`);
        const conclusion = document.querySelector('#e-conclusao-D p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesDireita.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesDireita.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
			} else if(indicacao || achados) {
				if(indicacao === undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados === undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  atualizaLinhaEsquerda({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoesEsquerda.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId}-E p`);
        const conclusion = document.querySelector('#e-conclusao-E p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesEsquerda.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesEsquerda.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
			} else if(indicacao || achados) {
				if(indicacao === undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados === undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  public RetornaConclusaoNull(Texto): any {
    let texto = '';
    texto = 'Exame dentro dos padrões da normalidade.';
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  public auditoriaUsuario(): any {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }
}
