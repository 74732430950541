import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { DescricaoConclusao } from '../../../models/Helper/DescricaoConclusao';
import { Term, Injuries } from 'src/app/models/BO/Term';
import { TermResult } from 'src/app/models/BO/TermResult';
import { SelectedItemEmiter } from 'src/app/utils/SelectedItemEmiter';
import { LaudosService } from 'src/app/services/laudos.service.spec';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Validacoes } from 'src/app/utils/validacoes';
import { CopyPaste } from 'src/app/utils/textEditor/CopyPaste';
import { MatDialog } from '@angular/material';
import { TermosGenericos } from '../../../../app/models/Helper/TermosGenericos';
import { TermosDinamicos } from '../../../models/Helper/TermosDinamicos';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { CommonService } from 'src/app/services/common.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

@Component({
  selector: 'app-cranio',
  templateUrl: './cranio.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./cranio.component.css']
})
export class CranioComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {listTerm:[1576],blockID:140},
    {listTerm:[4292],blockID:300},
    {listTerm:[4307],blockID:299},
    {listTerm:[4298],blockID:297},
    {listTerm:[4477],blockID:296},
    {listTerm:[4327],blockID:295},
    {listTerm:[4314],blockID:294},
    {listTerm:[4496],blockID:318},
    {listTerm:[4528],blockID:319},
    {listTerm:[4569],blockID:320},
    {listTerm:[4294],blockID:293},
  ]

  descricoes: DescricaoConclusao[] = [
    { value: '', blockID: 140, textTermID: 0, visivel: true },
    { value: '', blockID: 294, textTermID: 0, visivel: true },
    { value: '', blockID: 297, textTermID: 0, visivel: true },
    { value: 'Calota craniana sem alterações.', blockID: 295, textTermID: 0, visivel: true },
    { value: 'Sulcos e cisternas encefálicos normais.', blockID: 319, textTermID: 0, visivel: true },
    { value: 'Parênquima cerebral com morfologia e coeficientes de atenuação normais.', blockID: 296, textTermID: 0, visivel: true },
    { value: 'Tronco cerebral e cerebelo de aspecto conservado.', blockID: 298, textTermID: 0, visivel: true },
    { value: 'Sistema ventricular com morfologia e dimensões normais.', blockID: 300, textTermID: 0, visivel: true },
    { value: 'Estruturas da linha mediana sem desvios significativos.', blockID: 318, textTermID: 0, visivel: true },
    { value: 'Ausência de coleções hemorrágicas intra ou extra-axiais. ', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 299, textTermID: 0, visivel: true },
    { value: '', blockID: 293, textTermID: 0, visivel: true }

  ];

  conclusoes: DescricaoConclusao[] = [
    { value: 'Exame dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 294, textTermID: 0, visivel: true },
    { value: '', blockID: 297, textTermID: 0, visivel: true },
    { value: '', blockID: 295, textTermID: 0, visivel: true },
    { value: '', blockID: 319, textTermID: 0, visivel: true },
    { value: '', blockID: 296, textTermID: 0, visivel: true },
    { value: '', blockID: 298, textTermID: 0, visivel: true },
    { value: '', blockID: 300, textTermID: 0, visivel: true },
    { value: '', blockID: 318, textTermID: 0, visivel: true },
    { value: '', blockID: 299, textTermID: 0, visivel: true },
    { value: '', blockID: 293, textTermID: 0, visivel: true }
  ];

  genericIndicacao: TermosGenericos[] = [
    { value: '', selected: false, termID: 4576, term: '[Campo livre]' },
  ];

  genericAchados: TermosGenericos[] = [
    { value: '', selected: false, termID: 4351, term: '[Seio]' },
    { value: '', selected: false, termID: 4352, term: '[Lateralidade]' },
    { value: '', selected: false, termID: 4403, term: '[Seio Retencao]' },
    { value: '', selected: false, termID: 4405, term: '[Seio Osteoma]' },
    { value: '', selected: false, termID: 4406, term: '[Lado Osteoma]' },
    { value: '', selected: false, termID: 4407, term: '[Medida Osteoma]' },
    { value: '', selected: false, termID: 4409, term: '[LateralCristalino]' },
    { value: '', selected: false, termID: 4411, term: '[LateralEsclerais]' },
    { value: '', selected: false, termID: 4413, term: '[LateralMiopia]' },
    { value: '', selected: false, termID: 4415, term: '[LateralSequelares]' }
  ];

  genericMetodo: TermosGenericos[] = [
    { value: '', selected: false, termID: 1575, term: '[Tecnica]' },
    { value: '', selected: false, termID: 1578, term: '[Fase]' }
  ];

  genericPartes: TermosGenericos[] = [
    { value: '', selected: false, termID: 4303, term: '[Localização]' },
    { value: '', selected: false, termID: 4304, term: '[Lateralidade]' },
    { value: '', selected: false, termID: 4669, term: '[LocalizaçãoHematoma]' },
    { value: '', selected: false, termID: 4670, term: '[LateralidadeHematoma]' },
    { value: '', selected: false, termID: 4671, term: '[LocalizaçãoColecao]' },
    { value: '', selected: false, termID: 4672, term: '[LateralidadeColecao]' },
    { value: '', selected: false, termID: 4305, term: '[Característica]' },
    { value: '', selected: false, termID: 4673, term: '[LateralidadeManipulacao]' },
    { value: '', selected: false, termID: 4674, term: '[LocalizaçãoManipulacao]' },
    { value: '', selected: false, termID: 4675, term: '[LateralidadeLipoma]' },
    { value: '', selected: false, termID: 4676, term: '[LocalizaçãoLipoma]' },
    { value: '', selected: false, termID: 4566, term: '[Medida]' },
    { value: '', selected: false, termID: 4677, term: '[LocalizaçãoCalcificacoes]' },
    { value: '', selected: false, termID: 4678, term: '[LateralidadeCalcificacoes]' },
  ];

  genericVasos: TermosGenericos[] = [
    { value: '', selected: false, termID: 4312, term: '[Lateralidade]' },
    { value: '', selected: false, termID: 4442, term: '[LocaTrombo]' },
    { value: '', selected: false, termID: 4443, term: '[LateralTrombo]' },
    { value: '', selected: false, termID: 4445, term: '[LocaGranula]' },
    { value: '', selected: false, termID: 4446, term: '[LateralGranula]' },
    { value: '', selected: false, termID: 4448, term: '[LocaDva]' },
    { value: '', selected: false, termID: 4449, term: '[LateralDva]' }
  ];

  genericDispositivos: TermosGenericos[] = [
    { value: '', selected: false, termID: 4427, term: '[LateralSonNasal]' },
    { value: '', selected: false, termID: 4318, term: '[Entrada]' },
    { value: '', selected: false, termID: 4319, term: '[LadosCateter]' },
    { value: '', selected: false, termID: 4320, term: '[Extremidades]' },
    { value: '', selected: false, termID: 4322, term: '[Topografia]' },
    { value: '', selected: false, termID: 4323, term: '[Lado]' },
    { value: '', selected: false, termID: 4325, term: '[TopografiaStent]' },
    { value: '', selected: false, termID: 4326, term: '[LadoStent]' },
    { value: '', selected: false, termID: 4423, term: '[TopografiaMaterial]' },
    { value: '', selected: false, termID: 4424, term: '[LadoMaterial]' },
    { value: '', selected: false, termID: 4426, term: '[LadoAuditivo]' }
  ];


  genericEstruturas: TermosGenericos[] = [
    { value: '', selected: false, termID: 4332, term: '[Localizacao]' },
    { value: '', selected: false, termID: 4333, term: '[Lado]' },
    { value: '', selected: false, termID: 4337, term: '[LocalizacaoCom]' },
    { value: '', selected: false, termID: 4338, term: '[LadoCom]' },
    { value: '', selected: false, termID: 4342, term: '[LocalizacaoTre]' },
    { value: '', selected: false, termID: 4343, term: '[LadoTre]' },
    { value: '', selected: false, termID: 4346, term: '[LocalizacaoCra]' },
    { value: '', selected: false, termID: 4347, term: '[LadoCra]' },
    { value: '', selected: false, termID: 4349, term: '[LocalizacaoCraniec]' },
    { value: '', selected: false, termID: 4350, term: '[LadoCraniec]' },
    { value: '', selected: false, termID: 4430, term: '[LocalizacaoHema]' },
    { value: '', selected: false, termID: 4431, term: '[LadoHema]' },
    { value: '', selected: false, termID: 4439, term: '[Fontanela anterior]' },
    { value: '', selected: false, termID: 4440, term: '[Fontanela posterior]' },
  ];

  genericVentriculos: TermosGenericos[] = [
    { value: '', selected: false, termID: 4579, term: '[Lateralidade]' },
    { value: '', selected: false, termID: 4581, term: '[Cavum]' },
    { value: '', selected: false, termID: 4587, term: '[Ventriculos]' },
    { value: '', selected: false, termID: 4588, term: '[Graus]' },
    { value: '', selected: false, termID: 4593, term: '[VentriHemos]' },
    { value: '', selected: false, termID: 4595, term: '[Medida]' },
    { value: '', selected: false, termID: 4597, term: '[GrausCisto]' },
    { value: '', selected: false, termID: 4601, term: '[Localizacao]' },
    { value: '', selected: false, termID: 4602, term: '[Lado]' },
  ];

  genericEfeitoExpansivo: TermosGenericos[] = [
    { value: '', selected: false, termID: 4498, term: '[Lado]' },
    { value: '', selected: false, termID: 4499, term: '[Medida]' },
    { value: '', selected: false, termID: 4501, term: '[Ventriculo EE]' },
    { value: '', selected: false, termID: 4505, term: '[Desvio]' },
    { value: '', selected: false, termID: 4506, term: '[Medida EE]' },
    { value: '', selected: false, termID: 4508, term: '[Lado HS]' },
    { value: '', selected: false, termID: 4510, term: '[Lado HTDA]' },
    { value: '', selected: false, termID: 4515, term: '[Lado HTD]' },
    { value: '', selected: false, termID: 4519, term: '[Lado HTA]' }
  ];

  genericSulcosSisternas: TermosGenericos[] = [
    { value: '', selected: false, termID: 4537, term: '[Localizacao]' },
    { value: '', selected: false, termID: 4538, term: '[Lado]' },
    { value: '', selected: false, termID: 4539, term: '[Aferida]' },
    { value: '', selected: false, termID: 4540, term: '[Desvio]' },
    { value: '', selected: false, termID: 4541, term: '[Desvio med]' },
    { value: '', selected: false, termID: 4543, term: '[Localizacao HS]' },
    { value: '', selected: false, termID: 4544, term: '[Lado HS]' },
    { value: '', selected: false, termID: 4545, term: '[Medida HS]' },
    { value: '', selected: false, termID: 4546, term: '[Aferida HS]' },
    { value: '', selected: false, termID: 4547, term: '[Desvio HS]' },
    { value: '', selected: false, termID: 4548, term: '[Desvio med HS]' },
    { value: '', selected: false, termID: 4550, term: '[Localizacao HSub]' },
    { value: '', selected: false, termID: 4551, term: '[Lado HSub]' },
    { value: '', selected: false, termID: 4552, term: '[Cisternas HSub]' },
    { value: '', selected: false, termID: 4556, term: '[Medida]' }
  ];

  genericParenquima: TermosGenericos[] = [
    { value: '', selected: false, termID: 4450, term: '[LateralidadeLacuna]' },
    { value: '', selected: false, termID: 4451, term: '[LocalizacaoLacuna]' },
    { value: '', selected: false, termID: 4452, term: '[LocalizacaoEnce]' },
    { value: '', selected: false, termID: 4453, term: '[LadoEnce]' },
    { value: '', selected: false, termID: 4454, term: '[GrauEnce]' },
    { value: '', selected: false, termID: 4455, term: '[VentriculoEnce]' },
    { value: '', selected: false, termID: 4456, term: '[LocalizacaoAvc]' },
    { value: '', selected: false, termID: 4457, term: '[LateralidadeAvc]' },
    { value: '', selected: false, termID: 4458, term: '[ArteriaAvc]' },
    { value: '', selected: false, termID: 4459, term: '[LocalizacaoHemo]' },
    { value: '', selected: false, termID: 4460, term: '[LadoHemo]' },
    { value: '', selected: false, termID: 4461, term: '[ContornoHemo]' },
    { value: '', selected: false, termID: 4462, term: '[AtenuacaoHemo]' },
    { value: '', selected: false, termID: 4463, term: '[MedidaHemo]' },
    { value: '', selected: false, termID: 4464, term: '[VolumeHemo]' },
    { value: '', selected: false, termID: 4465, term: '[LocalizacaoCalc]' },
    { value: '', selected: false, termID: 4466, term: '[LadoCalc]' },
    { value: '', selected: false, termID: 4467, term: '[LocalizacaoIntra]' },
    { value: '', selected: false, termID: 4468, term: '[LadoIntra]' },
    { value: '', selected: false, termID: 4469, term: '[AtenuacaoIntra]' },
    { value: '', selected: false, termID: 4470, term: '[ImpregnacaoIntra]' },
    { value: '', selected: false, termID: 4471, term: '[MedidaIntra]' },
    { value: '', selected: false, termID: 4472, term: '[LocalizacaoExtra]' },
    { value: '', selected: false, termID: 4473, term: '[LadoExtra]' },
    { value: '', selected: false, termID: 4474, term: '[MedidaExtra]' },
    { value: '', selected: false, termID: 4475, term: '[MedidaTons]' },
  ];

  Subtitulo: string;
  subtituloBloco: string;
  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  dinamicLesions: TermosDinamicos[] = [];
  dinamicLesion: any;
  dinamicconclussion: TermosDinamicos[] = this.dinamicLesions;
  lesionRow = 1;
  cabecalhoTexto = '';
  achadosAdTexto = '';
  indicacaoTexto = '';
  rodapeTexto = '';

  cabecalho = false;
  indicacao = false;
  metodo = true;
  ventriculos = false;
  achadoAdicionais = false;
  vasos = false
  partesMoles = false;
  dispositivos = false;
  estruturasOsseas = false;
  efeitoExpansivo = false;
  sulcosCisternas = false;
  parenquima = false;
  rodape = false;
  editLaudoMode: boolean = false;

  medicalReportTypeID = 52;
  metodoID = 140;
  ventriculosID = 300;
  vasosID = 299;
  troncoCerebeloID = 298;
  partesMolesID = 297;
  parenquimaID = 296;
  estruturasOsseasID = 295;
  dispositivosID = 294;
  efeitoExpansivoID = 318;
  sulcosCisternasID = 319;
  indicacaoID = 320;
  achadoAdicionaisID = 293

  public switchFormReport = true;
  public showForm: Boolean = true;
  unidadeMedida: string;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];

  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _validacoes: Validacoes,
    private _toastr: ToastrManager,
    private _copyPaste: CopyPaste,
    private _serviceMediocalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    public dialog: MatDialog,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    public _addText: AddText,
    private _config: ConfigLaudoService,
    private dataService:DataTermService) { }

  ngOnInit() {
    this._toastr.warningToastr('Atenção esse exame ainda está em beta, caso venha a ocorrer algum problema reporte ao nosso suporte.', 'Atenção!');
    this.utilizarEditor();
    this._pouchService.loadDatabase("tc-cranio", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Método';
    this.subtituloBloco = 'Método';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });

    const listBlocks = [
      this.metodoID,
      this.ventriculosID,
      this.vasosID,
      this.troncoCerebeloID,
      this.partesMolesID,
      this.parenquimaID,
      this.estruturasOsseasID,
      this.dispositivosID,
      this.efeitoExpansivoID,
      this.sulcosCisternasID,
      this.indicacaoID,
      this.achadoAdicionaisID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "tc-cranioMask").then((obj: any) => {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "cranio");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'tc-cranio');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'tc-cranio');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }

  }

  public resetaDialog() {
    this._common.invokeEvent.subscribe(event => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.resetaLaudo();
        }
      });
    });
  }

  public resetaLaudo() {
    this._pouchService.getAll("tc-cranio").then((obj) => {
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.descricoes[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.achadosAdTexto = "";
    this.indicacaoTexto = "";
    this.showForm = false;
    this.rodapeTexto = "";

    setTimeout(() => {
      this.showForm = true;
      this.AdicionaTexto(this.newEditor);
    }, 500);
  };

  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false
    },
    {
      id: this.indicacaoID,
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: false
    },
    {
      id: this.metodoID,
      name: 'metodo',
      titulo: 'Método',
      selected: true
    },
    {
      id: this.partesMolesID,
      name: 'partes-moles',
      titulo: 'Partes Moles',
      selected: false
    },
    {
      id: this.dispositivosID,
      name: 'dispositivos',
      titulo: 'Dispositivos, Tubos e Cateters',
      selected: false
    },
    {
      id: this.parenquimaID,
      name: 'parenquima-cranio',
      titulo: 'Parênquima',
      selected: false
    },
    {
      id: this.estruturasOsseasID,
      name: 'estruturas-osseas',
      titulo: 'Estruturas Osseas',
      selected: false
    },
    {
      id: this.sulcosCisternasID,
      name: 'sulcos-cisternas',
      titulo: 'Sulcos e cisternas',
      selected: false
    },
    {
      id: this.ventriculosID,
      name: 'ventriculos',
      titulo: 'Ventrículos',
      selected: false
    },
    {
      id: this.efeitoExpansivoID,
      name: 'efeito-expansivo',
      titulo: 'Efeito expansivo',
      selected: false
    },
    {
      id: this.vasosID,
      name: 'vasos',
      titulo: 'Vasos',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: this.achadoAdicionaisID,
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.rodape =  (itemSelecionado.id === 'rodape');
    this.indicacao = (itemSelecionado.id === this.indicacaoID);
    this.metodo = (itemSelecionado.id === this.metodoID);
    this.achadoAdicionais = (itemSelecionado.id === this.achadoAdicionaisID);
    this.ventriculos = (itemSelecionado.id === this.ventriculosID);
    this.vasos = (itemSelecionado.id === this.vasosID);
    this.partesMoles = (itemSelecionado.id === this.partesMolesID);
    this.dispositivos = (itemSelecionado.id === this.dispositivosID);
    this.efeitoExpansivo = (itemSelecionado.id === this.efeitoExpansivoID);
    this.sulcosCisternas = (itemSelecionado.id === this.sulcosCisternasID);
    this.estruturasOsseas = (itemSelecionado.id === this.estruturasOsseasID);
    this.parenquima = (itemSelecionado.id === this.parenquimaID);
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.dinamicLesions = [];
    this.lesionRow = 1;
    this.cenarioCorrente = 0;

    this.genericIndicacao = [
      { value: '', selected: false, termID: 4576, term: '[Campo livre]' },
    ];

    this.genericAchados = [
      { value: '', selected: false, termID: 4351, term: '[Seio]' },
      { value: '', selected: false, termID: 4352, term: '[Lateralidade]' },
      { value: '', selected: false, termID: 4403, term: '[Seio Retencao]' },
      { value: '', selected: false, termID: 4405, term: '[Seio Osteoma]' },
      { value: '', selected: false, termID: 4406, term: '[Lado Osteoma]' },
      { value: '', selected: false, termID: 4407, term: '[Medida Osteoma]' },
      { value: '', selected: false, termID: 4409, term: '[LateralCristalino]' },
      { value: '', selected: false, termID: 4411, term: '[LateralEsclerais]' },
      { value: '', selected: false, termID: 4413, term: '[LateralMiopia]' },
      { value: '', selected: false, termID: 4415, term: '[LateralSequelares]' }
    ];

    this.genericMetodo = [
      { value: '', selected: false, termID: 1575, term: '[Tecnica]' },
      { value: '', selected: false, termID: 1578, term: '[Fase]' }
    ];

    this.genericPartes = [
      { value: '', selected: false, termID: 4303, term: '[Localização]' },
      { value: '', selected: false, termID: 4304, term: '[Lateralidade]' },
      { value: '', selected: false, termID: 4669, term: '[LocalizaçãoHematoma]' },
      { value: '', selected: false, termID: 4670, term: '[LateralidadeHematoma]' },
      { value: '', selected: false, termID: 4671, term: '[LocalizaçãoColecao]' },
      { value: '', selected: false, termID: 4672, term: '[LateralidadeColecao]' },
      { value: '', selected: false, termID: 4305, term: '[Característica]' },
      { value: '', selected: false, termID: 4673, term: '[LateralidadeManipulacao]' },
      { value: '', selected: false, termID: 4674, term: '[LocalizaçãoManipulacao]' },
      { value: '', selected: false, termID: 4675, term: '[LateralidadeLipoma]' },
      { value: '', selected: false, termID: 4676, term: '[LocalizaçãoLipoma]' },
      { value: '', selected: false, termID: 4566, term: '[Medida]' },
      { value: '', selected: false, termID: 4677, term: '[LocalizaçãoCalcificacoes]' },
      { value: '', selected: false, termID: 4678, term: '[LateralidadeCalcificacoes]' },
    ];

    this.genericVasos = [
      { value: '', selected: false, termID: 4312, term: '[Lateralidade]' },
      { value: '', selected: false, termID: 4442, term: '[LocaTrombo]' },
      { value: '', selected: false, termID: 4443, term: '[LateralTrombo]' },
      { value: '', selected: false, termID: 4445, term: '[LocaGranula]' },
      { value: '', selected: false, termID: 4446, term: '[LateralGranula]' },
      { value: '', selected: false, termID: 4448, term: '[LocaDva]' },
      { value: '', selected: false, termID: 4449, term: '[LateralDva]' }
    ];

    this.genericDispositivos = [
      { value: '', selected: false, termID: 4427, term: '[LateralSonNasal]' },
      { value: '', selected: false, termID: 4318, term: '[Entrada]' },
      { value: '', selected: false, termID: 4319, term: '[LadosCateter]' },
      { value: '', selected: false, termID: 4320, term: '[Extremidades]' },
      { value: '', selected: false, termID: 4322, term: '[Topografia]' },
      { value: '', selected: false, termID: 4323, term: '[Lado]' },
      { value: '', selected: false, termID: 4325, term: '[TopografiaStent]' },
      { value: '', selected: false, termID: 4326, term: '[LadoStent]' },
      { value: '', selected: false, termID: 4423, term: '[TopografiaMaterial]' },
      { value: '', selected: false, termID: 4424, term: '[LadoMaterial]' },
      { value: '', selected: false, termID: 4426, term: '[LadoAuditivo]' }
    ];

    this.genericEstruturas = [
      { value: '', selected: false, termID: 4332, term: '[Localizacao]' },
      { value: '', selected: false, termID: 4333, term: '[Lado]' },
      { value: '', selected: false, termID: 4337, term: '[LocalizacaoCom]' },
      { value: '', selected: false, termID: 4338, term: '[LadoCom]' },
      { value: '', selected: false, termID: 4342, term: '[LocalizacaoTre]' },
      { value: '', selected: false, termID: 4343, term: '[LadoTre]' },
      { value: '', selected: false, termID: 4346, term: '[LocalizacaoCra]' },
      { value: '', selected: false, termID: 4347, term: '[LadoCra]' },
      { value: '', selected: false, termID: 4349, term: '[LocalizacaoCraniec]' },
      { value: '', selected: false, termID: 4350, term: '[LadoCraniec]' },
      { value: '', selected: false, termID: 4430, term: '[LocalizacaoHema]' },
      { value: '', selected: false, termID: 4431, term: '[LadoHema]' },
      { value: '', selected: false, termID: 4439, term: '[Fontanela anterior]' },
      { value: '', selected: false, termID: 4440, term: '[Fontanela posterior]' },
    ];

    this.genericVentriculos = [
      { value: '', selected: false, termID: 4579, term: '[Lateralidade]' },
      { value: '', selected: false, termID: 4581, term: '[Cavum]' },
      { value: '', selected: false, termID: 4587, term: '[Ventriculos]' },
      { value: '', selected: false, termID: 4588, term: '[Graus]' },
      { value: '', selected: false, termID: 4593, term: '[VentriHemos]' },
      { value: '', selected: false, termID: 4595, term: '[Medida]' },
      { value: '', selected: false, termID: 4597, term: '[GrausCisto]' },
      { value: '', selected: false, termID: 4601, term: '[Localizacao]' },
      { value: '', selected: false, termID: 4602, term: '[Lado]' },
    ];


    this.genericEfeitoExpansivo = [
      { value: '', selected: false, termID: 4498, term: '[Lado]' },
      { value: '', selected: false, termID: 4499, term: '[Medida]' },
      { value: '', selected: false, termID: 4501, term: '[Ventriculo EE]' },
      { value: '', selected: false, termID: 4505, term: '[Desvio]' },
      { value: '', selected: false, termID: 4506, term: '[Medida EE]' },
      { value: '', selected: false, termID: 4508, term: '[Lado HS]' },
      { value: '', selected: false, termID: 4510, term: '[Lado HTDA]' },
      { value: '', selected: false, termID: 4515, term: '[Lado HTD]' },
      { value: '', selected: false, termID: 4519, term: '[Lado HTA]' }
    ];

    this.genericSulcosSisternas = [
      { value: '', selected: false, termID: 4537, term: '[Localizacao]' },
      { value: '', selected: false, termID: 4538, term: '[Lado]' },
      { value: '', selected: false, termID: 4539, term: '[Aferida]' },
      { value: '', selected: false, termID: 4540, term: '[Desvio]' },
      { value: '', selected: false, termID: 4541, term: '[Desvio med]' },
      { value: '', selected: false, termID: 4543, term: '[Localizacao HS]' },
      { value: '', selected: false, termID: 4544, term: '[Lado HS]' },
      { value: '', selected: false, termID: 4545, term: '[Medida HS]' },
      { value: '', selected: false, termID: 4546, term: '[Aferida HS]' },
      { value: '', selected: false, termID: 4547, term: '[Desvio HS]' },
      { value: '', selected: false, termID: 4548, term: '[Desvio med HS]' },
      { value: '', selected: false, termID: 4550, term: '[Localizacao HSub]' },
      { value: '', selected: false, termID: 4551, term: '[Lado HSub]' },
      { value: '', selected: false, termID: 4552, term: '[Cisternas HSub]' },
      { value: '', selected: false, termID: 4556, term: '[Medida]' }
    ];

    this.genericParenquima = [
      { value: '', selected: false, termID: 4450, term: '[LateralidadeLacuna]' },
      { value: '', selected: false, termID: 4451, term: '[LocalizacaoLacuna]' },
      { value: '', selected: false, termID: 4452, term: '[LocalizacaoEnce]' },
      { value: '', selected: false, termID: 4453, term: '[LadoEnce]' },
      { value: '', selected: false, termID: 4454, term: '[GrauEnce]' },
      { value: '', selected: false, termID: 4455, term: '[VentriculoEnce]' },
      { value: '', selected: false, termID: 4456, term: '[LocalizacaoAvc]' },
      { value: '', selected: false, termID: 4457, term: '[LateralidadeAvc]' },
      { value: '', selected: false, termID: 4458, term: '[ArteriaAvc]' },
      { value: '', selected: false, termID: 4459, term: '[LocalizacaoHemo]' },
      { value: '', selected: false, termID: 4460, term: '[LadoHemo]' },
      { value: '', selected: false, termID: 4461, term: '[ContornoHemo]' },
      { value: '', selected: false, termID: 4462, term: '[AtenuacaoHemo]' },
      { value: '', selected: false, termID: 4463, term: '[MedidaHemo]' },
      { value: '', selected: false, termID: 4464, term: '[VolumeHemo]' },
      { value: '', selected: false, termID: 4465, term: '[LocalizacaoCalc]' },
      { value: '', selected: false, termID: 4466, term: '[LadoCalc]' },
      { value: '', selected: false, termID: 4467, term: '[LocalizacaoIntra]' },
      { value: '', selected: false, termID: 4468, term: '[LadoIntra]' },
      { value: '', selected: false, termID: 4469, term: '[AtenuacaoIntra]' },
      { value: '', selected: false, termID: 4470, term: '[ImpregnacaoIntra]' },
      { value: '', selected: false, termID: 4471, term: '[MedidaIntra]' },
      { value: '', selected: false, termID: 4472, term: '[LocalizacaoExtra]' },
      { value: '', selected: false, termID: 4473, term: '[LadoExtra]' },
      { value: '', selected: false, termID: 4474, term: '[MedidaExtra]' },
      { value: '', selected: false, termID: 4475, term: '[MedidaTons]' },
    ];
  }



  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  public enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  incluirLesoesDinamicas($event) {
    const lesoesValue = $event.elemento.value;
    if ($event.linha === 1 && ($event.posicao === 0 || $event.posicao === 1)) {
      if ($event.isPrimeiraLesao === true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaLesoes($event.elemento);
      }
    }

    switch ($event.elemento.id) {
      case '4332':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4332,
          lesionID: 4329,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localizacao]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4333':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4333,
          lesionID: 4329,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Lado]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4337':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 4337,
          lesionID: 4334,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[LocalizacaoCom]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4338':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4338,
          lesionID: 4334,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[LadoCom]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4342':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4342,
          lesionID: 4339,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[LocalizacaoTre]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4343':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4343,
          lesionID: 4339,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[LadoTre]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }
    this.dinamicconclussion = this.dinamicLesions;
    this.lesionRow = $event.linha;

  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  adicionaSelecaoIndicacao($event) {
    this.genericIndicacao.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoAchados($event) {
    this.genericAchados.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoVentriculos($event) {
    this.genericVentriculos.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoMetodo($event) {
    this.genericMetodo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoPartes($event) {
    this.genericPartes.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });

    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoVasos($event) {
    this.genericVasos.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoDispositivos($event) {
    this.genericDispositivos.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoEstruturasOsseas($event) {
    this.genericEstruturas.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoEfeitoExpansivo($event) {
    this.genericEfeitoExpansivo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoSulcosCisternas($event) {
    this.genericSulcosSisternas.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoParenquima($event) {
    this.genericParenquima.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.metodo) {
        this.term.blockID = this.metodoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.metodoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaMetodo(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.metodoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.metodoID,
              descricao: desc,
              conclusao: '',
              conclusaoNull: ''
            });
          }
        });

      } else if (this.ventriculos) {
        this.term.blockID = this.ventriculosID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.ventriculosID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaVentriculos(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.ventriculosID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.ventriculosID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaVentriculos(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ventriculosID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ventriculosID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.ventriculosID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.achadoAdicionais) {
        this.term.blockID = this.achadoAdicionaisID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.achadoAdicionaisID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaachadoAdicionais(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.achadoAdicionaisID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.achadoAdicionaisID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaachadoAdicionais(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.achadoAdicionaisID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.achadoAdicionaisID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }
          ''
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.achadoAdicionaisID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.partesMoles) {
        this.term.blockID = this.partesMolesID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.partesMolesID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoConclusaoPartesMoles(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              const index = this.descricoes.findIndex(x => x.blockID === this.partesMolesID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.partesMolesID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoConclusaoPartesMoles(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              contadorTexto++;
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.partesMolesID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.partesMolesID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.partesMolesID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.vasos) {
        this.term.blockID = this.vasosID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.vasosID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoConclusaoVasos(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });
              const index = this.descricoes.findIndex(x => x.blockID === this.vasosID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });
          let obj = {
            value: '',
            blockID: this.vasosID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            console.log(resultado);
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoConclusaoVasos(resultado.conclusion, contadorTexto);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
            contadorTexto++;
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.vasosID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.vasosID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.vasosID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.dispositivos) {
        this.term.blockID = this.dispositivosID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.dispositivosID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDispositivos(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.dispositivosID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.dispositivosID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.dispositivosID;
              obj.value = obj.value + espaco + this.MontaDispositivos(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              contadorTexto++;
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = this.dispositivosID;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.dispositivosID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.dispositivosID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.dispositivosID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.estruturasOsseas) {
        this.term.blockID = this.estruturasOsseasID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.estruturasOsseasID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoEstruturasOsseas(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.estruturasOsseasID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.estruturasOsseasID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {

            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoEstruturasOsseas(resultado.conclusion, contadorTexto);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
            contadorTexto++;
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.estruturasOsseasID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.estruturasOsseasID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.estruturasOsseasID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.efeitoExpansivo) {
        this.term.blockID = this.efeitoExpansivoID;
        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.efeitoExpansivoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoConclusaoEfeitoExpansivo(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.efeitoExpansivoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.efeitoExpansivoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoConclusaoEfeitoExpansivo(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.efeitoExpansivoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.efeitoExpansivoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.efeitoExpansivoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.sulcosCisternas) {
        this.term.blockID = this.sulcosCisternasID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.sulcosCisternasID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoConclusaoSulcosCisternas(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.sulcosCisternasID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.sulcosCisternasID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoConclusaoSulcosCisternas(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.sulcosCisternasID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.sulcosCisternasID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.sulcosCisternasID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.indicacao) {
        this.term.blockID = this.indicacaoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.termResultsAPI = termResults;
          this.termResultsAPI.forEach(resultado => {
            this.indicacaoTexto = this.MontaDescricaoConclusaoIndicacao(resultado.text);
            desc = this.indicacaoTexto;

          });

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.indicacaoID,
              descricao: desc,
              conclusao: '',
              conclusaoNull: ''
            });
          }
        });

      } else if (this.parenquima) {
      this.term.blockID = this.parenquimaID;
      this.term.injuries.push(this.injuries);
      this.cenarioCorrente++;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.parenquimaID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              if (resultado.text) {
                obj.value = obj.value + espaco + this.MontaDescricaoConclusaoParenquima(resultado.text, contadorTexto);
                espaco = '</br>';
              }
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.parenquimaID);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        let obj = {
          value: '',
          blockID: this.parenquimaID,
          textTermID: 0
        };
        let espaco = '';
        let contadorTexto = 0;
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaDescricaoConclusaoParenquima(resultado.conclusion, contadorTexto);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.parenquimaID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.parenquimaID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: +this.parenquimaID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
      }
  }
}

  MontaachadoAdicionais(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 4351 || +term === 4352 || +term === 4403 || +term === 4405 || +term === 4406
          || +term === 4407 || +term === 4409 || +term === 4411 || +term === 4413 || +term === 4415) {
          this.genericAchados.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4351 || +term === 4352 || +term === 4403 || +term === 4405 || +term === 4406
          || +term === 4407 || +term === 4409 || +term === 4411 || +term === 4413 || +term === 4415) {
          this.genericAchados.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaMetodo(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1575 || +term === 1578) {
          this.genericMetodo.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1575 || +term === 1578) {
          this.genericMetodo.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaVentriculos(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4579 || +term === 4581 || +term === 4587 || +term === 4588 || +term === 4593
         || +term === 4595 || +term === 4597 || +term === 4601 || +term === 4602) {
        this.genericVentriculos.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoConclusaoPartesMoles(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 4303 || +term === 4304 || +term === 4305 || +term === 4566 || +term === 4669 || +term === 4670 ||
          +term === 4671 || +term === 4672 || +term === 4673 || +term === 4674 || +term === 4675 || +term === 4676 ||
          +term === 4677 || +term === 4678) {
          this.genericPartes.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4303 || +term === 4304 || +term === 4305 || +term === 4566 || +term === 4669 || +term === 4670 ||
          +term === 4671 || +term === 4672 || +term === 4673 || +term === 4674 || +term === 4675 || +term === 4676 ||
          +term === 4677 || +term === 4678) {
          this.genericPartes.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaDescricaoConclusaoVasos(texto, contadorTexto) {

    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 4312 || +term === 4442 || +term === 4443 || +term === 4445 || +term === 4446 || +term === 4448 || +term === 4449) {
          this.genericVasos.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4312 || +term === 4442 || +term === 4443 || +term === 4445 || +term === 4446 || +term === 4448 || +term === 4449) {
          this.genericVasos.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDispositivos(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 4427 || +term === 4318 || +term === 4319 || +term === 4320 || +term === 4322 || +term === 4323 ||
          +term === 4325 || +term === 4326 || +term === 4423 || +term === 4424 || +term === 4426) {
          this.genericDispositivos.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4427 || +term === 4318 || +term === 4319 || +term === 4320 || +term === 4322 || +term === 4323 ||
          +term === 4325 || +term === 4326 || +term === 4423 || +term === 4424 || +term === 4426) {
          this.genericDispositivos.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaDescricaoEstruturasOsseas(texto, contadorTexto) {
    let xpto = this.dinamicLesions;
    const rowDinamic = xpto.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoDescricaoEstruturasOsseasDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoEstruturasOsseasDescNormal(texto, contadorTexto);
    }
    return texto;
  }

  MontaConclusaoEstruturasOsseas(texto, contadorTexto) {
    const rowDinamic = this.dinamicconclussion.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      //texto = this.TextoConclusaoEstruturasOsseasDinamica(texto, contadorTexto, lesaoID);
      texto = this.TextoConclusaoEstruturasOsseas(texto, contadorTexto);
    } else {
      texto = this.TextoConclusaoEstruturasOsseas(texto, contadorTexto);
    }
    return texto;

  }

  MontaDescricaoConclusaoParenquima(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 4450 || +term === 4451 || +term === 4452 || +term === 4453 || +term === 4454|| +term === 4455 ||
          +term === 4456 || +term === 4457 || +term === 4458 || +term === 4459 || +term === 4460 || +term === 4461 ||
          +term === 4462 || +term === 4463 || +term === 4464 || +term === 4465 || +term === 4466 || +term === 4467 ||
          +term === 4468 || +term === 4469 || +term === 4470 || +term === 4471 || +term === 4472 || +term === 4473 ||
          +term === 4474 || +term === 4475) {
          this.genericParenquima.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4450 || +term === 4451 || +term === 4452 || +term === 4453 || +term === 4454|| +term === 4455 ||
          +term === 4456 || +term === 4457 || +term === 4458 || +term === 4459 || +term === 4460 || +term === 4461 ||
          +term === 4462 || +term === 4463 || +term === 4464 || +term === 4465 || +term === 4466 || +term === 4467 ||
          +term === 4468 || +term === 4469 || +term === 4470 || +term === 4471 || +term === 4472 || +term === 4473 ||
          +term === 4474 || +term === 4475) {
          this.genericParenquima.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  TextoEstruturasOsseasDescNormal(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 4332 || +term === 4333 || +term === 4337 || +term === 4338 || +term === 4342 || +term === 4343 || +term === 4346 ||
          +term === 4347 || +term === 4349 || +term === 4350 || +term === 4430 || +term === 4431 || +term === 4439 || +term === 4440) {
          this.genericEstruturas.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4332 || +term === 4333 || +term === 4337 || +term === 4338 || +term === 4342 || +term === 4343 || +term === 4346 ||
          +term === 4347 || +term === 4349 || +term === 4350 || +term === 4430 || +term === 4431 || +term === 4439 || +term === 4440) {
          this.genericEstruturas.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  TextoDescricaoEstruturasOsseasDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
    }

    // Lista term
    this.term.listTermID.forEach(term => {
      if (+term === 4332 || +term === 4333 || +term === 4337 || +term === 4338 || +term === 4342 || +term === 4343 || +term === 4346 ||
        +term === 4347 || +term === 4349 || +term === 4350 || +term === 4430 || +term === 4431 || +term === 4439 || +term === 4440
      ) {
        this.genericEstruturas.forEach(generic => {
          if (+generic.termID === +term) {
            textoRetorno = textoRetorno.replace(generic.term, generic.value);
          }
        });
      }
    });

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID == 4332 || item.termID == 4333 || item.termID == 4337 || item.termID == 4338 || item.termID == 4342 ||
            item.termID == 4343 || item.termID == 4346 || item.termID == 4347 || item.termID == 4349 || item.termID == 4350 ||
            item.termID === 4430 || item.termID === 4431 || item.termID === 4439 || item.termID === 4440
          ) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
          }

          if (item.termID == 4332 || item.termID == 4333 || item.termID == 4337 || item.termID == 4338 || item.termID == 4342 ||
            item.termID == 4343 || item.termID == 4346 || item.termID == 4347 || item.termID == 4349 || item.termID == 4350 ||
            item.termID === 4430 || item.termID === 4431 || item.termID === 4439 || item.termID === 4440
          ) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });

    return texto;
  }

  TextoConclusaoEstruturasOsseas(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 4332 || +term === 4333 || +term === 4337 || +term === 4338 || +term === 4342 || +term === 4343 || +term === 4346 ||
          +term === 4347 || +term === 4349 || +term === 4350 || +term === 4430 || +term === 4431 || +term === 4439 || +term === 4440) {
          this.genericEstruturas.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4332 || +term === 4333 || +term === 4337 || +term === 4338 || +term === 4342 || +term === 4343 || +term === 4346 ||
          +term === 4347 || +term === 4349 || +term === 4350 || +term === 4430 || +term === 4431 || +term === 4439 || +term === 4440) {
          this.genericEstruturas.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  TextoConclusaoEstruturasOsseasDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = "";//textoSplit[0];
      textoDinamico = textoSplit[1] + '</br>';
    }

    this.dinamicconclussion.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID == 4332 || item.termID == 4333 || item.termID == 4337 || item.termID == 4338 || item.termID == 4342 ||
            item.termID == 4343 || item.termID == 4346 || item.termID == 4347 || item.termID == 4349 || item.termID == 4350 ||
            item.termID === 4430 || item.termID === 4431 || item.termID === 4439 || item.termID === 4440) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length == 1) {
            textoDinamico = '</br>' + textoSplit[0];
          } else {
            textoDinamico = textoSplit[1] + '</br>';
          }

          if (item.termID == 4332 || item.termID == 4333 || item.termID == 4337 || item.termID == 4338 || item.termID == 4342 ||
            item.termID == 4343 || item.termID == 4346 || item.termID == 4347 || item.termID == 4349 || item.termID == 4350 ||
            item.termID === 4430 || item.termID === 4431 || item.termID === 4439 || item.termID === 4440) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });

    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicconclussion = this.dinamicconclussion.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });

    return texto;
  }

  MontaDescricaoConclusaoEfeitoExpansivo(texto, contadorTexto) {

    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 4498 || +term === 4499 || +term === 4501 || +term === 4505 || +term === 4506 || +term === 4508 || +term === 4510
          || +term === 4515 || +term === 4519) {
          this.genericEfeitoExpansivo.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4498 || +term === 4499 || +term === 4501 || +term === 4505 || +term === 4506 || +term === 4508 || +term === 4510
          || +term === 4515 || +term === 4519) {
          this.genericEfeitoExpansivo.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoConclusaoSulcosCisternas(texto, contadorTexto) {

    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 4537 || +term === 4538 || +term === 4539 || +term === 4540 || +term === 4541 || +term === 4543 || +term === 4544
          || +term === 4545 || +term === 4546 || +term === 4547 || +term === 4548 || +term === 4550 || +term === 4551 || +term === 4552
          || +term === 4556) {
          this.genericSulcosSisternas.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4537 || +term === 4538 || +term === 4539 || +term === 4540 || +term === 4541 || +term === 4543 || +term === 4544
          || +term === 4545 || +term === 4546 || +term === 4547 || +term === 4548 || +term === 4550 || +term === 4551 || +term === 4552
          || +term === 4556) {
          this.genericSulcosSisternas.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoConclusaoIndicacao(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 4576) {
        this.genericIndicacao.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
			} else if(indicacao || achados) {
				if(indicacao === undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados === undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  public adicionaTextoNewEditor(){
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${maskSplit[1] + '<br/>' + this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      let desc = descricao.value
      if(descricao.blockID === this.metodoID){
        if(descricao.value){
          desc = descricao.value;
        } else {
          desc = 'Técnica: multislice' + '</br>Meio de contraste: sem contraste';
        }
      }
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${desc}</p>`,
        }
      });



    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[3]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao, index) => {
      if (conclusao.value && this.descricoes[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    if (this.indicacaoTexto !== '') {
      this.htmlContent += maskSplit[1];
      this.htmlContent += '<br/>' + this.indicacaoTexto + '<br/><br/>';
    }
    this.htmlContent += maskSplit[2];
    if (this.descricoes[0].value) {
      this.htmlContent += '<br/>' + this.descricoes[0].value + '<br/><br/>';
    } else {
      this.htmlContent += '</br>Técnica: multislice';
      this.htmlContent += '</br>Meio de contraste: sem contraste<br/><br/>';
    }
    this.htmlContent += maskSplit[3];

    for (i = 0; i < this.descricoes.length; i++) {
      var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value && this.descricoes[i].visivel === true)
        if (this.descricoes[i].blockID !== 140 && this.descricoes[i].blockID !== 293)
          this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
    }

    for (i = 0; i < this.conclusoes.length; i++) {
      var newValue = this.conclusoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.conclusoes[i].value = newValue2;
    }
    this.htmlContent += maskSplit[4];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value && this.conclusoes[i].visivel === true)
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
    }

    // Achados
    if (this.descricoes[11].blockID === 293 && this.descricoes[11].value !== '') {
      this.htmlContent += maskSplit[5];
      this.htmlContent += this.descricoes[11].value + '<br/>';
    } else {
      this.htmlContent += '';
    }

    if(this.rodapeTexto != '') {
      this.htmlContent += this.rodapeTexto;
    }
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    texto = 'Exame dentro dos padrões da normalidade.';
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMediocalReportLog.saveLogMedicalReport(obj);
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  handleSwitchFormReport(formReport: string) {
    if (formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

}
