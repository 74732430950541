import { PowerBIToken } from './../models/BO/PowerBIToken';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PowerBIService {

  constructor(private http: HttpClient) { }

  getAccessToken():Observable<PowerBIToken>{
    return this.http.post<PowerBIToken>(`${environment.nLaudoApi}Indicator/GetToken`,{})
  }

}
