import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../../../utils/dialog/confirm-dialog/confirm-dialog.component';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { TermResult } from '../../../../models/BO/TermResult';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DescricaoConclusao } from '../../../../models/Helper/DescricaoConclusao';
import { TermosGenericos } from '../../../../models/Helper/TermosGenericos';
import { Injuries, Term } from '../../../../models/BO/Term';
import { Validacoes } from '../../../../utils/validacoes';
import { TermosDinamicos } from 'src/app/models/Helper/TermosDinamicos';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { CommonService } from 'src/app/services/common.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

@Component({
  selector: 'app-mao',
  templateUrl: './mao.component.html',
  styleUrls: ['./mao.component.css'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
})
export class MaoComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  public Subtitulo: string;
  public subtituloBloco: string;
  public DerrameArticular: Boolean = false;
  public FasciaPalmar: Boolean = false;
  public MusculaturaRegional: Boolean = false;
  public PeleTecido: Boolean = false;
  public SuperficiesOsseas: Boolean = false;
  public TendoesExtensores: Boolean = false;
  public TendoesFlexores: Boolean = false;
  public medicalReportTypeID: number = 22;
  public PeleTecidoID: Number = 149;
  public DerrameArticularID: Number = 150;
  public SuperficiesOsseasID: Number = 151;
  public TendoesFlexoresID: Number = 152;
  public TendoesExtensoresID: Number = 153;
  public MusculaturaRegionalID: Number = 154;
  public FasciaPalmarID: Number = 155;
  public CistoID: Number = 322;
  public htmlContent: string;
  public mask: string;
  public term: any = new Term();
  public indicacaoTexto: any = '';
  public achadosAdTexto: any = '';
  public cabecalhoTexto = '';
  public cabecalho: Boolean = false;
  public indicacao: Boolean = false;
  public achadosTexto: Boolean = false;
  public rodape: Boolean = false;
  public rodapeTexto: any = '';
  public cisto: Boolean = false;
  public lateralidade: Boolean = true;
  public Lado: any = '';
  public termResultsAPI: Array<TermResult>;
  public termConclusionAPI: Array<TermResult>;
  public cenarioCorrente: any = 0;
  public dinamicLesions: TermosDinamicos[] = [];
  public dinamicLesion: any;
  public lesionRow = 1;
  public dinamicconclussion: TermosDinamicos[] = this.dinamicLesions;
  public generoLateralidade: string;
  public showForm: Boolean = true;
  public injuries = new Injuries();
  public medicaoCisto = '';
  public newEditor: boolean = false;
  public laudoCompleto: any = [];

  public blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: false
    },
    {
      titulo: 'Lateralidade',
      id: 'lateralidade',
      name: 'lateralidade',
      selected: true,
    },
    {
      titulo: 'Pele e tecido subcutâneo',
      id: this.PeleTecidoID,
      name: 'pele-tecido-subcutaneo',
      selected: false,
    },
    {
      titulo: 'Derrame articular',
      id: this.DerrameArticularID,
      name: 'derrame-articular',
      selected: false,
    },
    {
      titulo: 'Superfícies ósseas',
      id: this.SuperficiesOsseasID,
      name: 'superficies-osseas',
      selected: false,
    },
    {
      titulo: 'Tendões dos flexores',
      id: this.TendoesFlexoresID,
      name: 'tendoes-flexores',
      selected: false,
    },
    {
      titulo: 'Tendões extensores',
      id: this.TendoesExtensoresID,
      name: 'tendoes-extensores',
      selected: false,
    },
    {
      titulo: 'Musculatura regional',
      id: this.MusculaturaRegionalID,
      name: 'musculatura-regional',
      selected: false,
    },
    {
      titulo: 'Fáscia palmar',
      id: this.FasciaPalmarID,
      name: 'fascia-palmar',
      selected: false,
    },
    {
      titulo: 'Cisto',
      id: this.CistoID,
      name: 'cisto',
      selected: false,
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  listTermDefault=[
    {listTerm:[1731],blockID:149},
    {listTerm:[1734],blockID:150},
    {listTerm:[1739],blockID:151},
    {listTerm:[1743],blockID:152},
    {listTerm:[1746],blockID:153},
    {listTerm:[1749],blockID:154},
    {listTerm:[1750],blockID:155},
    {listTerm:[4656],blockID:322},
  ]

  public Descricoes: DescricaoConclusao[] = [
    { value: 'Pele e tecido subcutâneo preservados.', blockID: 149, textTermID: 0, visivel: true },
    { value: 'Articulações locais sem derrame significativo.', blockID: 150, textTermID: 0, visivel: true },
    { value: 'Superfícies ósseas sem alterações significativas ao método.', blockID: 151, textTermID: 0, visivel: true },
    { value: 'Tendões flexores dos dedos com ecogenicidade e espessura preservadas.', blockID: 152, textTermID: 0, visivel: true },
    { value: 'Tendões extensores dos dedos de aspecto anatômico.', blockID: 153, textTermID: 0, visivel: true },
    { value: 'Musculatura regional íntegra.', blockID: 154, textTermID: 0, visivel: true },
    { value: 'Fáscia palmar com espessura e ecogenicidades preservadas.', blockID: 155, textTermID: 0, visivel: true },
    { value: '', blockID: 322, textTermID: 0, visivel: true },
  ];

  public conclusoes: DescricaoConclusao[] = [
    { value: 'Exame dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 149, textTermID: 0, visivel: true },
    { value: '', blockID: 150, textTermID: 0, visivel: true },
    { value: '', blockID: 151, textTermID: 0, visivel: true },
    { value: '', blockID: 152, textTermID: 0, visivel: true },
    { value: '', blockID: 153, textTermID: 0, visivel: true },
    { value: '', blockID: 154, textTermID: 0, visivel: true },
    { value: '', blockID: 155, textTermID: 0, visivel: true },
    { value: '', blockID: 322, textTermID: 0, visivel: true },
  ];

  public genecicPeleTecido: TermosGenericos[] = [
    { value: '', selected: false, termID: 1733, term: '[Localizacao]' },
  ];

  public genericDerrameArticular: TermosGenericos[] = [
    { value: '', selected: false, termID: 1736, term: '[Articulacao]' },
    { value: '', selected: false, termID: 1737, term: '[Dedos]' },
    { value: '', selected: false, termID: 1738, term: '[Volume]' },
  ];

  public genericSuperficiesOsseas: TermosGenericos[] = [
    { value: '', selected: false, termID: 1741, term: '[Articulacao]' },
    { value: '', selected: false, termID: 1742, term: '[Dedos]' },
  ];

  public genericTendoesFlexores: TermosGenericos[] = [
    { value: '', selected: false, termID: 1745, term: '[Dedos]' },
  ];

  public genericTendoesExtensores: TermosGenericos[] = [
    { value: '', selected: false, termID: 1748, term: '[Dedos]' },
  ];

  public genericFasciaPalmar: TermosGenericos[] = [
    { value: '', selected: false, termID: 1754, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1755, term: '[Medida]' },
  ];

  public genericCisto: TermosGenericos[] = [
    { value: '', selected: false, termID: 4658, term: '[Localizacao Artro]' },
    { value: '', selected: false, termID: 4659, term: '[Articulacao Artro]' },
    { value: '', selected: false, termID: 4660, term: '[Dedo Artro]' },
    { value: '', selected: false, termID: 4661, term: '[Medida Artro]' },
    { value: '', selected: false, termID: 4664, term: '[Localizacao Peri]' },
    { value: '', selected: false, termID: 4665, term: '[Nivel Peri]' },
    { value: '', selected: false, termID: 4666, term: '[Tendoes Peri]' },
    { value: '', selected: false, termID: 4667, term: '[Dedo Peri]' },
    { value: '', selected: false, termID: 4668, term: '[Medida Peri]' },
  ];

  public switchFormReport = true;
  unidadeMedida: string;
  editLaudoMode: boolean = false;




  constructor(private readonly _selecteditememiter: SelectedItemEmiter,
    private readonly _laudoservice: LaudosService,
    private readonly _copyPaste: CopyPaste,
    private readonly _toastr: ToastrManager,
    private readonly _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _config: ConfigLaudoService,
    public _addText: AddText,
    private dataService:DataTermService,
    public readonly dialog: MatDialog) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("maoAnalise", this.Descricoes, this.conclusoes);
    this.resetaDialog();
    this.generoLateralidade = 'feminino';
    this.Subtitulo = 'lateralidade';
    this.subtituloBloco = 'lateralidade';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    const listBlocks = [
      this.PeleTecidoID,
      this.DerrameArticularID,
      this.SuperficiesOsseasID,
      this.TendoesFlexoresID,
      this.TendoesExtensoresID,
      this.MusculaturaRegionalID,
      this.CistoID,
      this.FasciaPalmarID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "maoMask").then((obj: any) => {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "mao");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-mao');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-mao');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  public resetaDialog() {
    this._common.invokeEvent.subscribe(event => {
      if (event) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.resetaLaudo();
          }
        });
      }
    });
  }

  public resetaLaudo() {
    this._pouchService.getAll("maoAnalise").then((obj) => {
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.Descricoes[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.Lado = "";
    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.achadosAdTexto = "";
    this.showForm = false;

    setTimeout(() => {
      this.showForm = true;
      this.AdicionaTexto(this.newEditor);
    }, 500);
  };

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selecteditememiter.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

      if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
        let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
        this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
      }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.lateralidade = (itemSelecionado.id === 'lateralidade');
    this.PeleTecido = (itemSelecionado.id === this.PeleTecidoID);
    this.DerrameArticular = (itemSelecionado.id === this.DerrameArticularID);
    this.SuperficiesOsseas = (itemSelecionado.id === this.SuperficiesOsseasID);
    this.TendoesFlexores = (itemSelecionado.id === this.TendoesFlexoresID);
    this.TendoesExtensores = (itemSelecionado.id === this.TendoesExtensoresID);
    this.MusculaturaRegional = (itemSelecionado.id === this.MusculaturaRegionalID);
    this.FasciaPalmar = (itemSelecionado.id === this.FasciaPalmarID);
    this.cisto = (itemSelecionado.id === this.CistoID);
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.achadosTexto = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  public limparTermos(): any {
    this.term.listTermID = new Array<number>(); this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;
    this.dinamicLesions = [];
    this.lesionRow = 1;

    this.genecicPeleTecido = [
      { value: '', selected: false, termID: 1733, term: '[Localizacao]' },
    ];

    this.genericDerrameArticular = [
      { value: '', selected: false, termID: 1736, term: '[Articulacao]' },
      { value: '', selected: false, termID: 1737, term: '[Dedos]' },
      { value: '', selected: false, termID: 1738, term: '[Volume]' },
    ];

    this.genericSuperficiesOsseas = [
      { value: '', selected: false, termID: 1741, term: '[Articulacao]' },
      { value: '', selected: false, termID: 1742, term: '[Dedos]' },
    ];

    this.genericTendoesFlexores = [
      { value: '', selected: false, termID: 1745, term: '[Dedos]' },
    ];

    this.genericTendoesExtensores = [
      { value: '', selected: false, termID: 1748, term: '[Dedos]' },
    ];

    this.genericFasciaPalmar = [
      { value: '', selected: false, termID: 1754, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1755, term: '[Medida]' },
    ];

    this.genericCisto = [
      { value: '', selected: false, termID: 4658, term: '[Localizacao Artro]' },
      { value: '', selected: false, termID: 4659, term: '[Articulacao Artro]' },
      { value: '', selected: false, termID: 4660, term: '[Dedo Artro]' },
      { value: '', selected: false, termID: 4661, term: '[Medida Artro]' },
      { value: '', selected: false, termID: 4664, term: '[Localizacao Peri]' },
      { value: '', selected: false, termID: 4665, term: '[Nivel Peri]' },
      { value: '', selected: false, termID: 4666, term: '[Tendoes Peri]' },
      { value: '', selected: false, termID: 4667, term: '[Dedo Peri]' },
      { value: '', selected: false, termID: 4668, term: '[Medida Peri]' },
    ];
  }

  public MontaDescricaoPeleTecido(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1733) {
        this.genecicPeleTecido.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  TextoDerrameArticularDinamica(texto, contadorTexto, lesaoID) {

    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 1736 || +item.termID === 1737 || +item.termID === 1738) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID === 1736 || +item.termID === 1737 || +item.termID === 1738) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    this.dinamicLesions = this.dinamicLesions.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }

  MontaDescricaoDerrameArticular(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });
    const RowDinamicConclussion = this.dinamicconclussion.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    if (rowDinamic !== undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoDerrameArticularDinamica(texto, contadorTexto, lesaoID);
    } else if (RowDinamicConclussion !== undefined && RowDinamicConclussion.length > 0) {
      const lesaoID = RowDinamicConclussion[0].lesionID;
      texto = this.MontaDerrameArticularConclusion(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoDerrameArticularNormal(texto);
    }
    return texto;
  }

  public TextoDerrameArticularNormal(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1736 || +term === 1737 || +term === 1738) {
        this.genericDerrameArticular.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDerrameArticularConclusion(texto, contadorTexto, lesaoID): any {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.dinamicconclussion.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 1736 || +item.termID === 1737 || +item.termID === 1738) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID === 1736 || +item.termID === 1737 || +item.termID === 1738) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    this.dinamicconclussion = this.dinamicconclussion.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }

  TextoSuperficiesOsseasDinamica(texto, contadorTexto, lesaoID) {

    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 1741 || +item.termID === 1742) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID === 1741 || +item.termID === 1742) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    this.dinamicLesions = this.dinamicLesions.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }

  MontaDescricaoSuperficiesOsseas(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });
    const RowDinamicConclussion = this.dinamicconclussion.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    if (rowDinamic !== undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoSuperficiesOsseasDinamica(texto, contadorTexto, lesaoID);
    } else if (RowDinamicConclussion !== undefined && RowDinamicConclussion.length > 0) {
      const lesaoID = RowDinamicConclussion[0].lesionID;
      texto = this.MontaSuperficiesOsseasConclusion(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoSuperficiesOsseasNormal(texto);
    }
    return texto;
  }

  public TextoSuperficiesOsseasNormal(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1741 || +term === 1742) {
        this.genericSuperficiesOsseas.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaSuperficiesOsseasConclusion(texto, contadorTexto, lesaoID): any {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.dinamicconclussion.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 1741 || +item.termID === 1742) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID === 1741 || +item.termID === 1742) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    this.dinamicconclussion = this.dinamicconclussion.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }

  TextoTendoesFlexoresDinamica(texto, contadorTexto, lesaoID) {

    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 1745) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID === 1745) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    this.dinamicLesions = this.dinamicLesions.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }

  MontaDescricaoTendoesFlexores(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    const RowDinamicConclussion = this.dinamicconclussion.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    if (rowDinamic !== undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoTendoesFlexoresDinamica(texto, contadorTexto, lesaoID);
    } else if (RowDinamicConclussion !== undefined && RowDinamicConclussion.length > 0) {
      const lesaoID = RowDinamicConclussion[0].lesionID;
      texto = this.MontaTendoesFlexoresConclusion(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoTendoesFlexoresNormal(texto);
    }
    return texto;
  }

  public TextoTendoesFlexoresNormal(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1745) {
        this.genericTendoesFlexores.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaTendoesFlexoresConclusion(texto, contadorTexto, lesaoID): any {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.dinamicconclussion.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 1745) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID === 1745) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    this.dinamicconclussion = this.dinamicconclussion.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }

  TextoTendoesExtensoresDinamica(texto, contadorTexto, lesaoID) {

    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 1748) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID === 1748) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    this.dinamicLesions = this.dinamicLesions.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }

  MontaDescricaoTendoesExtensores(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    const RowDinamicConclussion = this.dinamicconclussion.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    if (rowDinamic !== undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoTendoesExtensoresDinamica(texto, contadorTexto, lesaoID);
    } else if (RowDinamicConclussion !== undefined && RowDinamicConclussion.length > 0) {
      const lesaoID = RowDinamicConclussion[0].lesionID;
      texto = this.MontaTendoesExtensoresConclusion(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoTendoesExtensoresNormal(texto);
    }
    return texto;
  }

  public TextoTendoesExtensoresNormal(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1748) {
        this.genericTendoesExtensores.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaTendoesExtensoresConclusion(texto, contadorTexto, lesaoID): any {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.dinamicconclussion.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 1748) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID === 1748) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    this.dinamicconclussion = this.dinamicconclussion.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }

  TextoFasciaPalmarDinamica(texto, contadorTexto, lesaoID) {

    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '</br>';
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 1754 || +item.termID === 1755) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID === 1754 || +item.termID === 1755) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    this.dinamicLesions = this.dinamicLesions.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }

  MontaDescricaoFasciaPalmar(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    if (rowDinamic !== undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoFasciaPalmarDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoFasciaPalmarNormal(texto);
    }
    return texto;
  }

  public TextoFasciaPalmarNormal(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1754 || +term === 1755) {
        this.genericFasciaPalmar.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }


  public MontaConclussaoFasciaPalmar(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1754 || +term === 1755) {
        this.genericFasciaPalmar.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoCisto(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    if (rowDinamic !== undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoCistoDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoCistoNormal(texto);
    }
    return texto;
  }

  public TextoCistoNormal(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4658 || +term === 4659 || +term === 4660 || +term === 4661 || +term === 4664
        || +term === 4665 || +term === 4666 || +term === 4667 || +term === 4668) {
        this.genericFasciaPalmar.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  TextoCistoDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    textoDinamico = textoSplit[1] + '</br>';

    this.dinamicLesions.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 4658 || +item.termID === 4659 || +item.termID === 4660 || +item.termID === 4661
            || +item.termID === 4664 || +item.termID === 4665 || +item.termID === 4666 || +item.termID === 4667 || +item.termID === 4668) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          textoDinamico = textoSplit[1] + '</br>';

          if (+item.termID === 4658 || +item.termID === 4659 || +item.termID === 4660 || +item.termID === 4661
            || +item.termID === 4664 || +item.termID === 4665 || +item.termID === 4666 || +item.termID === 4667 || +item.termID === 4668) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // Validar a linha da lesão dinamica
    // this.dinamicLesions = this.dinamicLesions.filter((din) => {
    //   return lesaoID !== din.lesionID;
    // });

    return texto;
  }

  public MontaConclussaoPeleTecido(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1733) {
        this.genecicPeleTecido.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public adicionaSelecaoPeleTecido($event): any {
    this.genecicPeleTecido.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoDerrameArticular($event): any {
    this.genericDerrameArticular.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoSuperficiesOsseas($event): any {
    this.genericSuperficiesOsseas.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoTendoesFlexores($event): any {
    this.genericTendoesFlexores.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoTendoesExtensores($event): any {
    this.genericTendoesExtensores.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoFasciaPalmar($event): any {
    this.genericFasciaPalmar.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoCisto($event): any {
    this.genericCisto.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public consultarAPI(): any {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.PeleTecido) {
        this.term.blockID = this.PeleTecidoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.PeleTecidoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoPeleTecido(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.PeleTecidoID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.PeleTecidoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoPeleTecido(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const descricoes = this.conclusoes.find(x => x.blockID === this.PeleTecidoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.PeleTecidoID) {
                conclusaoNull.value = obj.value;
                descricoes.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              descricoes.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            descricoes.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: descricoes.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.DerrameArticular) {
        this.term.blockID = this.DerrameArticularID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.DerrameArticularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoDerrameArticular(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.DerrameArticularID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.DerrameArticularID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoDerrameArticular(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const descricoes = this.conclusoes.find(x => x.blockID === this.DerrameArticularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.DerrameArticularID) {
                conclusaoNull.value = obj.value;
                descricoes.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              descricoes.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            descricoes.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: descricoes.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.SuperficiesOsseas) {
        this.term.blockID = this.SuperficiesOsseasID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.SuperficiesOsseasID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoSuperficiesOsseas(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.SuperficiesOsseasID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.SuperficiesOsseasID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoSuperficiesOsseas(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const descricoes = this.conclusoes.find(x => x.blockID === this.SuperficiesOsseasID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.SuperficiesOsseasID) {
                conclusaoNull.value = obj.value;
                descricoes.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              descricoes.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            descricoes.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: descricoes.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.TendoesFlexores) {
        this.term.blockID = this.TendoesFlexoresID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.TendoesFlexoresID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoTendoesFlexores(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.TendoesFlexoresID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.TendoesFlexoresID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoTendoesFlexores(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const descricoes = this.conclusoes.find(x => x.blockID === this.TendoesFlexoresID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.TendoesFlexoresID) {
                conclusaoNull.value = obj.value;
                descricoes.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              descricoes.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            descricoes.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: descricoes.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.TendoesExtensores) {
        this.term.blockID = this.TendoesExtensoresID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.TendoesExtensoresID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoTendoesExtensores(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.TendoesExtensoresID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.TendoesExtensoresID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoTendoesExtensores(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const descricoes = this.conclusoes.find(x => x.blockID === this.TendoesExtensoresID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.TendoesExtensoresID) {
                conclusaoNull.value = obj.value;
                descricoes.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              descricoes.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            descricoes.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: descricoes.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.MusculaturaRegional) {
        this.term.blockID = this.MusculaturaRegionalID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.MusculaturaRegionalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });
              let index = this.Descricoes.findIndex(x => x.blockID === this.MusculaturaRegionalID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.MusculaturaRegionalID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const descricoes = this.conclusoes.find(x => x.blockID === this.MusculaturaRegionalID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.MusculaturaRegionalID) {
                conclusaoNull.value = obj.value;
                descricoes.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              descricoes.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            descricoes.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: descricoes.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.FasciaPalmar) {
        this.term.blockID = this.FasciaPalmarID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.FasciaPalmarID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoFasciaPalmar(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.FasciaPalmarID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.FasciaPalmarID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoFasciaPalmar(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const descricoes = this.conclusoes.find(x => x.blockID === this.FasciaPalmarID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.FasciaPalmarID) {
                conclusaoNull.value = obj.value;
                descricoes.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              descricoes.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            descricoes.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: descricoes.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.cisto) {
        this.term.blockID = this.CistoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.CistoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoCisto(resultado.text, contadorTexto);
                espaco = '';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.CistoID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.CistoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoCisto(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const descricoes = this.conclusoes.find(x => x.blockID === this.CistoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.CistoID) {
                conclusaoNull.value = obj.value;
                descricoes.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              descricoes.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            descricoes.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: descricoes.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
  }
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-lateralidade`,
        'name': ''
      },
      'data': {
        'text': `<div style="text-align: center;"><b>ULTRASSONOGRAFIA DA MÃO ${this.Lado.toUpperCase()}</b></div>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.Descricoes.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });
    this.Descricoes.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[3]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao) => {
      if (conclusao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.Lado.toUpperCase();
    this.htmlContent += maskSplit[1];
    this.htmlContent += this.indicacaoTexto;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContent += maskSplit[2];

    for (i = 0; i < this.Descricoes.length; i++) {
      var newValue = this.Descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.Descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.Descricoes.length; i++) {
      if (this.Descricoes[i].value) {
        this.htmlContent += '<br/>' + this.Descricoes[i].value + '<br/>';
      }
    }
    this.htmlContent += maskSplit[3];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }
    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
    conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
    lateralidade
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.Descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }

        if(lateralidade !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);
          let lado = `<div style="text-align: center;"><b>ULTRASSONOGRAFIA DA MÃO ${lateralidade.toUpperCase()}</b></div>`

          if(footer) {
						footer.innerHTML = lado.toUpperCase();
          }
        }
			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  public enviaTermos($event): any {
    if ($event === 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      //Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      //adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  public enviaLados($event) {
    this.Lado = $event;

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'lateralidade',
        lateralidade: this.Lado
      });
    }
  }

  public incluirLesoesDinamicas($event) {
    let lesoesValue = this._validacoes.formataDecimal($event.elemento.value);

    if ($event.elemento.id == 4661 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoCisto == '' || $event.posicao == 1) {
        this.medicaoCisto = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 4661,
          lesionID: 2635,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medida Artro]',
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoCisto = this.medicaoCisto + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoCisto;
            obj.selected = true;
            obj.termID = 4661;
            obj.lesionID = 2635;
            obj.cenario = $event.elemento.cenario,
              obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medida Artro]';
          }
        });
      }
    } else if ($event.elemento.id == 4668 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoCisto == '' || $event.posicao == 1) {
        this.medicaoCisto = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 4668,
          lesionID: 2636,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medida Peri]',
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoCisto = this.medicaoCisto + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoCisto;
            obj.selected = true;
            obj.termID = 4668;
            obj.lesionID = 2636;
            obj.cenario = $event.elemento.cenario,
              obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medida Peri]';
          }
        });
      }
    }

    if ($event.linha === 1 && ($event.posicao === 0 || $event.posicao === 1)) {
      if ($event.isPrimeiraLesao === true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaLesoes($event.elemento);
      }
    }

    switch ($event.elemento.id) {
      // Derrame Articular
      case '1736':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1736,
          lesionID: 1735,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Articulacao]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1737':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1737,
          lesionID: 1735,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Dedos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1738':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1738,
          lesionID: 1735,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Volume]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // Superfícies ósseas
      case '1741':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1741,
          lesionID: 1740,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Articulacao]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1742':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1742,
          lesionID: 1740,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Dedos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // Tendões flexores
      case '1745':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1745,
          lesionID: 1744,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Dedos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1745':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1745,
          lesionID: 1765,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Dedos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1745':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1745,
          lesionID: 1766,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Dedos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // Tendões extensores
      case '1748':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1748,
          lesionID: 1747,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Dedos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // Fascia Palmar
      case '1754':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1754,
          lesionID: 1753,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localizacao]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1755':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1755,
          lesionID: 1753,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // Fascia Palmar
      case '4658':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 4658,
          lesionID: 2635,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localizacao Artro]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4659':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 4659,
          lesionID: 2635,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Articulacao Artro]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4660':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 4660,
          lesionID: 2635,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Dedo Artro]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4661':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 4661,
          lesionID: 2635,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida Artro]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4664':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 4664,
          lesionID: 2636,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localizacao Peri]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4665':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 4665,
          lesionID: 2636,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Nivel Peri]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4666':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 4666,
          lesionID: 2636,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Tendoes Peri]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4667':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 4667,
          lesionID: 2636,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Dedo Peri]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4668':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 4668,
          lesionID: 2636,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida Peri]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }

    this.dinamicconclussion = this.dinamicLesions;
    this.lesionRow = $event.linha;
  }

  public RetornaConclusaoNull(Texto): any {
    let texto = '';
    texto = 'Exame dentro dos padrões da normalidade.';
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if (formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
