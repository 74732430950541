import { trigger, transition, query, stagger, animate, style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Term, Injuries } from 'src/app/models/BO/Term';
import { TermResult } from 'src/app/models/BO/TermResult';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { TermosDinamicos } from 'src/app/models/Helper/TermosDinamicos';
import { TermosGenericos } from 'src/app/models/Helper/TermosGenericos';
import { CommonService } from 'src/app/services/common.service';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';
import { LaudosService } from 'src/app/services/laudos.service.spec';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { SelectedItemEmiter } from 'src/app/utils/SelectedItemEmiter';
import { CopyPaste } from 'src/app/utils/textEditor/CopyPaste';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-penis-doppler',
  templateUrl: './penis-doppler.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./penis-doppler.component.css']
})
export class PenisDopplerComponent implements OnInit {

  listTermDefault=[
    {listTerm:[4193],blockID:276},
    {listTerm:[4195],blockID:277},
    {listTerm:[4196],blockID:278},
    {listTerm:[4197],blockID:279},
    {listTerm:[4201],blockID:280},
    {listTerm:[4219],blockID:281},
    {listTerm:[4182],blockID:275},
    {listTerm:[4982],blockID:355},
    {listTerm:[4985],blockID:356},
  ]

  descricao: DescricaoConclusao[] = [
    { value: 'Pele e tecido subcutâneo com padrão habitual.', blockID: 276, textTermID: 0, visivel: true },
    { value: 'Corpos cavernosos e esponjoso com forma, contornos e textura ecográfica normais.', blockID: 277, textTermID: 0, visivel: true },
    { value: 'Septo intercavernoso delgado, regular e homogêneo.', blockID: 278, textTermID: 0, visivel: true },
    { value: '', blockID: 279, textTermID: 0, visivel: true },
    { value: 'Túnica albugínea com espessura e ecogenicidade normais.', blockID: 280, textTermID: 0, visivel: true },
    { value: 'Uretra peniana sem dilatações císticas ou aparentes cálculos.', blockID: 281, textTermID: 0, visivel: true },
    { value: 'Ausência de linfonodomegalia pélvica ou inguinofemoral.', blockID: 275, textTermID: 0, visivel: true },
    { value: '', blockID: 355, textTermID: 0, visivel: true },
    { value: '', blockID: 356, textTermID: 0, visivel: true },
  ];

  conclusao: DescricaoConclusao[] = [
    { value: 'Exame dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 276, textTermID: 0, visivel: true },
    { value: '', blockID: 277, textTermID: 0, visivel: true },
    { value: '', blockID: 278, textTermID: 0, visivel: true },
    { value: '', blockID: 279, textTermID: 0, visivel: true },
    { value: '', blockID: 280, textTermID: 0, visivel: true },
    { value: '', blockID: 281, textTermID: 0, visivel: true },
    { value: '', blockID: 275, textTermID: 0, visivel: true },
    { value: '', blockID: 355, textTermID: 0, visivel: true },
    { value: '', blockID: 356, textTermID: 0, visivel: true },
  ];

  genericPriapismo: TermosGenericos[] = [
    { value: '', selected: false, termID: 4987, term: '[Lateralidade]' },
  ];

  genericLinfonodo: TermosGenericos[] = [
    { value: '', selected: false, termID: 4184, term: '[Lado]' },
    { value: '', selected: false, termID: 4185, term: '[Medidas]' },
    { value: '', selected: false, termID: 4190, term: '[Lado]' },
    { value: '', selected: false, termID: 4191, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 4192, term: '[Medidas]' }
  ];

  genericTunica: TermosGenericos[] = [
    { value: '', selected: false, termID: 4208, term: '[placa]' },
    { value: '', selected: false, termID: 4209, term: '[local corpo cavernoso]' },
    { value: '', selected: false, termID: 4210, term: '[corpo cavernoso]' },
    { value: '', selected: false, termID: 4211, term: '[medida]' },
    { value: '', selected: false, termID: 4212, term: '[local penis]' },
    { value: '', selected: false, termID: 4213, term: '[fratura corpo cavernoso]' },
    { value: '', selected: false, termID: 4214, term: '[fratura face do corpo cavernoso]' },
    { value: '', selected: false, termID: 4215, term: '[terco do penis]' },
    { value: '', selected: false, termID: 4216, term: '[medida]' },
    { value: '', selected: false, termID: 4217, term: '[medida colecao]' },
    { value: '', selected: false, termID: 4218, term: '[local da colecao]' }
  ];

  Subtitulo: string;
  subtituloBloco: string;
  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  dinamicLesions: TermosDinamicos[] = [];
  dinamicconclussion: TermosDinamicos[] = this.dinamicLesions;
  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  dinamicLesion: any;
  lesionRow = 1;

  cabecalhoTexto = '';
  medicaoNodulo = '';
  indicacaoTexto = '';
  achadosAdTexto = '';
  calcFrase = '';
  exibeTabelaVel = false;
  exibeTabelaCal = false;
  exibeTabelaIR = false;
  tabelaValor = new Array<string>();
  rodapeTexto = '';
  tabelaTexto = '';

  cabecalho = false;
  indicacao = false;
  achadosTexto = false;
  peleTecido = true;
  corposCavernosos = false;
  septo = false;
  calcificacoes = false;
  uretra = false;
  linfonodo = false;
  tunica = false;
  arteriasCavernosas = false;
  priapismo = false;
  rodape = false;

  medicalReportTypeID = 79;
  peleTecidoID = 276;
  corposCavernososID = 277;
  septoID = 278;
  calcificacoesID = 279;
  tunicaID = 280;
  linfonodoID = 275;
  arteriasCavernosasID = 355;
  priapismoID = 356;
  uretraID = 281;

  public switchFormReport = true;
  public showForm: Boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  unidadeMedida: string;
  editLaudoMode: boolean = false;

  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _validacoes: Validacoes,
    private _copyPaste: CopyPaste,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    public _addText: AddText,
    private _config: ConfigLaudoService,
    private dataService:DataTermService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("penisDopplerAnalise", this.descricao, this.conclusao);
    this.resetaDialog();
    this.Subtitulo = 'Pele e tecido';
    this.subtituloBloco = 'Pele e tecido';

    const listBlocks = [
      this.septoID,
      this.priapismoID,
      this.tunicaID,
      this.linfonodoID,
      this.calcificacoesID,
      this.uretraID,
      this.arteriasCavernosasID,
      this.peleTecidoID,
      this.corposCavernososID,
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "penisDopplerMask").then((obj: any) => {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "penisDoppler");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-penis-doppler');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-penis-doppler');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if (config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  public resetaDialog() {
    this._common.invokeEvent.subscribe(event => {
      if (event) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo() {
    this._pouchService.getAll("penisDopplerAnalise").then((obj) => {
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.descricao[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusao[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.showForm = false;
    this.achadosAdTexto = "";
    this.achadosTexto = false;
    this.exibeTabelaVel = false;
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: false
    },
    {
      id: this.peleTecidoID,
      name: 'peleTecido',
      titulo: 'Pele e tecido',
      selected: true
    },
    {
      id: this.corposCavernososID,
      name: 'corposCavernosos',
      titulo: 'Corpos Cavernosos',
      selected: false
    },
    {
      id: this.septoID,
      name: 'septo',
      titulo: 'Septo intercavernoso',
      selected: false
    },
    {
      id: this.calcificacoesID,
      name:'calcificacoes',
      titulo:'Calcificações arteriais',
      selected: false
    },
    {
      id: this.tunicaID,
      titulo: 'Túnica albugínea',
      name: 'tunica',
      selected: false
    },
    {
      id: this.uretraID,
      name: 'uretraPeniana',
      titulo: 'Uretra peniana',
      selected: false
    },
    {
      titulo: 'Linfonodos',
      id: this.linfonodoID,
      name: 'linfonodos',
      selected: false
    },
    {
      id: this.arteriasCavernosasID,
      name: 'arteriasCavernosas',
      titulo: 'Artérias cavernosas',
      selected: false
    },
    {
      id: this.priapismoID,
      name: 'priapismo',
      titulo: 'Priapismo',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados_adicionais',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if (itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }


    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.peleTecido = (itemSelecionado.id === this.peleTecidoID);
    this.priapismo = (itemSelecionado.id === this.priapismoID);
    this.corposCavernosos = (itemSelecionado.id === this.corposCavernososID);
    this.septo = (itemSelecionado.id === this.septoID);
    this.tunica = (itemSelecionado.id === this.tunicaID);
    this.linfonodo = (itemSelecionado.id === this.linfonodoID);
    this.calcificacoes = (itemSelecionado.id === this.calcificacoesID);
    this.arteriasCavernosas = (itemSelecionado.id === this.arteriasCavernosasID);
    this.uretra = (itemSelecionado.id === this.uretraID);
    this.achadosTexto = (itemSelecionado.id === 'achados_adicionais');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;
    this.dinamicLesions = [];
    this.lesionRow = 1;
    this.medicaoNodulo = '';

    this.genericLinfonodo = [
      { value: '', selected: false, termID: 4184, term: '[Lado]' },
      { value: '', selected: false, termID: 4185, term: '[Medidas]' },
      { value: '', selected: false, termID: 4190, term: '[Lado]' },
      { value: '', selected: false, termID: 4191, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 4192, term: '[Medidas]' }
    ];

    this.genericTunica = [
      { value: '', selected: false, termID: 4208, term: '[placa]' },
      { value: '', selected: false, termID: 4209, term: '[local corpo cavernoso]' },
      { value: '', selected: false, termID: 4210, term: '[corpo cavernoso]' },
      { value: '', selected: false, termID: 4211, term: '[medida]' },
      { value: '', selected: false, termID: 4212, term: '[local penis]' },
      { value: '', selected: false, termID: 4213, term: '[fratura corpo cavernoso]' },
      { value: '', selected: false, termID: 4214, term: '[fratura face do corpo cavernoso]' },
      { value: '', selected: false, termID: 4215, term: '[terco do penis]' },
      { value: '', selected: false, termID: 4216, term: '[medida]' },
      { value: '', selected: false, termID: 4217, term: '[medida colecao]' },
      { value: '', selected: false, termID: 4218, term: '[local da colecao]' }
    ];

  }

  incluirLesoesDinamicas($event) {
    const lesoesValue = $event.elemento.value;
    if ($event.linha === 1 && ($event.posicao === 0 || $event.posicao === 1)) {
      if ($event.isPrimeiraLesao === true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaTermos($event.elemento);
      }
    }

    switch ($event.elemento.id) {
      // Linfonodomegalias
      case '4184':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4184,
          lesionID: 4183,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Lado]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4185':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4185,
          lesionID: 4183,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medidas]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4190':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4190,
          lesionID: 4187,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Lado]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4191':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4191,
          lesionID: 4187,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4192':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4192,
          lesionID: 4187,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medidas]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }
    this.dinamicconclussion = this.dinamicLesions;
    this.lesionRow = $event.linha;

  }

  enviaLesoes($event) {
    if (this.cenarioCorrente === 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario === 0 || $event.cenario === this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  enviaTermos($event) {
    if ($event === 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    // this.achadosAdTexto = texto;
    else
      this.achadosAdTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados_adicionais',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  atualizaTabela() {
    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.AdicionaTexto(true);
    }
  }

  atribuiValorTabela($element) {
    const value = this._validacoes.formataDecimal($element.value);
    this.exibeTabelaVel = true;
    debugger;
    console.log($element.id);

    if ($element.id === 'VelSisDir') {
      this.tabelaValor[0] = value;
    } else if ($element.id === 'VelSisDir') {
      this.tabelaValor[0] = value;
    } else if ($element.id === 'VelSisEsq') {
      this.tabelaValor[1] = value;
    } else if ($element.id === 'VelSisEsq') {
      this.tabelaValor[1] = value;
    } else if ($element.id === 'VelDisDir') {
      this.tabelaValor[2] = value;
    } else if ($element.id === 'VelDisDir') {
      this.tabelaValor[2] = value;
    } else if ($element.id === 'VelDisEsq') {
      this.tabelaValor[3] = value;
    } else if ($element.id === 'VelDisEsq') {
      this.tabelaValor[3] = value;
    } else if ($element.id === 'AceleracaoDir') {
      this.tabelaValor[4] = value;
    } else if ($element.id === 'AceleracaoDir') {
      this.tabelaValor[4] = value;
    } else if ($element.id === 'AceleracaoEsq') {
      this.tabelaValor[5] = value;
    } else if ($element.id === 'AceleracaoEsq') {
      this.tabelaValor[5] = value;
    } else if ($element.id === 'NaoCitar' || $element.id === 'Normal') {
      this.exibeTabelaVel = false;
      this.tabelaValor = [];

    }
    //Calibre
    if ($element.id === 'CalibreDir') {
      this.tabelaValor[6] = value;
    } else if ($element.id === 'CalibreEsq') {
      this.tabelaValor[7] = value;
    } else if($element.id === 'CitarCalibres'){
      this.exibeTabelaCal = value;
      if(!value){
        this.tabelaValor[6] = '';
        this.tabelaValor[7] = '';
      }
    } else if($element.id === 'NaoCitar' || $element.id === 'Normal'){
      this.exibeTabelaVel = false;
      this.exibeTabelaCal = false;
      this.tabelaValor = [];
    }

    //Citar IR
      if ($element.id === 'IRDir') {
      this.tabelaValor[8] = value;
    } else if ($element.id === 'IREsq') {
      this.tabelaValor[9] = value;
    } else if($element.id === 'CitarIR'){
      this.exibeTabelaIR = value;
      if(!value){
        this.tabelaValor[8] = '';
        this.tabelaValor[9] = '';
      }
    }else if ($element.id === 'NaoCitar'  || $element.id === 'Normal') {
      this.exibeTabelaVel = false;
      this.exibeTabelaCal = false;
      this.exibeTabelaIR = false;
      this.tabelaValor = [];
    }

    let tabeVel = this.TabelaVelocidade();
    if (tabeVel !== '') {
      if(!this.newEditor) {
        this.AdicionaTexto(this.newEditor);
      } else {
        this.atualizaLinha({
          blockId: 'tabelaVelocidade',
          tabelaVelocidade: tabeVel,
        });
      }
    }

    let tabeCal = this.TabelaCalibre();
    if (tabeCal !== '') {
      if(!this.newEditor) {
        this.AdicionaTexto(this.newEditor);
      } else {
        this.atualizaLinha({
          blockId: 'tabelaCalibre',
          tabelaCalibre: tabeCal,
        });
      }
    }

    let tabeIR = this.TabelaIR();
    if (tabeIR !== '') {
      if(!this.newEditor) {
        this.AdicionaTexto(this.newEditor);
      } else {
        this.atualizaLinha({
          blockId: 'tabelaIR',
          tabelaIR: tabeIR,
        });
      }
    }
  }

  adicionaSelecaoTunica($event) {
    this.genericTunica.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        if (+$event.elemento.id === 4211 ||
          +$event.elemento.id === 4216 ||
          +$event.elemento.id === 4217)
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        else
          obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoLinfonodo($event) {
    this.genericLinfonodo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  consultarAPI() {
  if(this.editLaudoMode){
    let blocoSelecionado = this.blocos.filter(bloco=>{
      return bloco.selected==true
    })
    this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
    return;
  } else if(!this.editLaudoMode){
      if (this.septo) {
        this.term.blockID = this.septoID;
        let desc;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.septoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricao.findIndex(x => x.blockID === this.septoID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.septoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.septoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.septoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.septoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.priapismo) {
        this.term.blockID = this.priapismoID;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          debugger
          this.descricao.forEach(obj => {
            if (obj.blockID === this.priapismoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.montaDescriçãoPriapismo(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricao.findIndex(x => x.blockID === this.priapismoID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.priapismoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.montaDescriçãoPriapismo(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.priapismoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.priapismoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.priapismoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.linfonodo) {
        this.term.blockID = this.linfonodoID;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.linfonodoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoLinfonodo(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricao.findIndex(x => x.blockID === this.linfonodoID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.linfonodoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoLinfonodo(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.linfonodoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.linfonodoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.linfonodoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.tunica) {

        this.term.blockID = this.tunicaID;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.tunicaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontadescricaoTunica(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricao.findIndex(x => x.blockID === this.tunicaID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.tunicaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.tunicaID;
              obj.value = obj.value + espaco + this.MontaConclusaoTunica(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.tunicaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tunicaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.tunicaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.calcificacoes) {
        this.term.blockID = this.calcificacoesID;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.calcificacoesID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricao.findIndex(x => x.blockID === this.calcificacoesID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.calcificacoesID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.calcificacoesID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.calcificacoesID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.calcificacoesID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.uretra) {
        this.term.blockID = this.uretraID;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.uretraID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricao.findIndex(x => x.blockID === this.uretraID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.uretraID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.uretraID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.uretraID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.uretraID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.arteriasCavernosas){
        this.term.blockID = this.arteriasCavernosasID;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.arteriasCavernosasID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricao.findIndex(x => x.blockID === this.arteriasCavernosasID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.arteriasCavernosasID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.arteriasCavernosasID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.arteriasCavernosasID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.arteriasCavernosasID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.peleTecido){

        this.term.blockID = this.peleTecidoID;
        let desc;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.peleTecidoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricao.findIndex(x => x.blockID === this.peleTecidoID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.peleTecidoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.peleTecidoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.peleTecidoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.peleTecidoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.corposCavernosos) {
          this.term.blockID = this.corposCavernososID;
          let desc;

          this._pouchService.getLaudos(this.term).then((termResults) => {
            this.descricao.forEach(obj => {
              if (obj.blockID === this.septoID && termResults[0] !== undefined) {
                this.termResultsAPI = termResults;
                obj.textTermID = this.termResultsAPI[0].id;
                obj.value = '';
                this.termResultsAPI.forEach(resultado => {
                  obj.value = resultado.text;
                });

                let index = this.descricao.findIndex(x => x.blockID === this.corposCavernososID);
                if (index > -1) {
                  this.descricao[index].value = obj.value;
                  this.descricao[index].textTermID = obj.textTermID;
                  desc = obj.value;
                }
              }
            });

            let obj = {
              value: '',
              blockID: this.corposCavernososID,
              textTermID: 0
            };
            let espaco = '';
            const indexConclusion = termResults.filter(x => x.conclusion !== null);
            this.termConclusionAPI = termResults;
            this.termConclusionAPI.forEach(resultado => {
              if (resultado.conclusion) {
                obj.value = resultado.conclusion;
              } else {
                if (indexConclusion.length <= 0) {
                  obj.blockID = 0;
                  obj.value = this.RetornaConclusaoNull(resultado.conclusion);
                } else {
                  if (!obj.value) {
                    obj.blockID = 0;
                    obj.value = '';
                  }
                }
              }
            });

            const conclusaoAll = this.conclusao.filter(x => x.value !== '');
            const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
            const conclusao = this.conclusao.find(x => x.blockID === this.corposCavernososID);

            if (obj.blockID === 0) {
              if (conclusaoAll.length <= 1) {
                if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.corposCavernososID) {
                  conclusaoNull.value = obj.value;
                  conclusao.value = '';
                } else {
                  conclusaoNull.value = '';
                }
              } else {
                conclusao.value = '';
              }
            } else if (obj.value) {
              conclusaoNull.value = '';
              conclusao.value = obj.value;
            }

            if(!this.newEditor) {
              this.AdicionaTexto(this.newEditor);
            } else {
              this.atualizaLinha({
                blockId: this.corposCavernososID,
                descricao: desc,
                conclusao: conclusao.value,
                conclusaoNull: conclusaoNull.value
              });
            }
          });
      }
    }
  }

  montaDescriçãoPriapismo(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 4987) {
        this.genericPriapismo.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  adicionaSelecaoPriapismo($event) {
    this.genericPriapismo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  MontadescricaoTunica(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4208 || +term === 4209 || +term === 4210 || +term === 4211 ||
        +term === 4212 || +term === 4213 || +term === 4214 || +term === 4215 ||
        +term === 4216 || +term === 4217 || +term === 4218) {
        this.genericTunica.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoTunica(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4208 || +term === 4209 || +term === 4210 || +term === 4211 ||
        +term === 4212 || +term === 4213 || +term === 4214 || +term === 4215 ||
        +term === 4216 || +term === 4217 || +term === 4218) {
        this.genericTunica.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoLinfonodo(texto, contadorTexto) {
    let xpto = this.dinamicLesions;
    const rowDinamic = xpto.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoLinfonodoDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoLinfonodoDescNormal(texto, contadorTexto);
    }
    return texto;
  }

  MontaConclusaoLinfonodo(texto, contadorTexto) {
    const rowDinamic = this.dinamicconclussion.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoConclusaoLinfonodoDinamic(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoLinfonodoDescNormal(texto, contadorTexto);
    }
    return texto;

  }

  TextoLinfonodoDescNormal(texto, contadorTexto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 4184 || +term === 4185 || +term === 4190 || +term === 4191 || +term === 4192) {
        this.genericLinfonodo.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  TextoLinfonodoDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1] + '</br>';
    }

    // Lista term
    this.term.listTermID.forEach(term => {
      if (+term === 4184 || +term === 4185 || +term === 4190 || +term === 4191 || +term === 4192) {
        this.genericLinfonodo.forEach(generic => {
          if (+generic.termID === +term) {
            textoRetorno = textoRetorno.replace(generic.term, generic.value);
          }
        });
      }
    });

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID == 4184 || item.termID == 4185 || item.termID == 4190 || item.termID == 4191 || item.termID == 4192) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1] + '</br>';
          }

          if (item.termID == 4184 || item.termID == 4185 || item.termID == 4190 || item.termID == 4191 || item.termID == 4192) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });

    return texto;
  }

  TextoConclusaoLinfonodoDinamic(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = "";//textoSplit[0];
      textoDinamico = textoSplit[1] + '</br>';
    }

    this.dinamicconclussion.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID == 4184 || item.termID == 4185 || item.termID == 4190 || item.termID == 4191 || item.termID == 4192) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1] + '</br>';
          }

          if (item.termID == 4184 || item.termID == 4185 || item.termID == 4190 || item.termID == 4191 || item.termID == 4192) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicconclussion = this.dinamicconclussion.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });

    return texto;
  }

  TabelaIR(): any {
    let strTable = '<style>' +
      'table {' +
      'width: 80%;' +
      '}' +
      'table, th, td {' +
      'border: 1px solid #c2c0c2;' +
      'border-collapse: collapse;' +
      'th, td {' +
      '  padding: 5px;' +
      '  text-align: left;' +
      '}' +
      '</style>' +
      '<table>';
    if (this.tabelaValor[8] && this.tabelaValor[8] != '') {
      strTable += '  <tr>';
      strTable += '    <th><b></b></th>';
      strTable += '    <th><b>Direita</b></th>';
      strTable += '    <th><b>Esquerda</b></th>';
      strTable += '  </tr>';

    }
    if ((this.tabelaValor[8] && this.tabelaValor[8] != '') || (this.tabelaValor[9]  && this.tabelaValor[9] != '')) {

      strTable += '  <tr>';
      strTable += '    <td><b>IR</b></td>';
      strTable += '    <td>' + this.tabelaValor[8] + '</td>';
      strTable += '    <td>' + this.tabelaValor[9] + '</td>';
      strTable += '  </tr>';
    }
    strTable += '</tr>' +
      '</table>';

    return strTable;
  }

  TabelaCalibre(): any {
    let strTable = '<style>' +
      'table {' +
      'width: 80%;' +
      '}' +
      'table, th, td {' +
      'border: 1px solid #c2c0c2;' +
      'border-collapse: collapse;' +
      'th, td {' +
      '  padding: 5px;' +
      '  text-align: left;' +
      '}' +
      '</style>' +
      '<table>';
    if (this.tabelaValor[6] && this.tabelaValor[6] != '') {
      strTable += '  <tr>';
      strTable += '    <th><b></b></th>';
      strTable += '    <th><b>Direita</b></th>';
      strTable += '    <th><b>Esquerda</b></th>';
      strTable += '  </tr>';

    }
    if ((this.tabelaValor[6] && this.tabelaValor[6] != '') || (this.tabelaValor[7]  && this.tabelaValor[7] != '')) {

      strTable += '  <tr>';
      strTable += '    <td><b>Calibre</b></td>';
      strTable += '    <td>' + this.tabelaValor[6] + ' ' + this.unidadeMedida + '/s</td>';
      strTable += '    <td>' + this.tabelaValor[7] + ' ' + this.unidadeMedida + '/s</td>';
      strTable += '  </tr>';
    }

    strTable += '</tr>' +
      '</table>';

    return strTable;
  }

  TabelaVelocidade(): any {
    let strTable = '<style>' +
      'table {' +
      'width: 80%;' +
      '}' +
      'table, th, td {' +
      'border: 1px solid #c2c0c2;' +
      'border-collapse: collapse;' +
      'th, td {' +
      '  padding: 5px;' +
      '  text-align: left;' +
      '}' +
      '</style>' +
      '<table>';
    if (this.tabelaValor[0]) {
      strTable += '  <tr>';
      strTable += '    <th><b></b></th>';
      strTable += '    <th><b>Direita</b></th>';
      strTable += '    <th><b>Esquerda</b></th>';
      strTable += '  </tr>';

    }
    if (this.tabelaValor[0] || this.tabelaValor[1]) {
      strTable += '  <tr>';
      strTable += '    <td><b>Vel. Sistólica</b></td>';
      strTable += '    <td>' + this.tabelaValor[0] + ' ' + this.unidadeMedida + '/s</td>';
      strTable += '    <td>' + this.tabelaValor[1] + ' ' + this.unidadeMedida + '/s</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValor[2] || this.tabelaValor[3]) {
      strTable += '  <tr>';
      strTable += '    <td><b>Vel. Diastólica</b></td>';
      strTable += '    <td>' + this.tabelaValor[2] + ' ' + this.unidadeMedida + '/s</td>';
      strTable += '    <td>' + this.tabelaValor[3] + ' ' + this.unidadeMedida + '/s</td>';
      strTable += '  </tr>';
    }
    if(this.tabelaValor[4] || this.tabelaValor[5]) {
      strTable += '  <tr>';
      strTable += '    <td><b>Aceleração</b></td>';
      strTable += '    <td>' + this.tabelaValor[4] + ' ' + this.unidadeMedida + '/s</td>';
      strTable += '    <td>' + this.tabelaValor[5] + ' ' + this.unidadeMedida + '/s</td>';
      strTable += '  </tr>';
    }

    strTable += '</tr>' +
      '</table>';

    return strTable;
  }


  AdicionaTexto(isNewEditor: boolean) {
    if (isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'data': {
          'text': '</br></br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.descricao.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });

    this.descricao.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    // Tabela
    if (this.exibeTabelaVel) {
      this.htmlContent += '</br>' + this.TabelaVelocidade() + '</br>';

      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-tabela`,
          'name': ''
        },
        'data': {
          'text': `<p>${this.tabelaTexto}</p>`,
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[3]}</p>`,
      }
    });

    this.conclusao.forEach((conclusao) => {
      if (conclusao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados_adicionais`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += maskSplit[1];
    this.htmlContent += this.indicacaoTexto;
    //Lógica Rules
    if (JSON.parse(localStorage.getItem('tecnicaRule')) && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContent += maskSplit[2];

    for (i = 0; i < this.descricao.length; i++) {
      var newValue = this.descricao[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricao[i].value = newValue2;
    }
    for (i = 0; i < this.descricao.length; i++) {
      if (this.descricao[i].value) {
        this.htmlContent += '<br/>' + this.descricao[i].value + '<br/>';
      }
    }

    debugger;
    // Tabela
    if (this.exibeTabelaVel) {
      if (this.exibeTabelaCal) {
        //this.htmlContent += maskSplit[4];
        this.htmlContent += '</br>' + this.TabelaCalibre();
      }

      //this.htmlContent += maskSplit[3];
      this.htmlContent += '</br>' + this.TabelaVelocidade() + '</br>';

      if (this.exibeTabelaIR) {
        //this.htmlContent += maskSplit[4];
        this.htmlContent += '</br>' + this.TabelaIR();
      }
    }

    this.htmlContent += maskSplit[3];
    for (i = 0; i < this.conclusao.length; i++) {
      if (this.conclusao[i].value) {
        this.htmlContent += '<br/>' + this.conclusao[i].value + '<br/>';
      }
    }
    if (this.achadosTexto === true) {
      this.htmlContent += this.achadosAdTexto;
    }

    if (this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
    blockId,
    descricao,
    conclusao,
    conclusaoNull,
    indicacao,
    achados,
    cabecalho,
    rodape,
    tabelaVelocidade,
    tabelaCalibre,
    tabelaIR,
  }: IAtualizaLinha): void {
    if (blockId !== undefined) {
      if (indicacao === undefined && achados === undefined) {
        if (descricao === undefined) {
          this.descricao.forEach(desc => {
            if (desc.blockID === blockId) {
              descricao = desc.value;
            }
          });
        }

        const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

        if (conclusao !== '' || conclusaoNull !== '') {
          if (conclusion) {
            conclusion.innerHTML = '';

            this.conclusao.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                  ? conclusaoNull !== ''
                    ? conclusaoNull
                    : ''
                  : conclusao;
              }
            });
          }
        } else {
          if (conclusion) {
            conclusion.innerHTML = '';

            this.conclusao.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                  ? conclusaoNull !== ''
                    ? conclusaoNull
                    : ''
                  : conclusao;
              }
            });
          }
        }

        if (description) {
          description.innerHTML = descricao;
        } else if (!description) {
          this.AdicionaTexto(true);
        } else if (!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if (cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId} p`);

          if (header) {
            header.innerHTML = cabecalho;
          }
        }

        if (rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId} p`);

          if (footer) {
            footer.innerHTML = rodape;
          }
        }

        if(tabelaVelocidade !== undefined) {
          const footer = document.querySelector(`#e-${blockId} p`);

          if(footer) {
						footer.innerHTML = `<b>Velocidade:</b></br> ${tabelaVelocidade}`;
          }
        }

        if(tabelaCalibre !== undefined) {
          const footer = document.querySelector(`#e-${blockId} p`);

          if(footer) {
            footer.innerHTML = `<b>Calibre:</b></br> ${tabelaCalibre}`;
          }
        }

        if(tabelaIR !== undefined) {
          const footer = document.querySelector(`#e-${blockId} p`);

          if(footer) {
            footer.innerHTML = `<b>IR:</b></br> ${tabelaIR}`;
          }
        }

      } else if (indicacao !== undefined || achados !== undefined) {
        if (indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if (recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
        } else if (achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if (findings) {
            findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
        }
      }
      this._addText.lineHeight();
    }
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    texto = 'Exame dentro dos padrões da normalidade.';
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if (formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
