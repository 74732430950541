import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Injuries, Term } from 'src/app/models/BO/Term';
import { TermResult } from 'src/app/models/BO/TermResult';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { TermosDinamicos } from 'src/app/models/Helper/TermosDinamicos';
import { LaudosService } from 'src/app/services/laudos.service.spec';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { SelectedItemEmiter } from 'src/app/utils/SelectedItemEmiter';
import { CopyPaste } from 'src/app/utils/textEditor/CopyPaste';
import { Validacoes } from 'src/app/utils/validacoes';
import { TermosGenericos } from 'src/app/models/Helper/TermosGenericos';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';


export interface ListaBirads {
  blockID: number;
  value: number;
}

@Component({
  selector: 'app-mamografia',
  templateUrl: './mamografia.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./mamografia.component.css']
})
export class MamografiaComponent implements OnInit {
  listTermDefault=[
    {listTerm:[4039],blockID:255},
    {listTerm:[4095],blockID:256},
    {listTerm:[4046],blockID:257},
    {listTerm:[4066],blockID:258},
    {listTerm:[4089],blockID:259},
    {listTerm:[4050],blockID:260},
    {listTerm:[4076],blockID:261},
    {listTerm:[4062],blockID:262},
    {listTerm:[4099],blockID:263},
    {listTerm:[4224],blockID:285},
    {listTerm:[4220],blockID:284},
  ]

  descricao: DescricaoConclusao[] = [
    { value: 'Realizado em aparelho de alta resolução nas incidências crânio-caudal e mediolateral oblíqua.', blockID: 284, textTermID: 0, visivel: true },
    { value: 'Rastreamento.', blockID: 263, textTermID: 0, visivel: true },
    { value: '<b>Pele tangenciada e mamilos:</b> com espessura e aspecto usuais, respectivamente.', blockID: 255, textTermID: 0, visivel: true },
    {
      value: '<b>Padrão do parênquima:</b><br/>A- Mamas predominantemente adiposas.<br/> B- Mamas com densidades fibroglandulares esparsas.<br/>'
        + 'C- Mamas heterogeneamente densas, o que pode ocultar pequenos nódulos.<br/>D- Mamas extremamente densas, o que diminui a sensibilidade da mamografia.', blockID: 256, textTermID: 0, visivel: true
    },
    { value: '<b>Assimetria focal/global do parênquima:</b> ausente.', blockID: 259, textTermID: 0, visivel: true },
    { value: '<b>Vascularização do parênquima:</b> com calibre e distribuição normal.', blockID: 257, textTermID: 0, visivel: true },
    { value: '<b>Áreas de distorção arquitetural:</b> ausentes.', blockID: 258, textTermID: 0, visivel: true },
    { value: '<b>Nodulações:</b> ausência de aparente nódulo nas incidências obtidas.', blockID: 260, textTermID: 0, visivel: true },
    { value: '<b>Calcificações/Microcalcificações:</b> ausência de agrupamentos de calcificações suspeitas.', blockID: 261, textTermID: 0, visivel: true },
    { value: '<b>Linfonodos axilares:</b> ausência de linfonodomegalias.', blockID: 262, textTermID: 0, visivel: true },
    { value: '', blockID: 285, textTermID: 0, visivel: true }
  ];

  conclusao: DescricaoConclusao[] = [
    { value: 'Ausência de achados mamográficos suspeitos.<br/>Pelo sistema de padronização de laudos BI-RADS®: Categoria 1.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 255, textTermID: 0, visivel: true },
    { value: '', blockID: 256, textTermID: 0, visivel: true },
    { value: '', blockID: 257, textTermID: 0, visivel: true },
    { value: '', blockID: 258, textTermID: 0, visivel: true },
    { value: '', blockID: 259, textTermID: 0, visivel: true },
    { value: '', blockID: 260, textTermID: 0, visivel: true },
    { value: '', blockID: 261, textTermID: 0, visivel: true },
    { value: '', blockID: 262, textTermID: 0, visivel: true },
    { value: '', blockID: 263, textTermID: 0, visivel: true },
    { value: '', blockID: 284, textTermID: 0, visivel: true },
  ];

  genericTermsPeleMamilos: TermosGenericos[] = [
    { value: '', selected: false, termID: 4041, term: '[Mama]' },
    { value: '', selected: false, termID: 4043, term: '[Mama]' },
    { value: '', selected: false, termID: 4045, term: '[Mama]' }
  ];

  genericTermsVasParenq: TermosGenericos[] = [
    { value: '', selected: false, termID: 4048, term: '[Grau]' },
    { value: '', selected: false, termID: 4049, term: '[Mama]' }
  ];

  genericTermsDistorArqui: TermosGenericos[] = [
    { value: '', selected: false, termID: 4069, term: '[Mama]' },
    { value: '', selected: false, termID: 4070, term: '[Localizacao]' },
    { value: '', selected: false, termID: 4072, term: '[Mama]' },
    { value: '', selected: false, termID: 4073, term: '[Localizacao]' },
    { value: '', selected: false, termID: 4075, term: '[Mama]' }
  ];

  genericTermsAssimetria: TermosGenericos[] = [
    { value: '', selected: false, termID: 4091, term: '[Tipo]' },
    { value: '', selected: false, termID: 4092, term: '[Mama]' },
    { value: '', selected: false, termID: 4093, term: '[Localizacao]' }
  ];

  genericTermsNodulos: TermosGenericos[] = [
    { value: '', selected: false, termID: 4052, term: '[Densidade]' },
    { value: '', selected: false, termID: 4053, term: '[Forma]' },
    { value: '', selected: false, termID: 4054, term: '[Margens]' },
    { value: '', selected: false, termID: 4055, term: '[Medida]' },
    { value: '', selected: false, termID: 4056, term: '[Localizacao]' },
    { value: '', selected: false, termID: 4057, term: '[Lado]' },
    { value: '', selected: false, termID: 4059, term: '[Medida]' },
    { value: '', selected: false, termID: 4060, term: '[Localizacao]' },
    { value: '', selected: false, termID: 4061, term: '[Lado]' }
  ];

  genericTermsCalcific: TermosGenericos[] = [
    { value: '', selected: false, termID: 4078, term: '[TiposDeCalcificacao]' },
    { value: '', selected: false, termID: 4079, term: '[Lado]' },
    { value: '', selected: false, termID: 4081, term: '[Lado]' },
    { value: '', selected: false, termID: 4083, term: '[Lado]' },
    { value: '', selected: false, termID: 4085, term: '[Morfologia]' },
    { value: '', selected: false, termID: 4086, term: '[Distribuicao]' },
    { value: '', selected: false, termID: 4087, term: '[Localizacao]' },
    { value: '', selected: false, termID: 4088, term: '[Lado]' }
  ];

  genericTermsLinfonodomegalias: TermosGenericos[] = [
    { value: '', selected: false, termID: 4064, term: '[Lado]' },
    { value: '', selected: false, termID: 4065, term: '[Medida]' }
  ];

  genericTermsRecomendacao: TermosGenericos[] = [
    { value: '', selected: false, termID: 4233, term: '[mama]' },
    { value: '', selected: false, termID: 4234, term: '[tempo]' }
  ];

  genericTermsTecnica: TermosGenericos[] = [
    { value: '', selected: false, termID: 4222, term: '[Complemento]' },
    { value: '', selected: false, termID: 4223, term: '[Mama]' }
  ];

  listaBirads: ListaBirads[] = [
    { blockID: 255, value: 1 },
    { blockID: 256, value: 1 },
    { blockID: 257, value: 1 },
    { blockID: 258, value: 1 },
    { blockID: 259, value: 1 },
    { blockID: 260, value: 1 },
    { blockID: 261, value: 1 },
    { blockID: 262, value: 1 },
    { blockID: 263, value: 1 },
    { blockID: 284, value: 1 },
    { blockID: 285, value: 1 },
  ];

  Subtitulo: string;
  subtituloBloco:string;
  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();
  Lado = '';
  generoLateralidade: string;

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  dinamicLesions: TermosDinamicos[] = [];
  dinamicLesion: any;
  dinamicconclussion: TermosDinamicos[] = this.dinamicLesions;
  lesionRow = 1;
  medicaoNodulo = '';
  cabecalhoTexto = '';
  indicacaoTexto = '';
  calcFrase = '';
  achadosAdTexto = '';
  rodapeTexto = '';
  historiaAdTexto = '';
  cabecalho = false;
  lateralidade = true;
  pelemamilo = false;
  parenquimamamario = false;
  vascularizacaoparenquima = false;
  distorcaoarquitetural = false;
  assimetria = false;
  nodulos = false;
  calcificacoes = false;
  linfonodomegalias = false;
  indicacao = false;
  recomendacao = false;
  achadosTexto = false;
  tecnica = false;
  rodape = false;
  historiaTexto = false;


  medicalReportTypeID = 47;
  pelemamiloID = 255;
  parenquimamamarioID = 256;
  vascularizacaoparenquimaID = 257;
  distorcaoarquiteturalID = 258;
  assimetriaID = 259;
  nodulosID = 260;
  calcificacoesID = 261;
  linfonodomegaliasID = 262;
  indicacaoID = 263;
  recomendacaoID = 285;
  tecnicaID = 284;

  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  public showForm: Boolean = true;
  unidadeMedida: string;
  infoLogotipo:string|any= '';
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  public switchFormReport = true;
  editLaudoMode: boolean = false;
  regraTagBr: boolean = false;

  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _validacoes: Validacoes,
    private _copyPaste: CopyPaste,
    private _toastr: ToastrManager,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    public dialog: MatDialog,
    public _addText: AddText,
    private dataService:DataTermService,
    private _config: ConfigLaudoService) { }

  ngOnInit() {
    this.utilizarEditor();
    this._toastr.warningToastr('Atenção esse exame ainda está em beta, caso venha a ocorrer algum problema reporte ao nosso suporte.', 'Atenção!');
    this._pouchService.loadDatabase("mamografiaAnalise", this.descricao, this.conclusao);
    this.resetaDialog();
    this.Subtitulo = 'Título';
    this.subtituloBloco= this.Subtitulo;
    this.generoLateralidade = 'mamografico';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    const listBlocks = [
      // this.pelemamiloID,
      this.parenquimamamarioID,
      // this.vascularizacaoparenquimaID,
      this.distorcaoarquiteturalID,
      this.assimetriaID,
      this.nodulosID,
      this.calcificacoesID,
      this.linfonodomegaliasID,
      this.indicacaoID,
      this.recomendacaoID,
      this.tecnicaID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "mamografiaMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "mamografia");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'mg-mamografia');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'mg-mamografia');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo(){
    this._pouchService.getAll("mamografiaAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricao[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusao[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.Lado = "";
    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.showForm = false;
    this.achadosAdTexto = "";
    this.rodapeTexto = "";
    this.historiaAdTexto = ""

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      titulo: 'Título',
      id: 'titulo',
      name: 'titulo',
      selected: true,
    },
    {
      titulo: 'Técnica',
      id: 'tecnica',
      name: 'tecnica',
      selected: false
    },
    {
      titulo: 'História Clínica',
      id: 'historia_clinica',
      name: 'historia-clinica',
      subtitulo:'Preencha o campo história clínica',
      selected: false
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo:'Preencha o campo indicação',
      selected: false
    },
    // {
    //   titulo: 'Pele e Mamilos',
    //   id: this.pelemamiloID,
    //   name: 'pele-mamilos',
    //   selected: false
    // },
    {
      titulo: 'Parênquima Mamário',
      id: this.parenquimamamarioID,
      name: 'parenquima-mamario',
      selected: false
    },
    // {
    //   titulo: 'Vascularização do Parênquima',
    //   id: this.vascularizacaoparenquimaID,
    //   name: 'vascularizacao-parenquima',
    //   selected: false
    // },
    {
      titulo: 'Distorção Arquitetural',
      id: this.distorcaoarquiteturalID,
      name: 'distorcao-arquitetural',
      selected: false
    },
    {
      titulo: 'Assimetria',
      id: this.assimetriaID,
      name: 'assimetria',
      selected: false
    },
    {
      titulo: 'Nódulos',
      id: this.nodulosID,
      name: 'nodulos',
      selected: false
    },
    {
      titulo: 'Calcificações',
      id: this.calcificacoesID,
      name: 'calcificacoes',
      selected: false
    },
    {
      titulo: 'Linfonodomegalias',
      id: this.linfonodomegaliasID,
      name: 'linfonodomegalias',
      selected: false
    },
    {
      titulo: 'Recomendação',
      id: this.recomendacaoID,
      name: 'recomendacao',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados_adicionais',
      name: 'achados-adicionais',
      subtitulo:'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;


      if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
        let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
        this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
      }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.lateralidade = (itemSelecionado.id === 'titulo');
    this.historiaTexto = (itemSelecionado.id === 'historia_clinica')
    this.tecnica = (itemSelecionado.id === 'tecnica');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    // this.pelemamilo = (itemSelecionado.id === this.pelemamiloID);
    this.parenquimamamario = (itemSelecionado.id === this.parenquimamamarioID);
    // this.vascularizacaoparenquima = (itemSelecionado.id === this.vascularizacaoparenquimaID);
    this.distorcaoarquitetural = (itemSelecionado.id === this.distorcaoarquiteturalID);
    this.assimetria = (itemSelecionado.id === this.assimetriaID);
    this.nodulos = (itemSelecionado.id === this.nodulosID);
    this.calcificacoes = (itemSelecionado.id === this.calcificacoesID);
    this.linfonodomegalias = (itemSelecionado.id === this.linfonodomegaliasID);
    this.recomendacao = (itemSelecionado.id === this.recomendacaoID);
    this.achadosTexto = (itemSelecionado.id === 'achados_adicionais');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;
    this.dinamicLesions = [];
    this.lesionRow = 1;
    this.medicaoNodulo = '';

    this.genericTermsPeleMamilos = [
      { value: '', selected: false, termID: 4041, term: '[Mama]' },
      { value: '', selected: false, termID: 4043, term: '[Mama]' },
      { value: '', selected: false, termID: 4045, term: '[Mama]' }
    ];

    this.genericTermsVasParenq = [
      { value: '', selected: false, termID: 4048, term: '[Grau]' },
      { value: '', selected: false, termID: 4049, term: '[Mama]' }
    ];

    this.genericTermsDistorArqui = [
      { value: '', selected: false, termID: 4069, term: '[Mama]' },
      { value: '', selected: false, termID: 4070, term: '[Localizacao]' },
      { value: '', selected: false, termID: 4072, term: '[Mama]' },
      { value: '', selected: false, termID: 4073, term: '[Localizacao]' },
      { value: '', selected: false, termID: 4075, term: '[Mama]' }
    ];

    this.genericTermsAssimetria = [
      { value: '', selected: false, termID: 4091, term: '[Tipo]' },
      { value: '', selected: false, termID: 4092, term: '[Mama]' },
      { value: '', selected: false, termID: 4093, term: '[Localizacao]' }
    ];

    this.genericTermsNodulos = [
      { value: '', selected: false, termID: 4052, term: '[Densidade]' },
      { value: '', selected: false, termID: 4053, term: '[Forma]' },
      { value: '', selected: false, termID: 4054, term: '[Margens]' },
      { value: '', selected: false, termID: 4055, term: '[Medida]' },
      { value: '', selected: false, termID: 4056, term: '[Localizacao]' },
      { value: '', selected: false, termID: 4057, term: '[Lado]' },
      { value: '', selected: false, termID: 4059, term: '[Medida]' },
      { value: '', selected: false, termID: 4060, term: '[Localizacao]' },
      { value: '', selected: false, termID: 4061, term: '[Lado]' }
    ];

    this.genericTermsCalcific = [
      { value: '', selected: false, termID: 4078, term: '[TiposDeCalcificacao]' },
      { value: '', selected: false, termID: 4079, term: '[Lado]' },
      { value: '', selected: false, termID: 4081, term: '[Lado]' },
      { value: '', selected: false, termID: 4083, term: '[Lado]' },
      { value: '', selected: false, termID: 4085, term: '[Morfologia]' },
      { value: '', selected: false, termID: 4086, term: '[Distribuicao]' },
      { value: '', selected: false, termID: 4087, term: '[Localizacao]' },
      { value: '', selected: false, termID: 4088, term: '[Lado]' }
    ];

    this.genericTermsLinfonodomegalias = [
      { value: '', selected: false, termID: 4064, term: '[Lado]' },
      { value: '', selected: false, termID: 4065, term: '[Medida]' }
    ];

    this.genericTermsRecomendacao = [
      { value: '', selected: false, termID: 4233, term: '[mama]' },
      { value: '', selected: false, termID: 4234, term: '[tempo]' }
    ];

    this.genericTermsTecnica = [
      { value: '', selected: false, termID: 4222, term: '[Complemento]' },
      { value: '', selected: false, termID: 4223, term: '[Mama]' }
    ];

  }

  enviaLados($event) {
    this.Lado = $event;

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'lateralidade',
        lateralidade: this.Lado
      });
    }
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  adicionaSelecaoPelemamilos($event) {
    this.genericTermsPeleMamilos.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoVascPare($event) {
    this.genericTermsVasParenq.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoDistorArqui($event) {
    this.genericTermsDistorArqui.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoAssimetria($event) {
    this.genericTermsAssimetria.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoNodulos($event) {
    this.genericTermsNodulos.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoCalcificacoes($event) {
    this.genericTermsCalcific.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoLinfonodomegalias($event) {
    this.genericTermsLinfonodomegalias.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoRecomendacao($event) {
    this.genericTermsRecomendacao.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoTecnica($event) {
    this.genericTermsTecnica.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaTermos($event) {
    if ($event === 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados_adicionais',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaHistoria(texto) {
    if (texto)
      this.historiaAdTexto = '<br/><br/><b>HISTÓRIA CLÍNICA:</b><br/>' + texto;
    else
      this.historiaAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'historia_clinica',
        historia: this.historiaAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  incluirLesoesDinamicas($event) {
    const lesoesValue = $event.elemento.value;
    if ($event.linha === 1 && ($event.posicao === 0 || $event.posicao === 1)) {
      if ($event.isPrimeiraLesao === true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaLesoes($event.elemento);
      }
    }

    if ($event.elemento.id == 4055 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = this._validacoes.formataDecimal(lesoesValue);
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 4055,
          lesionID: 2185,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medida]',
          termView: ''
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + this._validacoes.formataDecimal(lesoesValue);
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 4055;
            obj.lesionID = 2185;
            obj.cenario = $event.elemento.cenario,
              obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medida]';
          }
        });
      }
    } else if ($event.elemento.id == 4059 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = this._validacoes.formataDecimal(lesoesValue);
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 4059,
          lesionID: 2186,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medida linfo]',
          termView: ''
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + this._validacoes.formataDecimal(lesoesValue);
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 4059;
            obj.lesionID = 2186;
            obj.cenario = $event.elemento.cenario,
              obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medida linfo]';
          }
        });
      }
    }

    switch ($event.elemento.id) {
      case '4052':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4052,
          lesionID: 2185,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Densidade]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4053':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4053,
          lesionID: 2185,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Forma]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4054':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 4054,
          lesionID: 2185,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Margens]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4056':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4056,
          lesionID: 2185,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localizacao]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4057':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4057,
          lesionID: 2185,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Lado]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4060':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4060,
          lesionID: 2186,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localizacao linfo]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4061':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4061,
          lesionID: 2186,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Lado linfo]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }
    this.dinamicconclussion = this.dinamicLesions;
    this.lesionRow = $event.linha;
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      console.log(blocoSelecionado)
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc: any;
      if (this.indicacao) {
        this.term.blockID = this.indicacaoID;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.indicacaoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });
              let index = this.descricao.findIndex(x => x.blockID === this.indicacaoID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.indicacaoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaconclusaoNull(resultado.conclusion);
                // Zera o Birads
                const birads = this.listaBirads.find(x => x.blockID === this.indicacaoID);
                birads.value = 1;
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.indicacaoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.indicacaoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } 
      // else if (this.pelemamilo) {
      //   this.term.blockID = this.pelemamiloID;

      //   this._pouchService.getLaudos(this.term).then((termResults) => {
      //     this.descricao.forEach(obj => {
      //       if (obj.blockID === this.pelemamiloID && termResults[0] !== undefined) {
      //         this.termResultsAPI = termResults;
      //         obj.textTermID = this.termResultsAPI[0].id;
      //         obj.value = '';
      //         let espaco = '';
      //         let contadorTexto = 0;
      //         this.termResultsAPI.forEach(resultado => {
      //           obj.value = obj.value + espaco + this.MontaDescricaoPeleMamilos(resultado.text);
      //           espaco = '</br>';
      //           contadorTexto++;
      //         });

      //         let index = this.descricao.findIndex(x => x.blockID === this.pelemamiloID);
      //         if (index > -1) {
      //           this.descricao[index].value = obj.value;
      //           this.descricao[index].textTermID = obj.textTermID;
      //           desc = obj.value;
      //         }
      //       }
      //     });

      //     let obj = {
      //       value: '',
      //       blockID: this.pelemamiloID,
      //       textTermID: 0
      //     };
      //     let espaco = '';
      //     const indexConclusion = termResults.filter(x => x.conclusion !== null);
      //     this.termConclusionAPI = termResults;
      //     obj.value = '';
      //     this.termConclusionAPI.forEach(resultado => {
      //       if (resultado.conclusion) {
      //         obj.blockID = this.pelemamiloID;
      //         obj.value = obj.value + espaco + this.MontaConclusaoPeleMamilos(resultado.conclusion);
      //         espaco = '</br>';
      //       } else {
      //         if (indexConclusion.length <= 0) {
      //           obj.blockID = 0;
      //           obj.value = this.RetornaconclusaoNull(resultado.conclusion);
      //         } else {
      //           if (!obj.value) {
      //             obj.blockID = 0;
      //             obj.value = '';
      //           }
      //         }
      //       }
      //     });

      //     const conclusaoAll = this.conclusao.filter(x => x.value !== '');
      //     const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
      //     const conclusao = this.conclusao.find(x => x.blockID === this.pelemamiloID);

      //     if (obj.blockID === 0) {
      //       if (conclusaoAll.length <= 1) {
      //         if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.pelemamiloID) {
      //           conclusaoNull.value = obj.value;
      //           conclusao.value = '';
      //         } else {
      //           conclusaoNull.value = '';
      //         }
      //       } else {
      //         conclusao.value = '';
      //       }
      //     } else if (obj.value) {
      //       conclusaoNull.value = '';
      //       conclusao.value = obj.value;
      //     }

      //     if(!this.newEditor) {
      //       this.AdicionaTexto(this.newEditor);
      //     } else {
      //       this.atualizaLinha({
      //         blockId: this.term.blockID,
      //         descricao: desc,
      //         conclusao: conclusao.value,
      //         conclusaoNull: conclusaoNull.value
      //       });
      //     }
      //   });

      // } else 
      if (this.parenquimamamario) {
        this.term.blockID = this.parenquimamamarioID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.parenquimamamarioID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });
              let index = this.descricao.findIndex(x => x.blockID === this.parenquimamamarioID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.parenquimamamarioID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaconclusaoNull(resultado.conclusion);
                // Zera o Birads
                const birads = this.listaBirads.find(x => x.blockID === this.parenquimamamarioID);
                birads.value = 1;
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.parenquimamamarioID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.parenquimamamarioID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      }
      // else if (this.vascularizacaoparenquima) {
      //   this.term.blockID = this.vascularizacaoparenquimaID;

      //   this._pouchService.getLaudos(this.term).then((termResults) => {
      //     this.descricao.forEach(obj => {
      //       if (obj.blockID === this.vascularizacaoparenquimaID && termResults[0] !== undefined) {
      //         this.termResultsAPI = termResults;
      //         obj.textTermID = this.termResultsAPI[0].id;
      //         obj.value = '';
      //         let espaco = '';
      //         let contadorTexto = 0;
      //         this.termResultsAPI.forEach(resultado => {
      //           obj.value = obj.value + espaco + this.MontaDescricaoVascParenq(resultado.text);
      //           espaco = '</br>';
      //           contadorTexto++;
      //         });

      //         let index = this.descricao.findIndex(x => x.blockID === this.vascularizacaoparenquimaID);
      //         if (index > -1) {
      //           this.descricao[index].value = obj.value;
      //           this.descricao[index].textTermID = obj.textTermID;
      //           desc = obj.value;
      //         }
      //       }
      //     });

      //     let obj = {
      //       value: '',
      //       blockID: this.vascularizacaoparenquimaID,
      //       textTermID: 0
      //     };
      //     let espaco = '';
      //     const indexConclusion = termResults.filter(x => x.conclusion !== null);
      //     this.termConclusionAPI = termResults;
      //     obj.value = '';
      //     this.termConclusionAPI.forEach(resultado => {
      //       if (resultado.conclusion) {
      //         obj.blockID = this.vascularizacaoparenquimaID;
      //         obj.value = obj.value + espaco + this.MontaConclusaoVascParenq(resultado.conclusion);
      //         espaco = '</br>';
      //       } else {
      //         if (indexConclusion.length <= 0) {
      //           obj.blockID = 0;
      //           obj.value = this.RetornaconclusaoNull(resultado.conclusion);
      //         } else {
      //           if (!obj.value) {
      //             obj.blockID = 0;
      //             obj.value = '';
      //           }
      //         }
      //       }
      //     });

      //     const conclusaoAll = this.conclusao.filter(x => x.value !== '');
      //     const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
      //     const conclusao = this.conclusao.find(x => x.blockID === this.vascularizacaoparenquimaID);

      //     if (obj.blockID === 0) {
      //       if (conclusaoAll.length <= 1) {
      //         if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.vascularizacaoparenquimaID) {
      //           conclusaoNull.value = obj.value;
      //           conclusao.value = '';
      //         } else {
      //           conclusaoNull.value = '';
      //         }
      //       } else {
      //         conclusao.value = '';
      //       }
      //     } else if (obj.value) {
      //       conclusaoNull.value = '';
      //       conclusao.value = obj.value;
      //     }

      //     if(!this.newEditor) {
      //       this.AdicionaTexto(this.newEditor);
      //     } else {
      //       this.atualizaLinha({
      //         blockId: this.term.blockID,
      //         descricao: desc,
      //         conclusao: conclusao.value,
      //         conclusaoNull: conclusaoNull.value
      //       });
      //     }
      //   });

      // } else 
      if (this.distorcaoarquitetural) {
        this.term.blockID = this.distorcaoarquiteturalID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.distorcaoarquiteturalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoDistoArqui(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricao.findIndex(x => x.blockID === this.distorcaoarquiteturalID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.distorcaoarquiteturalID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.distorcaoarquiteturalID;
              obj.value = obj.value + espaco + this.MontaConclusaoDistoArqui(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaconclusaoNull(resultado.conclusion);
                // Zera o Birads
                const birads = this.listaBirads.find(x => x.blockID === this.distorcaoarquiteturalID);
                birads.value = 1;
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.distorcaoarquiteturalID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.distorcaoarquiteturalID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.assimetria) {
        this.term.blockID = this.assimetriaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.assimetriaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoAssimetria(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricao.findIndex(x => x.blockID === this.assimetriaID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.assimetriaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.assimetriaID;
              obj.value = obj.value + espaco + this.MontaConclusaoAssimetria(resultado.conclusion);
              console.log(this.listaBirads);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaconclusaoNull(resultado.conclusion);
                // Zera o Birads
                const birads = this.listaBirads.find(x => x.blockID === this.assimetriaID);
                birads.value = 1;
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.assimetriaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.assimetriaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.nodulos) {
        this.term.blockID = this.nodulosID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.nodulosID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '<b>Nodulações:</b>';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoNodulos(resultado.text, contadorTexto);
                contadorTexto++;
              });

              let index = this.descricao.findIndex(x => x.blockID === this.nodulosID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.nodulosID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.nodulosID;
              obj.value = obj.value + espaco + this.MontaConclusaoNodulos(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              contadorTexto++;
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaconclusaoNull(resultado.conclusion);
                // Zera o Birads
                const birads = this.listaBirads.find(x => x.blockID === this.nodulosID);
                birads.value = 1;
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.nodulosID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.nodulosID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.calcificacoes) {
        this.term.blockID = this.calcificacoesID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.calcificacoesID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoCalcificacoes(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricao.findIndex(x => x.blockID === this.calcificacoesID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.calcificacoesID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.calcificacoesID;
              obj.value = obj.value + espaco + this.MontaConclusaoCalcificacoes(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaconclusaoNull(resultado.conclusion);
                // Zera o Birads
                const birads = this.listaBirads.find(x => x.blockID === this.calcificacoesID);
                birads.value = 1;
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.calcificacoesID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.calcificacoesID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.linfonodomegalias) {
        this.term.blockID = this.linfonodomegaliasID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.linfonodomegaliasID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoLinfonodomegalias(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricao.findIndex(x => x.blockID === this.linfonodomegaliasID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.linfonodomegaliasID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.linfonodomegaliasID;
              obj.value = obj.value + espaco + this.MontaConlusaoLinfonodomegalias(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaconclusaoNull(resultado.conclusion);
                // Zera o Birads
                const birads = this.listaBirads.find(x => x.blockID === this.linfonodomegaliasID);
                birads.value = 1;
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.linfonodomegaliasID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.linfonodomegaliasID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.recomendacao) {
        this.term.blockID = this.recomendacaoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.recomendacaoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoRecomendacao(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricao.findIndex(x => x.blockID === this.recomendacaoID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.recomendacaoID,
            textTermID: 0
          };

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
            });
          }

        });

      } else if (this.tecnica) {
        this.term.blockID = this.tecnicaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.tecnicaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoConclusaoTecnica(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricao.findIndex(x => x.blockID === this.tecnicaID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });
          

          let obj = {
            value: '',
            blockID: this.tecnicaID,
            textTermID: 0
          };
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
            });
          }
        });

      }
    }
  }

  MontaDescricaoConclusaoTecnica(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4222 || +term === 4223) {
        this.genericTermsTecnica.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoPeleMamilos(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4041 || +term === 4043 || +term === 4045) {
        this.genericTermsPeleMamilos.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoPeleMamilos(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4041 || +term === 4043 || +term === 4045) {
        this.genericTermsPeleMamilos.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            // texto = textoRetorno;
            const textoSplit = textoRetorno.split('|', 2);
            texto = textoSplit[0];
            // Verifica BI-RADS
            // if (+textoSplit[1]) {
            //   const birads = this.listaBirads.find(x => x.blockID === this.pelemamiloID);
            //   birads.value = +textoSplit[1];
            // }
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoVascParenq(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4048 || +term === 4049) {
        this.genericTermsVasParenq.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoVascParenq(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4048 || +term === 4049) {
        this.genericTermsVasParenq.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            // texto = textoRetorno;
            const textoSplit = textoRetorno.split('|', 2);
            texto = textoSplit[0];
            // Verifica BI-RADS
            // if (+textoSplit[1]) {
            //   const birads = this.listaBirads.find(x => x.blockID === this.vascularizacaoparenquimaID);
            //   birads.value = +textoSplit[1];
            // }
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoDistoArqui(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4069 || +term === 4070 || +term === 4072 || +term === 4073 || +term === 4075) {
        this.genericTermsDistorArqui.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoDistoArqui(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4069 || +term === 4070 || +term === 4072 || +term === 4073 || +term === 4075) {
        this.genericTermsDistorArqui.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            // texto = textoRetorno;
            const textoSplit = textoRetorno.split('|', 2);
            texto = textoSplit[0];
            // Verifica BI-RADS
            if (+textoSplit[1]) {
              const birads = this.listaBirads.find(x => x.blockID === this.distorcaoarquiteturalID);
              birads.value = +textoSplit[1];
            }
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoAssimetria(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4091 || +term === 4092 || +term === 4093) {
        this.genericTermsAssimetria.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoAssimetria(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4091 || +term === 4092 || +term === 4093) {
        this.genericTermsAssimetria.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno.charAt(0).toUpperCase() + textoRetorno.slice(1);
            // texto = textoRetorno;
            const textoSplit = texto.split('|', 2);
            texto = textoSplit[0];

            // Verifica BI-RADS
            if (+generic.termID === 4091) {
              if (generic.value === 'assimetria em desenvolvimento') {
                textoSplit[1] = textoSplit[1].replace('[X]', '4');
              } else if (generic.value === 'assimetria global') {
                textoSplit[1] = textoSplit[1].replace('[X]', '2');
              } else {
                textoSplit[1] = textoSplit[1].replace('[X]', '0');
              }
            }
            if (textoSplit[1] != null) {
              console.log('entrou');
              let birads = this.listaBirads.find(x => x.blockID === this.assimetriaID);
              birads.value = +textoSplit[1];
              console.log(birads.value);
            }
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoNodulos(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoNodulosDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoNodulosDescNormal(texto, contadorTexto);
    }
    return texto;
  }

  TextoNodulosDescNormal(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 4052 || +term === 4053 || +term === 4054 || +term === 4055 ||
          +term === 4056 || +term === 4057 || +term === 4059 || +term === 4060 ||
          +term === 4061) {
          this.genericTermsNodulos.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4052 || +term === 4053 || +term === 4054 || +term === 4055 ||
          +term === 4056 || +term === 4057 || +term === 4059 || +term === 4060 ||
          +term === 4061) {
          this.genericTermsNodulos.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  TextoNodulosDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
    }

    // Lesões dinamicas
    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID == 4052 || item.termID == 4053 || item.termID == 4054 || item.termID == 4055 ||
            item.termID == 4056 || item.termID == 4057 || item.termID == 4059 || item.termID == 4060 ||
            item.termID == 4061) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }

        } else {

          linha++;
          if (lesaoID == item.lesionID && item.linha == linha) {
            textoRetorno = textoRetorno + textoDinamico;
          }

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
          }

          if (item.termID == 4052 || item.termID == 4053 || item.termID == 4054 || item.termID == 4055 ||
            item.termID == 4056 || item.termID == 4057 || item.termID == 4059 || item.termID == 4060 ||
            item.termID == 4061) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }

      }
    });

    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;



    // this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
    //   return lesaoID != din.lesionID;
    // });

    return texto;
  }

  MontaConclusaoNodulos(texto, contadorTexto) {
    let lesaoID;

    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      lesaoID = rowDinamic[0].lesionID;
    }

    this.listaBirads.forEach(y => {
      if (y.blockID === this.nodulosID)
        y.value = 0;
    });

    const textoSplit = texto.split('|', 3);
    let textoRetorno = '';
    let textoDinamico = '';
    let textoBirads = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
      textoBirads = textoSplit[2];
    } else {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
      textoBirads = textoSplit[2];
    }
    // Lesões dinamicas
    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID == 4052 || item.termID == 4053 || item.termID == 4054 || item.termID == 4055 ||
            item.termID == 4056 || item.termID == 4057 || item.termID == 4059 || item.termID == 4060 ||
            item.termID == 4061) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
            // texto = textoRetorno;
            //const textoSplit = textoDinamico.split('|', 2);
            // Verifica BI-RADS
            if (+item.termID === 4052) {
              if (item.value === 'com conteúdo adiposo') {
                textoBirads = textoSplit[2].replace('[X]', '2');
              } else {
                textoBirads = textoSplit[2].replace('[X]', '0');
              }
            } else if (+item.termID === 4054) {
              if (item.value === 'espiculado') {
                textoBirads = textoSplit[2].replace('[X]', '4');
              } else {
                textoBirads = textoSplit[2].replace('[X]', '0');
              }
            }
            if (+textoBirads) {
              const birads = this.listaBirads.find(x => x.blockID === this.nodulosID);
              birads.value = +textoBirads;
            }
          }

        } else {

          linha++;
          if (lesaoID == item.lesionID && item.linha == linha) {
            textoRetorno = textoRetorno + textoDinamico;
          }

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
            textoBirads = textoSplit[2];
          }

          if (item.termID == 4052 || item.termID == 4053 || item.termID == 4054 || item.termID == 4055 ||
            item.termID == 4056 || item.termID == 4057 || item.termID == 4059 || item.termID == 4060 ||
            item.termID == 4061) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
            // texto = textoRetorno;
            //const textoSplit = textoDinamico.split('|', 2);
            // Verifica BI-RADS
            if (+item.termID === 4052) {
              if (item.value === 'com conteúdo adiposo') {
                textoBirads = textoSplit[2].replace('[X]', '2');
              } else {
                textoBirads = textoSplit[2].replace('[X]', '0');
              }
            } else if (+item.termID === 4054) {
              if (item.value === 'espiculado') {
                textoBirads = textoSplit[2].replace('[X]', '4');
              } else {
                textoBirads = textoSplit[2].replace('[X]', '0');
              }
            }
            if (+textoBirads) {
              const birads = this.listaBirads.find(x => x.blockID === this.nodulosID);
              birads.value = +textoBirads;
            }
          }
        }

      }
    });

    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    // this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
    //   return lesaoID != din.lesionID;
    // });

    return texto;
  }

  MontaDescricaoCalcificacoes(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4078 || +term === 4079 || +term === 4081 ||
        +term === 4083 || +term === 4085 || +term === 4086 ||
        +term === 4087 || +term === 4088) {
        this.genericTermsCalcific.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoCalcificacoes(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4078 || +term === 4079 || +term === 4081 ||
        +term === 4083 || +term === 4085 || +term === 4086 ||
        +term === 4087 || +term === 4088) {
        this.genericTermsCalcific.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            // texto = textoRetorno;
            const textoSplit = textoRetorno.split('|', 2);
            texto = textoSplit[0];
            // Verifica BI-RADS
            if (+generic.termID === 4085) {
              if (generic.value === 'puntiforme') {
                textoSplit[1] = textoSplit[1].replace('[X]', '3');
              } else {
                textoSplit[1] = textoSplit[1].replace('[X]', '4');
              }
            }
            if (+textoSplit[1]) {
              const birads = this.listaBirads.find(x => x.blockID === this.calcificacoesID);
              birads.value = +textoSplit[1];
              this.listaBirads.forEach(y => {
                if (y.blockID === birads.blockID)
                  y.value = birads.value;
              });
            }
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoLinfonodomegalias(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4064 || +term === 4065) {
        this.genericTermsLinfonodomegalias.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConlusaoLinfonodomegalias(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4064 || +term === 4065) {
        this.genericTermsLinfonodomegalias.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            // texto = textoRetorno;
            const textoSplit = textoRetorno.split('|', 2);
            texto = textoSplit[0];
            // Verifica BI-RADS
            if (+textoSplit[1]) {
              const birads = this.listaBirads.find(x => x.blockID === this.linfonodomegaliasID);
              birads.value = +textoSplit[1];
            }
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoRecomendacao(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4233 || +term === 4234) {
        this.genericTermsRecomendacao.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${ maskSplit[0] } ${ this.Lado.toUpperCase() }</p>`,
      }
    });

    this.descricao.forEach((descricao, index) => {
      if(index === 0) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'data': {
            'text': `<b>TÉCNICA:</b>`,
          }
        });
      } else if(index === 1) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'data': {
            'text': `<b>INDICAÇÃO:</b>`,
          }
        });
      } else if(index === 3) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'data': {
            'text': `<b>DESCRIÇÃO:</b>`,
          }
        });
      }

      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[4]}</p>`,
      }
    });

    this.conclusao.forEach((conclusao) => {
      if (conclusao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let ultimoBiRads = 1;
    let maskSplit = this.mask.split('|');
    let conclus = this.conclusao[8].value.split('<>');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.Lado.toUpperCase();
    this.htmlContent += maskSplit[1];
    this.htmlContent += this.descricao[0].value;
    this.htmlContent += this.historiaAdTexto;
    this.htmlContent += maskSplit[2];
    this.htmlContent += this.descricao[1].value;

    this.htmlContent += maskSplit[3];
    for (i = 0; i < this.descricao.length; i++) {
      var newValue = this.descricao[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricao[i].value = newValue2;
    }
    for (i = 0; i < this.descricao.length; i++) {
      if (this.descricao[i].value && i >= 2 && i < 10) {
        if (i > 0 && i < this.descricao.length) {
          this.htmlContent += '<br/>' + this.descricao[i].value + '<br/>';
        }
      }
    }

    this.htmlContent += maskSplit[4];
    for (i = 0; i < this.conclusao.length; i++) {
      if (this.conclusao[i].value) {
        if (i === 8)
        this.htmlContent +=  '<br/>' + conclus[0];
        else
        this.htmlContent += '<br/>' + this.conclusao[i].value;
      }
    }
    //lógica de BRADS
    // calculo de BRADS (5, 4, 0, 3 ,2, 1)
    if (this.conclusao[0].value === '') {
      this.listaBirads.forEach(obj => {
        if (obj.value === 5) {
          ultimoBiRads = obj.value;
        } else if (obj.value === 4 && obj.value >= ultimoBiRads) {
          ultimoBiRads = obj.value;
        } else if (obj.value === 0 && ultimoBiRads <= 3) {
          ultimoBiRads = obj.value;
        } else if (obj.value === 3 && obj.value >= ultimoBiRads && ultimoBiRads !== 0) {
          ultimoBiRads = obj.value;
        } else if (obj.value === 2 && obj.value >= ultimoBiRads && ultimoBiRads !== 0) {
          ultimoBiRads = obj.value;
        } else if (obj.value === 1 && obj.value >= ultimoBiRads && ultimoBiRads !== 0) {
          ultimoBiRads = obj.value;
        }
      });

      this.htmlContent += '</br>Pelo sistema de padronização de laudos BI-RADS®: Categoria ' + ultimoBiRads + '.<br/>';
    }

    if (this.descricao[10].value) {
      this.htmlContent += maskSplit[5];
      this.htmlContent += '</br>' + this.descricao[10].value + '</br>';

    }
    //if (this.achadosTexto === true) {
    //this.htmlContent += maskSplit[6];
    this.htmlContent += this.achadosAdTexto;
    //}

    //Frase de "Nota.." de Linfonodomegalia
    if (conclus[1] === undefined) {
      this.htmlContent += '';
    } else {
      this.htmlContent += '</br>' + conclus[1];
    }

    if(this.rodapeTexto != '') {
      this.htmlContent += this.rodapeTexto;
    }
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
    conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
    lateralidade,
    historia
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricao.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusao.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusao.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }

        if(lateralidade !== undefined) {
          const side = document.querySelector(`#e-${blockId}`);

          let lado = lateralidade;

          if(side) {
						side.innerHTML = `MAMOGRAFIA ${ lado.toUpperCase() }`;
          }
        }
			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }

      if(indicacao === undefined && historia === undefined) {
				if(descricao === undefined) {
					this.descricao.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusao.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusao.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }

        if(lateralidade !== undefined) {
          const side = document.querySelector(`#e-${blockId}`);

          let lado = lateralidade;

          if(side) {
						side.innerHTML = `MAMOGRAFIA ${ lado.toUpperCase() }`;
          }
        }
			} else if(indicacao !==undefined || historia !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(historia !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = historia;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  RetornaconclusaoNull(Texto) {
    let texto = '';
    texto = 'Ausência de achados mamográficos suspeitos.<br/>Pelo sistema de padronização de laudos BI-RADS®: Categoria 1.';
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
