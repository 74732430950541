import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Validacoes } from '../../../../utils/validacoes';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

export interface TermosGenericos {
  value: string;
  selected: boolean;
  term: string;
  termID: number;
  linha: number;
  cenario: number;
  posicao: number;
  lesionID: number;
}
@Component({
  selector: 'app-glandulas-salivares',
  templateUrl: './glandulas-salivares.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./glandulas-salivares.component.css']
})
export class GlandulasSalivaresComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {listTerm:[607],blockID:30},
    {listTerm:[621],blockID:32},
    {listTerm:[575],blockID:28},
    {listTerm:[590],blockID:29},
    {listTerm:[562],blockID:27},
  ]

  descricoes: DescricaoConclusao[] = [
    { value: 'Glândula submandibular direita tópica, de dimensões normais, contornos regulares e ecotextura preservada.', blockID: 30, textTermID: 0, visivel: true },
    { value: 'Glândula submandibular esquerda tópica, de dimensões normais, contornos regulares e ecotextura preservada.', blockID: 32, textTermID: 0, visivel: true },
    { value: 'Glândula parótida direita tópica, de dimensões normais, contornos regulares e ecotextura preservada.', blockID: 28, textTermID: 0, visivel: true },
    { value: 'Glândula parótida esquerda tópica, de dimensões normais, contornos regulares e ecotextura preservada.', blockID: 29, textTermID: 0, visivel: true },
    { value: 'Não há evidência de linfonodomegalias.', blockID: 27, textTermID: 0, visivel: true }
  ];

  conclusoes: DescricaoConclusao[] = [
    { value: 'Estudo ultrassonográfico dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 30, textTermID: 0, visivel: true },
    { value: '', blockID: 32, textTermID: 0, visivel: true },
    { value: '', blockID: 28, textTermID: 0, visivel: true },
    { value: '', blockID: 29, textTermID: 0, visivel: true },
    { value: '', blockID: 27, textTermID: 0, visivel: true }
  ];

  genericTermsParoditasDireita: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 577, termID: 578, cenario: 0, posicao: 0, linha: 0, term: '[Dimensões]' },
    { value: '', selected: false, lesionID: 577, termID: 579, cenario: 0, posicao: 0, linha: 0, term: '[Ecotextura]' },
    { value: '', selected: false, lesionID: 577, termID: 581, cenario: 0, posicao: 0, linha: 0, term: '[Medida linfonodo]' },
    { value: '', selected: false, lesionID: 582, termID: 583, cenario: 0, posicao: 0, linha: 0, term: '[Composição]' },
    { value: '', selected: false, lesionID: 582, termID: 584, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]' },
    { value: '', selected: false, lesionID: 582, termID: 585, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]' },
    { value: '', selected: false, lesionID: 582, termID: 586, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
    { value: '', selected: false, lesionID: 587, termID: 605, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
  ];

  genericTermsParoditasEsquerda: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 592, termID: 593, cenario: 0, posicao: 0, linha: 0, term: '[Dimensões]' },
    { value: '', selected: false, lesionID: 592, termID: 594, cenario: 0, posicao: 0, linha: 0, term: '[Ecotextura]' },
    { value: '', selected: false, lesionID: 592, termID: 596, cenario: 0, posicao: 0, linha: 0, term: '[Medida linfonodo]' },
    { value: '', selected: false, lesionID: 597, termID: 598, cenario: 0, posicao: 0, linha: 0, term: '[Composição]' },
    { value: '', selected: false, lesionID: 597, termID: 599, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]' },
    { value: '', selected: false, lesionID: 597, termID: 600, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]' },
    { value: '', selected: false, lesionID: 597, termID: 601, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
    { value: '', selected: false, lesionID: 602, termID: 606, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
  ];

  genericTermsSubmandibularesDireita: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 609, termID: 610, cenario: 0, posicao: 0, linha: 0, term: '[Dimensões]' },
    { value: '', selected: false, lesionID: 609, termID: 611, cenario: 0, posicao: 0, linha: 0, term: '[Ecotextura]' },
    { value: '', selected: false, lesionID: 612, termID: 613, cenario: 0, posicao: 0, linha: 0, term: '[Composição]' },
    { value: '', selected: false, lesionID: 612, termID: 614, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]' },
    { value: '', selected: false, lesionID: 612, termID: 615, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]' },
    { value: '', selected: false, lesionID: 612, termID: 616, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
    { value: '', selected: false, lesionID: 617, termID: 620, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
  ];

  genericTermsSubmandibularesEsquerda: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 623, termID: 624, cenario: 0, posicao: 0, linha: 0, term: '[Dimensões]' },
    { value: '', selected: false, lesionID: 623, termID: 625, cenario: 0, posicao: 0, linha: 0, term: '[Ecotextura]' },
    { value: '', selected: false, lesionID: 626, termID: 627, cenario: 0, posicao: 0, linha: 0, term: '[Composição]' },
    { value: '', selected: false, lesionID: 626, termID: 628, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]' },
    { value: '', selected: false, lesionID: 626, termID: 629, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]' },
    { value: '', selected: false, lesionID: 626, termID: 630, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
    { value: '', selected: false, lesionID: 631, termID: 634, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
  ];

  genericTermsLinfonodomegalia: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 0, termID: 566, cenario: 0, posicao: 0, linha: 0, term: '[Localização]' },
    { value: '', selected: false, lesionID: 0, termID: 567, cenario: 0, posicao: 0, linha: 0, term: '[Lateralidade]' },
    { value: '', selected: false, lesionID: 0, termID: 568, cenario: 0, posicao: 0, linha: 0, term: '[Morfologia]' },
    { value: '', selected: false, lesionID: 0, termID: 569, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
    { value: '', selected: false, lesionID: 570, termID: 573, cenario: 0, posicao: 0, linha: 0, term: '[Morfologia]' },
    { value: '', selected: false, lesionID: 570, termID: 574, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
  ];

  Subtitulo: string;
  subtituloBloco: string;
  mask: string;
  htmlContent: string;
  term = new Term();
  injuries = new Injuries();

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  dinamicLesions: TermosGenericos[] = [];
  dinamicLesion: any;
  lesionRow = 1;
  medicaoNodulo = '';
  cabecalhoTexto = "";
  indicacaoTexto = '';
  achadosAdTexto = '';
  rodapeTexto = '';

  cabecalho = false;
  submandibularesDireita = true;
  submandibularesEsquerda = false;
  paraditasDireita = false;
  paraditasEsquerda = false;
  linfonodomegalia = false;
  indicacao = false;
  achadosTexto = false;
  rodape = false;
  editLaudoMode: boolean = false;


  medicalReportTypeID = 29;
  submandibularesDireitaID = 30;
  submandibularesEsquerdaID = 32;
  paraditasDireitaID = 28;
  paraditasEsquerdaID = 29;
  linfonodomegaliaID = 27;

  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  public switchFormReport: boolean = true;
  public showForm: Boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  unidadeMedida: string;

  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _toastr: ToastrManager,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private dialog: MatDialog,
    private _common: CommonService,
    private _copyPaste: CopyPaste,
    private _config: ConfigLaudoService,
    public _addText: AddText,
    public _pouchService: PouchDBService,
    private dataService:DataTermService) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("glandulas-salivaresAnalise", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Submandibular direita'
    this.subtituloBloco = 'Submandibular direita';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    const listBlocks = [
      this.submandibularesDireitaID,
      this.submandibularesEsquerdaID,
      this.paraditasDireitaID,
      this.paraditasEsquerdaID,
      this.linfonodomegaliaID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "glandulas-salivaresMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "glandulasSalivares");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-glandulas-salivares');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-glandulas-salivares');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo(){
    this._pouchService.getAll("glandulas-salivaresAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricoes[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.showForm = false;
    this.achadosAdTexto = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  blocos = [

    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: false
    },
    {
      titulo: 'Submandibular direita',
      id: this.submandibularesDireitaID,
      name: 'submandibulares-direita',
      selected: true
    },
    {
      titulo: 'Submandibular esquerda',
      id: this.submandibularesEsquerdaID,
      name: 'submandibulares-esquerda',
      selected: false
    },
    {
      titulo: 'Parótida direita',
      id: this.paraditasDireitaID,
      name: 'paroditas-direita',
      selected: false
    },
    {
      titulo: 'Parótida esquerda',
      id: this.paraditasEsquerdaID,
      name: 'paroditas-esquerda',
      selected: false
    },
    {
      titulo: 'Linfonodomegalias',
      id: this.linfonodomegaliaID,
      name: 'linfonodomegalias',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.submandibularesDireita = (itemSelecionado.id === this.submandibularesDireitaID);
    this.submandibularesEsquerda = (itemSelecionado.id === this.submandibularesEsquerdaID);
    this.paraditasDireita = (itemSelecionado.id === this.paraditasDireitaID);
    this.paraditasEsquerda = (itemSelecionado.id === this.paraditasEsquerdaID);
    this.linfonodomegalia = (itemSelecionado.id === this.linfonodomegaliaID);
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.achadosTexto = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');

    this.AdicionaTexto(this.newEditor);
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;
    this.dinamicLesions = [];
    this.lesionRow = 1;
    this.medicaoNodulo = '';

    this.genericTermsParoditasDireita = [
      { value: '', selected: false, lesionID: 577, termID: 578, cenario: 0, posicao: 0, linha: 0, term: '[Dimensões]' },
      { value: '', selected: false, lesionID: 577, termID: 579, cenario: 0, posicao: 0, linha: 0, term: '[Ecotextura]' },
      { value: '', selected: false, lesionID: 577, termID: 581, cenario: 0, posicao: 0, linha: 0, term: '[Medida linfonodo]' },
      { value: '', selected: false, lesionID: 582, termID: 583, cenario: 0, posicao: 0, linha: 0, term: '[Composição]' },
      { value: '', selected: false, lesionID: 582, termID: 584, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]' },
      { value: '', selected: false, lesionID: 582, termID: 585, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]' },
      { value: '', selected: false, lesionID: 582, termID: 586, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
      { value: '', selected: false, lesionID: 587, termID: 605, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
    ];

    this.genericTermsParoditasEsquerda = [
      { value: '', selected: false, lesionID: 592, termID: 593, cenario: 0, posicao: 0, linha: 0, term: '[Dimensões]' },
      { value: '', selected: false, lesionID: 592, termID: 594, cenario: 0, posicao: 0, linha: 0, term: '[Ecotextura]' },
      { value: '', selected: false, lesionID: 592, termID: 596, cenario: 0, posicao: 0, linha: 0, term: '[Medida linfonodo]' },
      { value: '', selected: false, lesionID: 597, termID: 598, cenario: 0, posicao: 0, linha: 0, term: '[Composição]' },
      { value: '', selected: false, lesionID: 597, termID: 599, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]' },
      { value: '', selected: false, lesionID: 597, termID: 600, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]' },
      { value: '', selected: false, lesionID: 597, termID: 601, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
      { value: '', selected: false, lesionID: 602, termID: 606, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
    ];

    this.genericTermsSubmandibularesDireita = [
      { value: '', selected: false, lesionID: 609, termID: 610, cenario: 0, posicao: 0, linha: 0, term: '[Dimensões]' },
      { value: '', selected: false, lesionID: 609, termID: 611, cenario: 0, posicao: 0, linha: 0, term: '[Ecotextura]' },
      { value: '', selected: false, lesionID: 612, termID: 613, cenario: 0, posicao: 0, linha: 0, term: '[Composição]' },
      { value: '', selected: false, lesionID: 612, termID: 614, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]' },
      { value: '', selected: false, lesionID: 612, termID: 615, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]' },
      { value: '', selected: false, lesionID: 612, termID: 616, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
      { value: '', selected: false, lesionID: 617, termID: 620, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
    ];

    this.genericTermsSubmandibularesEsquerda = [
      { value: '', selected: false, lesionID: 623, termID: 624, cenario: 0, posicao: 0, linha: 0, term: '[Dimensões]' },
      { value: '', selected: false, lesionID: 623, termID: 625, cenario: 0, posicao: 0, linha: 0, term: '[Ecotextura]' },
      { value: '', selected: false, lesionID: 626, termID: 627, cenario: 0, posicao: 0, linha: 0, term: '[Composição]' },
      { value: '', selected: false, lesionID: 626, termID: 628, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]' },
      { value: '', selected: false, lesionID: 626, termID: 629, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]' },
      { value: '', selected: false, lesionID: 626, termID: 630, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
      { value: '', selected: false, lesionID: 631, termID: 634, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
    ];

    this.genericTermsLinfonodomegalia = [
      { value: '', selected: false, lesionID: 0, termID: 566, cenario: 0, posicao: 0, linha: 0, term: '[Localização]' },
      { value: '', selected: false, lesionID: 0, termID: 567, cenario: 0, posicao: 0, linha: 0, term: '[Lateralidade]' },
      { value: '', selected: false, lesionID: 0, termID: 568, cenario: 0, posicao: 0, linha: 0, term: '[Morfologia]' },
      { value: '', selected: false, lesionID: 0, termID: 569, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
      { value: '', selected: false, lesionID: 570, termID: 573, cenario: 0, posicao: 0, linha: 0, term: '[Morfologia]' },
      { value: '', selected: false, lesionID: 570, termID: 574, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
    ];
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  incluirLesoesDinamicas($event) {
    const lesoesValue = this._validacoes.formataDecimal($event.elemento.value);

    if ($event.elemento.id == 671 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 671,
          lesionID: 664,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medida nódulo]',
          termView: 'inMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 671;
            obj.lesionID = 664;
            obj.cenario = $event.elemento.cenario,
              obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medida nódulo]';
          }
        });
      }
    } else if ($event.elemento.id == 679 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 679,
          lesionID: 672,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medida nódulo sem]',
          termView: 'inMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 679;
            obj.lesionID = 672;
            obj.cenario = $event.elemento.cenario,
              obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medida nódulo sem]';
          }
        });
      }
    } else if ($event.elemento.id == 574 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 574,
          lesionID: 570,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medida]',
          termView: 'inMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 574;
            obj.lesionID = 570;
            obj.cenario = $event.elemento.cenario,
              obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medida]';
          }
        });
      }
    }

    if ($event.linha == 1 && ($event.posicao == 0 || $event.posicao == 1)) {
      this.genericTermsLinfonodomegalia.forEach(obj => {
        if (obj.termID == $event.elemento.id) {
          obj.selected = true;
          obj.value = lesoesValue;
        }
      });
      if ($event.isPrimeiraLesao == true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaLesoes($event.elemento);
      }
    }

    switch ($event.elemento.id) {
      // Linfonodomegalia
      case '571':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 571,
          lesionID: 570,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '572':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 572,
          lesionID: 570,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Lateralidade]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // Nódulo sem
      case '665':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 665,
          lesionID: 664,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Composição]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '666':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 666,
          lesionID: 664,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '667':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 667,
          lesionID: 664,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Formato]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '668':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 668,
          lesionID: 664,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Margens]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '669':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 669,
          lesionID: 664,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Focos ecogênicos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '670':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 670,
          lesionID: 664,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // Nódulo sem
      case '673':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 673,
          lesionID: 672,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Composição]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '674':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 674,
          lesionID: 672,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '678':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 678,
          lesionID: 672,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }

    this.lesionRow = $event.linha;
  }

  adicionaSelecaoParoditasDireita($event) {
    this.genericTermsParoditasDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoParoditasEsquerda($event) {
    this.genericTermsParoditasEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoSubmandibularesDireita($event) {
    this.genericTermsSubmandibularesDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoSubmandibularesEsquerda($event) {
    this.genericTermsSubmandibularesEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoLinfonodomegalia($event) {
    this.genericTermsLinfonodomegalia.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.paraditasDireita) {
        this.term.blockID = this.paraditasDireitaID;
        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.paraditasDireitaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoParoditasDireita(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.paraditasDireitaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.paraditasDireitaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.paraditasDireitaID;
              obj.value = obj.value + espaco + resultado.conclusion;
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.paraditasDireitaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.paraditasDireitaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.paraditasDireitaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.paraditasEsquerda) {
        this.term.blockID = this.paraditasEsquerdaID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.paraditasEsquerdaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoParoditasEsquerda(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.paraditasEsquerdaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.paraditasEsquerdaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.paraditasEsquerdaID;
              obj.value = obj.value + espaco + resultado.conclusion;
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.paraditasEsquerdaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.paraditasEsquerdaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.paraditasEsquerdaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.submandibularesDireita) {
        this.term.blockID = this.submandibularesDireitaID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.submandibularesDireitaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoSubmandibularesDireita(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.submandibularesDireitaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.submandibularesDireitaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.submandibularesDireitaID;
              obj.value = obj.value + espaco + resultado.conclusion;
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.submandibularesDireitaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.submandibularesDireitaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.submandibularesDireitaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.submandibularesEsquerda) {
        this.term.blockID = this.submandibularesEsquerdaID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.submandibularesEsquerdaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoSubmandibularesEsquerda(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.submandibularesEsquerdaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.submandibularesEsquerdaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.submandibularesEsquerdaID;
              obj.value = obj.value + espaco + resultado.conclusion;
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.submandibularesEsquerdaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.submandibularesEsquerdaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.submandibularesEsquerdaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.linfonodomegalia) {
        this.term.blockID = this.linfonodomegaliaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.linfonodomegaliaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '</br>';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoLinfonodomegalia(resultado.text, contadorTexto);
                espaco = '';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.linfonodomegaliaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.linfonodomegaliaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoLinfonodomegalia(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.linfonodomegaliaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.linfonodomegaliaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.linfonodomegaliaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  MontaDescricaoParoditasDireita(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 578 || +term === 579 || +term === 581 || +term === 583 || +term === 584
          || +term === 585 || +term === 586 || +term === 605) {
          this.genericTermsParoditasDireita.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 605) {
          this.genericTermsParoditasDireita.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoParoditasEsquerda(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 593 || +term === 594 || +term === 596 || +term === 598 ||
          +term === 599 || +term === 600 || +term === 601 || +term === 606) {
          this.genericTermsParoditasEsquerda.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 606) {
          this.genericTermsParoditasEsquerda.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoSubmandibularesDireita(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 610 || +term === 611 || +term === 613 || +term === 614 ||
          +term === 615 || +term === 616 || +term === 620) {
          this.genericTermsSubmandibularesDireita.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 620) {
          this.genericTermsSubmandibularesDireita.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoSubmandibularesEsquerda(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 624 || +term === 625 || +term === 627 || +term === 628 ||
          +term === 629 || +term === 630 || +term === 634) {
          this.genericTermsSubmandibularesEsquerda.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 634) {
          this.genericTermsSubmandibularesEsquerda.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoLinfonodomegalia(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoLinfonodomegaliaDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoLinfonodomegaliaDescNormal(texto, contadorTexto);
    }
    return texto;
  }

  TextoLinfonodomegaliaDescNormal(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 566 || +term === 567 || +term === 568 || +term === 569) {
        this.genericTermsLinfonodomegalia.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  TextoLinfonodomegaliaDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '</br>';
      textoDinamico = textoSplit[1]+ '</br>';
    }

    // Lista term
    this.term.listTermID.forEach(term => {
      if (+term === 573 || +term === 574) {
        this.genericTermsLinfonodomegalia.forEach(generic => {
          if (+generic.termID === +term) {
            textoRetorno = textoRetorno.replace(generic.term, generic.value);
          }
        });
      }
    });

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID == 571 || item.termID == 572 || item.termID == 574) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1] + '</br>';
          }

          if (item.termID == 571 || item.termID == 572 || item.termID == 574) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });

    return texto;
  }

  MontaConclusaoLinfonodomegalia(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 566 || +term === 567 || +term === 568 || +term === 569) {
        this.genericTermsLinfonodomegalia.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p></br>${this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '<b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });
    this.descricoes.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao) => {
      if (conclusao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.indicacaoTexto;
      //Lógica Rules
      if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>';

    this.htmlContent += maskSplit[1];

    for (i = 0; i < this.descricoes.length; i++) {
      var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value) {
        this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
      }
    }
    this.htmlContent += maskSplit[2];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }

    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId} p`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId} p`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }
			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    // if (this.conclusoes.length <= 1) {
    texto = 'Estudo ultrassonográfico dentro dos padrões da normalidade.';
    // }
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
