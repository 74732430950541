import { Injectable }   from  '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { Observable } from 'rxjs';
import { User } from '../auth/user';

@Injectable()

export class Authintersepctor implements HttpInterceptor{
    constructor(private _user: User){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        let reqUrl = req.url;
        let viaCEP = 'https://viacep.com.br/';

        if(reqUrl.indexOf(viaCEP) !== -1){
            return next.handle(req);
        } else {
            let tokenData = this._user.getUserToken();
            let authHeader = 'Bearer ' + tokenData;
    
            const authReq = req.clone({setHeaders: {Authorization: authHeader}});
    
            return next.handle(authReq);
        }

    }

}
