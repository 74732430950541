import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { Validacoes } from '../../../../utils/validacoes';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

export interface TermosGenericos {
  value: string;
  selected: boolean;
  term: string;
  termID: number;
  linha: number;
  cenario: number;
  posicao: number;
  lesionID: number;
}

export interface Tirads {
  id: string;
  value: string;
  tiradsValue: string;
  count: number;
}

@Component({
  selector: 'app-cervical',
  templateUrl: './cervical.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./cervical.component.css']
})
export class CervicalComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {listTerm:[641],blockID:31},
    {listTerm:[607],blockID:30},
    {listTerm:[621],blockID:32},
    {listTerm:[575],blockID:28},
    {listTerm:[590],blockID:29},
    {listTerm:[562],blockID:27},
  ]

  descricoes: DescricaoConclusao[] = [
    {
      value: 'Glândula tireoide tópica, de dimensões normais e contornos preservados.</br>' +
        'Parênquima tireoidiano com ecotextura homogênea. Não foram caracterizadas lesões sólidas ou císticas.</br>' +
        'Lobo direito da tireoide:</br>' +
        'Lobo esquerdo da tireoide:</br>' +
        'Istmo:</br>' +
        'Volume total:', blockID: 31, textTermID: 0, visivel: true
    },
    { value: 'Glândula submandibular direita tópica, de dimensões normais, contornos regulares e ecotextura preservada.', blockID: 30, textTermID: 0, visivel: true },
    { value: 'Glândula submandibular esquerda tópica, de dimensões normais, contornos regulares e ecotextura preservada.', blockID: 32, textTermID: 0, visivel: true },
    { value: 'Glândula parótida direita tópica, de dimensões normais, contornos regulares e ecotextura preservada.', blockID: 28, textTermID: 0, visivel: true },
    { value: 'Glândula parótida esquerda tópica, de dimensões normais, contornos regulares e ecotextura preservada.', blockID: 29, textTermID: 0, visivel: true },
    { value: 'Não há evidência de linfonodomegalias.', blockID: 27, textTermID: 0, visivel: true }
  ];

  conclusoes: DescricaoConclusao[] = [
    { value: 'Estudo ultrassonográfico dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 31, textTermID: 0, visivel: true },
    { value: '', blockID: 30, textTermID: 0, visivel: true },
    { value: '', blockID: 32, textTermID: 0, visivel: true },
    { value: '', blockID: 28, textTermID: 0, visivel: true },
    { value: '', blockID: 29, textTermID: 0, visivel: true },
    { value: '', blockID: 27, textTermID: 0, visivel: true }
  ];

  genericTermsParoditasDireita: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 577, termID: 578, cenario: 0, posicao: 0, linha: 0, term: '[Dimensões]' },
    { value: '', selected: false, lesionID: 577, termID: 579, cenario: 0, posicao: 0, linha: 0, term: '[Ecotextura]' },
    { value: '', selected: false, lesionID: 577, termID: 581, cenario: 0, posicao: 0, linha: 0, term: '[Medida linfonodo]' },
    { value: '', selected: false, lesionID: 582, termID: 583, cenario: 0, posicao: 0, linha: 0, term: '[Composição]' },
    { value: '', selected: false, lesionID: 582, termID: 584, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]' },
    { value: '', selected: false, lesionID: 582, termID: 585, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]' },
    { value: '', selected: false, lesionID: 582, termID: 586, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
    { value: '', selected: false, lesionID: 587, termID: 605, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
  ];

  genericTermsParoditasEsquerda: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 592, termID: 593, cenario: 0, posicao: 0, linha: 0, term: '[Dimensões]' },
    { value: '', selected: false, lesionID: 592, termID: 594, cenario: 0, posicao: 0, linha: 0, term: '[Ecotextura]' },
    { value: '', selected: false, lesionID: 592, termID: 596, cenario: 0, posicao: 0, linha: 0, term: '[Medida linfonodo]' },
    { value: '', selected: false, lesionID: 597, termID: 598, cenario: 0, posicao: 0, linha: 0, term: '[Composição]' },
    { value: '', selected: false, lesionID: 597, termID: 599, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]' },
    { value: '', selected: false, lesionID: 597, termID: 600, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]' },
    { value: '', selected: false, lesionID: 597, termID: 601, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
    { value: '', selected: false, lesionID: 602, termID: 606, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
  ];

  genericTermsSubmandibularesDireita: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 609, termID: 610, cenario: 0, posicao: 0, linha: 0, term: '[Dimensões]' },
    { value: '', selected: false, lesionID: 609, termID: 611, cenario: 0, posicao: 0, linha: 0, term: '[Ecotextura]' },
    { value: '', selected: false, lesionID: 612, termID: 613, cenario: 0, posicao: 0, linha: 0, term: '[Composição]' },
    { value: '', selected: false, lesionID: 612, termID: 614, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]' },
    { value: '', selected: false, lesionID: 612, termID: 615, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]' },
    { value: '', selected: false, lesionID: 612, termID: 616, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
    { value: '', selected: false, lesionID: 617, termID: 620, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
  ];

  genericTermsSubmandibularesEsquerda: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 623, termID: 624, cenario: 0, posicao: 0, linha: 0, term: '[Dimensões]' },
    { value: '', selected: false, lesionID: 623, termID: 625, cenario: 0, posicao: 0, linha: 0, term: '[Ecotextura]' },
    { value: '', selected: false, lesionID: 626, termID: 627, cenario: 0, posicao: 0, linha: 0, term: '[Composição]' },
    { value: '', selected: false, lesionID: 626, termID: 628, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]' },
    { value: '', selected: false, lesionID: 626, termID: 629, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]' },
    { value: '', selected: false, lesionID: 626, termID: 630, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
    { value: '', selected: false, lesionID: 631, termID: 634, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
  ];

  genericTermsLinfonodomegalia: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 0, termID: 566, cenario: 0, posicao: 0, linha: 0, term: '[Localização]' },
    { value: '', selected: false, lesionID: 0, termID: 567, cenario: 0, posicao: 0, linha: 0, term: '[Lateralidade]' },
    { value: '', selected: false, lesionID: 0, termID: 568, cenario: 0, posicao: 0, linha: 0, term: '[Morfologia]' },
    { value: '', selected: false, lesionID: 0, termID: 569, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
    { value: '', selected: false, lesionID: 570, termID: 573, cenario: 0, posicao: 0, linha: 0, term: '[Morfologia]' },
    { value: '', selected: false, lesionID: 570, termID: 574, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
  ];

  genericTermsTireoide: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 635, termID: 640, cenario: 0, posicao: 0, linha: 0, term: '[Medida lobo]' },
    { value: '', selected: false, lesionID: 0, termID: 643, cenario: 0, posicao: 0, linha: 0, term: '[Medida lobo direito]' },
    { value: '', selected: false, lesionID: 0, termID: 644, cenario: 0, posicao: 0, linha: 0, term: '[Medida lobo esquerdo]' },
    { value: '', selected: false, lesionID: 0, termID: 645, cenario: 0, posicao: 0, linha: 0, term: '[Medida istmo]' },
    { value: '', selected: false, lesionID: 0, termID: 646, cenario: 0, posicao: 0, linha: 0, term: '[Volume total]' },
    { value: '', selected: false, lesionID: 642, termID: 647, cenario: 0, posicao: 0, linha: 0, term: '[Dimensões]' },
    { value: '', selected: false, lesionID: 642, termID: 648, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]' },
    { value: '', selected: false, lesionID: 0, termID: 653, cenario: 0, posicao: 0, linha: 0, term: '[Localização]' },
    { value: '', selected: false, lesionID: 0, termID: 654, cenario: 0, posicao: 0, linha: 0, term: '[Medida cisto]' },
    { value: '', selected: false, lesionID: 656, termID: 657, cenario: 0, posicao: 0, linha: 0, term: '[Composição]' },
    { value: '', selected: false, lesionID: 656, termID: 658, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]' },
    { value: '', selected: false, lesionID: 656, termID: 659, cenario: 0, posicao: 0, linha: 0, term: '[Formato]' },
    { value: '', selected: false, lesionID: 656, termID: 660, cenario: 0, posicao: 0, linha: 0, term: '[Margens]' },
    { value: '', selected: false, lesionID: 656, termID: 661, cenario: 0, posicao: 0, linha: 0, term: '[Focos ecogênicos]' },
    { value: '', selected: false, lesionID: 656, termID: 662, cenario: 0, posicao: 0, linha: 0, term: '[Localização]' },
    { value: '', selected: false, lesionID: 656, termID: 663, cenario: 0, posicao: 0, linha: 0, term: '[Medida nódulo]' }
  ];

  listaValorTirads: Tirads[] = [];
  listaValorTiradsNaoSem: Tirads[] = [];
  listaValorTiradsSem: Tirads[] = [];
  countNaoSem = 0;
  countSem = 0;

  Subtitulo: string;
  subtituloBloco: string;
  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  dinamicLesions: TermosGenericos[] = [];
  dinamicLesion: any;
  lesionRow = 1;
  medicaoNodulo = '';
  cabecalhoTexto = '';
  indicacaoTexto = '';
  achadosAdTexto = '';
  rodapeTexto = '';

  tireoide = true;
  submandibularesDireita = false;
  submandibularesEsquerda = false;
  paraditasDireita = false;
  paraditasEsquerda = false;
  linfonodomegalia = false;
  cabecalho = false;
  indicacao = false;
  achadosTexto = false;
  rodape = false;
  editLaudoMode: boolean = false;

  medicalReportTypeID = 4;
  tireoideID = 31;
  submandibularesDireitaID = 30;
  submandibularesEsquerdaID = 32;
  paraditasDireitaID = 28;
  paraditasEsquerdaID = 29;
  linfonodomegaliaID = 27;

  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  public iscisto: Boolean;
  tabelaReferencia = '';
  public showForm: Boolean = true;
  public switchFormReport = true;
  unidadeMedida: string;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  tabelaReferenciaAcr: string;


  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _toastr: ToastrManager,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private dialog: MatDialog,
    private _common: CommonService,
    private _copyPaste: CopyPaste,
    private _pouchService: PouchDBService,
    public _addText: AddText,
    private _config: ConfigLaudoService,
    private dataService:DataTermService) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("cervicalAnalise", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Tireoide';
    this.subtituloBloco= 'Tireoide';

    const listBlocks = [this.tireoideID,
    this.submandibularesDireitaID,
    this.submandibularesEsquerdaID,
    this.paraditasDireitaID,
    this.linfonodomegaliaID,
    this.paraditasEsquerdaID];

    this._pouchService.loadMask(this.medicalReportTypeID, "cervicalMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks,"cervical");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-cervical');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-cervical');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  public resetaDialog() {
    this._common.invokeEvent.subscribe(event => {
      if (event) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo() {
    this._pouchService.getAll("cervicalAnalise").then((obj) => {
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.descricoes[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.showForm = false;
    this.tabelaReferencia = "";
    this.tabelaReferenciaAcr = "";
    this.achadosAdTexto = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo:'Preencha o campo indicação',
      selected: false
    },
    {
      titulo: 'Tireoide',
      id: this.tireoideID,
      name: 'tireoide',
      selected: true,
    },
    {
      titulo: 'Submandibular direita',
      id: this.submandibularesDireitaID,
      name: 'submandibulares-direita',
      selected: false
    },
    {
      titulo: 'Submandibular esquerda',
      id: this.submandibularesEsquerdaID,
      name: 'submandibulares-esquerda',
      selected: false
    },
    {
      titulo: 'Parótida direita',
      id: this.paraditasDireitaID,
      name: 'paroditas-direita',
      selected: false
    },
    {
      titulo: 'Parótida esquerda',
      id: this.paraditasEsquerdaID,
      name: 'paroditas-esquerda',
      selected: false
    },
    {
      titulo: 'Linfonodomegalias',
      id: this.linfonodomegaliaID,
      name: 'linfonodomegalias',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados-adicionais',
      name: 'achados-adicionais',
      subtitulo:'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }

    this.cabecalho =  (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.achadosTexto = (itemSelecionado.id === 'achados-adicionais');
    this.rodape =  (itemSelecionado.id === 'rodape');
    this.tireoide =  (itemSelecionado.id === this.tireoideID)
    this.submandibularesDireita = (itemSelecionado.id === this.submandibularesDireitaID)
    this.submandibularesEsquerda = (itemSelecionado.id === this.submandibularesEsquerdaID)
    this.paraditasDireita = (itemSelecionado.id === this.paraditasDireitaID)
    this.paraditasEsquerda = (itemSelecionado.id === this.paraditasEsquerdaID)
    this.linfonodomegalia = (itemSelecionado.id === this.linfonodomegaliaID)

    this.AdicionaTexto(this.newEditor);
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;
    this.dinamicLesions = [];
    this.lesionRow = 1;
    this.medicaoNodulo = '';
    this.listaValorTirads = [];
    this.listaValorTiradsNaoSem = [];
    this.listaValorTiradsSem = [];
    this.countNaoSem = 0;
    this.countSem = 0;
    this.iscisto = false;


    this.genericTermsParoditasDireita = [
      { value: '', selected: false, lesionID: 577, termID: 578, cenario: 0, posicao: 0, linha: 0, term: '[Dimensões]' },
      { value: '', selected: false, lesionID: 577, termID: 579, cenario: 0, posicao: 0, linha: 0, term: '[Ecotextura]' },
      { value: '', selected: false, lesionID: 577, termID: 581, cenario: 0, posicao: 0, linha: 0, term: '[Medida linfonodo]' },
      { value: '', selected: false, lesionID: 582, termID: 583, cenario: 0, posicao: 0, linha: 0, term: '[Composição]' },
      { value: '', selected: false, lesionID: 582, termID: 584, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]' },
      { value: '', selected: false, lesionID: 582, termID: 585, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]' },
      { value: '', selected: false, lesionID: 582, termID: 586, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
      { value: '', selected: false, lesionID: 587, termID: 605, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
    ];

    this.genericTermsParoditasEsquerda = [
      { value: '', selected: false, lesionID: 592, termID: 593, cenario: 0, posicao: 0, linha: 0, term: '[Dimensões]' },
      { value: '', selected: false, lesionID: 592, termID: 594, cenario: 0, posicao: 0, linha: 0, term: '[Ecotextura]' },
      { value: '', selected: false, lesionID: 592, termID: 596, cenario: 0, posicao: 0, linha: 0, term: '[Medida linfonodo]' },
      { value: '', selected: false, lesionID: 597, termID: 598, cenario: 0, posicao: 0, linha: 0, term: '[Composição]' },
      { value: '', selected: false, lesionID: 597, termID: 599, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]' },
      { value: '', selected: false, lesionID: 597, termID: 600, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]' },
      { value: '', selected: false, lesionID: 597, termID: 601, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
      { value: '', selected: false, lesionID: 602, termID: 606, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
    ];

    this.genericTermsSubmandibularesDireita = [
      { value: '', selected: false, lesionID: 609, termID: 610, cenario: 0, posicao: 0, linha: 0, term: '[Dimensões]' },
      { value: '', selected: false, lesionID: 609, termID: 611, cenario: 0, posicao: 0, linha: 0, term: '[Ecotextura]' },
      { value: '', selected: false, lesionID: 612, termID: 613, cenario: 0, posicao: 0, linha: 0, term: '[Composição]' },
      { value: '', selected: false, lesionID: 612, termID: 614, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]' },
      { value: '', selected: false, lesionID: 612, termID: 615, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]' },
      { value: '', selected: false, lesionID: 612, termID: 616, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
      { value: '', selected: false, lesionID: 617, termID: 620, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
    ];

    this.genericTermsSubmandibularesEsquerda = [
      { value: '', selected: false, lesionID: 623, termID: 624, cenario: 0, posicao: 0, linha: 0, term: '[Dimensões]' },
      { value: '', selected: false, lesionID: 623, termID: 625, cenario: 0, posicao: 0, linha: 0, term: '[Ecotextura]' },
      { value: '', selected: false, lesionID: 626, termID: 627, cenario: 0, posicao: 0, linha: 0, term: '[Composição]' },
      { value: '', selected: false, lesionID: 626, termID: 628, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]' },
      { value: '', selected: false, lesionID: 626, termID: 629, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]' },
      { value: '', selected: false, lesionID: 626, termID: 630, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
      { value: '', selected: false, lesionID: 631, termID: 634, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
    ];

    this.genericTermsLinfonodomegalia = [
      { value: '', selected: false, lesionID: 0, termID: 566, cenario: 0, posicao: 0, linha: 0, term: '[Localização]' },
      { value: '', selected: false, lesionID: 0, termID: 567, cenario: 0, posicao: 0, linha: 0, term: '[Lateralidade]' },
      { value: '', selected: false, lesionID: 0, termID: 568, cenario: 0, posicao: 0, linha: 0, term: '[Morfologia]' },
      { value: '', selected: false, lesionID: 0, termID: 569, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
      { value: '', selected: false, lesionID: 570, termID: 573, cenario: 0, posicao: 0, linha: 0, term: '[Morfologia]' },
      { value: '', selected: false, lesionID: 570, termID: 574, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' }
    ];

    this.genericTermsTireoide = [
      { value: '', selected: false, lesionID: 635, termID: 640, cenario: 0, posicao: 0, linha: 0, term: '[Medida lobo]' },
      { value: '', selected: false, lesionID: 0, termID: 643, cenario: 0, posicao: 0, linha: 0, term: '[Medida lobo direito]' },
      { value: '', selected: false, lesionID: 0, termID: 644, cenario: 0, posicao: 0, linha: 0, term: '[Medida lobo esquerdo]' },
      { value: '', selected: false, lesionID: 0, termID: 645, cenario: 0, posicao: 0, linha: 0, term: '[Medida istmo]' },
      { value: '', selected: false, lesionID: 0, termID: 646, cenario: 0, posicao: 0, linha: 0, term: '[Volume total]' },
      { value: '', selected: false, lesionID: 642, termID: 647, cenario: 0, posicao: 0, linha: 0, term: '[Dimensões]' },
      { value: '', selected: false, lesionID: 642, termID: 648, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]' },
      { value: '', selected: false, lesionID: 0, termID: 653, cenario: 0, posicao: 0, linha: 0, term: '[Localização]' },
      { value: '', selected: false, lesionID: 0, termID: 654, cenario: 0, posicao: 0, linha: 0, term: '[Medida cisto]' },
      { value: '', selected: false, lesionID: 656, termID: 657, cenario: 0, posicao: 0, linha: 0, term: '[Composição]' },
      { value: '', selected: false, lesionID: 656, termID: 658, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]' },
      { value: '', selected: false, lesionID: 656, termID: 659, cenario: 0, posicao: 0, linha: 0, term: '[Formato]' },
      { value: '', selected: false, lesionID: 656, termID: 660, cenario: 0, posicao: 0, linha: 0, term: '[Margens]' },
      { value: '', selected: false, lesionID: 656, termID: 661, cenario: 0, posicao: 0, linha: 0, term: '[Focos ecogênicos]' },
      { value: '', selected: false, lesionID: 656, termID: 662, cenario: 0, posicao: 0, linha: 0, term: '[Localização]' },
      { value: '', selected: false, lesionID: 656, termID: 663, cenario: 0, posicao: 0, linha: 0, term: '[Medida nódulo]' },
      { value: '', selected: false, lesionID: 672, termID: 675, cenario: 0, posicao: 0, linha: 0, term: '[Formato]' },
      { value: '', selected: false, lesionID: 672, termID: 676, cenario: 0, posicao: 0, linha: 0, term: '[Margens]' },
      { value: '', selected: false, lesionID: 672, termID: 677, cenario: 0, posicao: 0, linha: 0, term: '[Focos ecogênicos]' }
    ];

  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados_adicionais',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  incluirLesoesDinamicas($event) {
    let textoSplit;
    let lesoesValue;
    let count = 0;

    // Inicia a contagem de dois ou mais nodulos - Tirads
    // Verifica se é nod semelhante
    if (+$event.elemento.id < 673) {
      // Verifica se é um novo nodulo
      if (+$event.elemento.id === 665) {
        this.countNaoSem++;
        count = this.countNaoSem;
      } else {
        count = this.countNaoSem;
      }
    } else {
      if (+$event.elemento.id === 673) {
        this.countSem++;
        count = this.countSem;
      } else {
        count = this.countSem;
      }
    }

    // Lógica para adicionar ao array de Tirads
    // Verifica se é o mat-select de focos ecogenicos
    if (Array.isArray($event.elemento.value)) {
      $event.elemento.value.forEach(objArr => {
        textoSplit = objArr.split('|', 2);
        if (lesoesValue) {
          lesoesValue = lesoesValue + ', ' + textoSplit[0];
        } else {
          lesoesValue = textoSplit[0];
        }
        if (textoSplit[1]) {
          const objTirads = { id: $event.elemento.id, value: textoSplit[0], tiradsValue: textoSplit[1], count: count };
          if (+$event.elemento.id < 673) {
            this.listaValorTiradsNaoSem.push(objTirads);
          } else {
            this.listaValorTiradsSem.push(objTirads);
          }
        }
      });
    } else {
      if ($event.elemento.value !== undefined) {
        textoSplit = $event.elemento.value.split('|', 2);
        lesoesValue = this._validacoes.formataDecimal(textoSplit[0]);

        if (textoSplit[1]) {
          const objTirads = { id: $event.elemento.id, value: textoSplit[0], tiradsValue: textoSplit[1], count: count };
          if (+$event.elemento.id < 673) {
            this.listaValorTiradsNaoSem.push(objTirads);
          } else {
            this.listaValorTiradsSem.push(objTirads);
          }
        }
      }
    }

    if ($event.elemento.id == 671 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 671,
          lesionID: 664,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medida nódulo]',
          termView: 'inMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 671;
            obj.lesionID = 664;
            obj.cenario = $event.elemento.cenario,
              obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medida nódulo]';
          }
        });
      }
    } else if ($event.elemento.id == 679 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 679,
          lesionID: 672,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medida nódulo sem]',
          termView: 'inMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 679;
            obj.lesionID = 672;
            obj.cenario = $event.elemento.cenario,
              obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medida nódulo sem]';
          }
        });
      }
    } else if ($event.elemento.id == 574 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 574,
          lesionID: 570,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medida]',
          termView: 'inMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 574;
            obj.lesionID = 570;
            obj.cenario = $event.elemento.cenario,
              obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medida]';
          }
        });
      }
    }

    if ($event.linha == 1 && ($event.posicao == 0 || $event.posicao == 1)) {
      this.genericTermsLinfonodomegalia.forEach(obj => {
        if (obj.termID == $event.elemento.id) {
          obj.selected = true;
          obj.value = lesoesValue;
        }
      });
      this.genericTermsTireoide.forEach(obj => {
        if (obj.termID == $event.elemento.id) {
          obj.selected = true;
          obj.value = lesoesValue;
        }
      });
      if ($event.isPrimeiraLesao == true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaLesoes($event.elemento);
      }
    }

    switch ($event.elemento.id) {
      // Linfonodomegalia
      case '571':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 571,
          lesionID: 570,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '572':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 572,
          lesionID: 570,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Lateralidade]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // Nódulo sem
      case '665':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 665,
          lesionID: 664,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Composição]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '666':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 666,
          lesionID: 664,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '667':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 667,
          lesionID: 664,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Formato]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '668':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 668,
          lesionID: 664,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Margens]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '669':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 669,
          lesionID: 664,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Focos ecogênicos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '670':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 670,
          lesionID: 664,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // Nódulo sem
      case '673':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 673,
          lesionID: 672,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Composição]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '674':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 674,
          lesionID: 672,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '678':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 678,
          lesionID: 672,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }

    this.lesionRow = $event.linha;
  }

  adicionaSelecaoParoditasDireita($event) {
    this.genericTermsParoditasDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoParoditasEsquerda($event) {
    this.genericTermsParoditasEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoSubmandibularesDireita($event) {
    this.genericTermsSubmandibularesDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoSubmandibularesEsquerda($event) {
    this.genericTermsSubmandibularesEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoLinfonodomegalia($event) {
    this.genericTermsLinfonodomegalia.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoTireoide($event) {
    let value;
    let textoSplit;

    // Lógica para adicionar ao array de Tirads
    // e pegar o valor das opções selecionadas
    this.genericTermsTireoide.forEach(obj => {
      if (obj.termID == $event.elemento.id) {

        // Verifica se é o mat-select de focos ecogenicos
        if (Array.isArray($event.elemento.value)) {
          $event.elemento.value.forEach(objArr => {
            textoSplit = objArr.split('|', 2);
            if (value) {
              value = value + ', ' + textoSplit[0];
            } else {
              value = textoSplit[0];
            }
            if (textoSplit[1]) {
              const objTirads = { id: $event.elemento.id, value: textoSplit[0], tiradsValue: textoSplit[1], count: 0 };
              if (+$event.elemento.id < 673) {
                this.listaValorTirads.push(objTirads);
              } else {
                this.listaValorTiradsSem.push(objTirads);
              }
            }
          });
        } else {
          textoSplit = $event.elemento.value.split('|', 2);
          value = this._validacoes.formataDecimal(textoSplit[0]);
          if (textoSplit[1]) {
            const objTirads = { id: $event.elemento.id, value: textoSplit[0], tiradsValue: textoSplit[1], count: 0 };
            if (+$event.elemento.id < 673) {
              this.listaValorTirads.push(objTirads);
            } else {
              this.listaValorTiradsSem.push(objTirads);
            }
          }
        }

        obj.selected = true;
        obj.value = this._validacoes.formataDecimal(value);
      }
    });

    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if (+$event.elemento.id === 643) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 1 };
        this.enviaLesoes(this.objetoGrupoLesao);
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
        this.enviaLesoes(this.objetoGrupoLesao);
      }

    }
    if ($event.iscisto !== undefined && $event.ckCisto.checked == false) {
      this.iscisto = true;
    } else {
      this.iscisto = false;
    }


  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
    let desc;
    if (this.paraditasDireita) {
      this.term.blockID = this.paraditasDireitaID;

      this.term.injuries.push(this.injuries);
      this.cenarioCorrente++;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.paraditasDireitaID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoParoditasDireita(resultado.text, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.paraditasDireitaID);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            //this.AdicionaTexto(this.newEditor);
          }
        });

        let obj = {
          value: '',
          blockID: this.paraditasDireitaID,
          textTermID: 0
        };
        let espaco = '';
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.blockID = this.paraditasDireitaID;
            obj.value = obj.value + espaco + resultado.conclusion;
            espaco = '</br>';
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.paraditasDireitaID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.paraditasDireitaID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        //this.AdicionaTexto(this.newEditor);
        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.paraditasDireitaID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });

    } else if (this.paraditasEsquerda) {
      this.term.blockID = this.paraditasEsquerdaID;

      this.term.injuries.push(this.injuries);
      this.cenarioCorrente++;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.paraditasEsquerdaID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoParoditasEsquerda(resultado.text, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.paraditasEsquerdaID);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            //this.AdicionaTexto(this.newEditor);
          }
        });

        let obj = {
          value: '',
          blockID: this.paraditasEsquerdaID,
          textTermID: 0
        };
        let espaco = '';
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.blockID = this.paraditasEsquerdaID;
            obj.value = obj.value + espaco + resultado.conclusion;
            espaco = '</br>';
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.paraditasEsquerdaID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.paraditasEsquerdaID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        //this.AdicionaTexto(this.newEditor);
        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.paraditasEsquerdaID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });

    } else if (this.submandibularesDireita) {
      this.term.blockID = this.submandibularesDireitaID;

      this.term.injuries.push(this.injuries);
      this.cenarioCorrente++;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.submandibularesDireitaID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoSubmandibularesDireita(resultado.text, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.submandibularesDireitaID);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            //this.AdicionaTexto(this.newEditor);
          }
        });

        let obj = {
          value: '',
          blockID: this.submandibularesDireitaID,
          textTermID: 0
        };
        let espaco = '';
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.blockID = this.submandibularesDireitaID;
            obj.value = obj.value + espaco + resultado.conclusion;
            espaco = '</br>';
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.submandibularesDireitaID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.submandibularesDireitaID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        //this.AdicionaTexto(this.newEditor);
        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.submandibularesDireitaID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });

    } else if (this.submandibularesEsquerda) {
      this.term.blockID = this.submandibularesEsquerdaID;

      this.term.injuries.push(this.injuries);
      this.cenarioCorrente++;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.submandibularesEsquerdaID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoSubmandibularesEsquerda(resultado.text, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.submandibularesEsquerdaID);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            //this.AdicionaTexto(this.newEditor);
          }
        });

        let obj = {
          value: '',
          blockID: this.submandibularesEsquerdaID,
          textTermID: 0
        };
        let espaco = '';
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.blockID = this.submandibularesEsquerdaID;
            obj.value = obj.value + espaco + resultado.conclusion;
            espaco = '</br>';
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.submandibularesEsquerdaID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.submandibularesEsquerdaID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        //this.AdicionaTexto(this.newEditor);
        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.submandibularesEsquerdaID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });

    } else if (this.linfonodomegalia) {
      this.term.blockID = this.linfonodomegaliaID;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.linfonodomegaliaID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoLinfonodomegalia(resultado.text, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.linfonodomegaliaID);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            //this.AdicionaTexto(this.newEditor);
          }
        });

        let obj = {
          value: '',
          blockID: this.linfonodomegaliaID,
          textTermID: 0
        };
        let espaco = '';
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclusaoLinfonodomegalia(resultado.conclusion);
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.linfonodomegaliaID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.linfonodomegaliaID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        //this.AdicionaTexto(this.newEditor);
        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.linfonodomegaliaID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });

    } else if (this.tireoide) {
      this.term.blockID = this.tireoideID;

      this.term.injuries.push(this.injuries);
      this.cenarioCorrente++;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.tireoideID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoTireoide(resultado.text, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.tireoideID);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            //this.AdicionaTexto(this.newEditor);
          }
        });

        let obj = {
          value: '',
          blockID: this.tireoideID,
          textTermID: 0
        };
        let espaco = '';

        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            if (obj.value !== resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoTireoide(resultado.conclusion);
              espaco = '</br>';
            }
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.tireoideID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tireoideID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        //this.AdicionaTexto(this.newEditor);
        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.tireoideID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
      }
    }
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
			} else if(indicacao || achados) {
				if(indicacao === undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados === undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }


  MontaDescricaoParoditasDireita(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 578 || +term === 579 || +term === 581 || +term === 583 || +term === 584
          || +term === 585 || +term === 586 || +term === 605) {
          this.genericTermsParoditasDireita.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 605) {
          this.genericTermsParoditasDireita.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoParoditasEsquerda(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 593 || +term === 594 || +term === 596 || +term === 598 ||
          +term === 599 || +term === 600 || +term === 601 || +term === 606) {
          this.genericTermsParoditasEsquerda.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 606) {
          this.genericTermsParoditasEsquerda.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoSubmandibularesDireita(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 610 || +term === 611 || +term === 613 || +term === 614 ||
          +term === 615 || +term === 616 || +term === 620) {
          this.genericTermsSubmandibularesDireita.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 620) {
          this.genericTermsSubmandibularesDireita.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoSubmandibularesEsquerda(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 624 || +term === 625 || +term === 627 || +term === 628 ||
          +term === 629 || +term === 630 || +term === 634) {
          this.genericTermsSubmandibularesEsquerda.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 634) {
          this.genericTermsSubmandibularesEsquerda.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoLinfonodomegalia(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoLinfonodomegaliaDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoLinfonodomegaliaDescNormal(texto, contadorTexto);
    }
    return texto;
  }

  TextoLinfonodomegaliaDescNormal(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 566 || +term === 567 || +term === 568 || +term === 569) {
        this.genericTermsLinfonodomegalia.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  TextoLinfonodomegaliaDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
    }

    // Lista term
    this.term.listTermID.forEach(term => {
      if (+term === 573 || +term === 574) {
        this.genericTermsLinfonodomegalia.forEach(generic => {
          if (+generic.termID === +term) {
            textoRetorno = textoRetorno.replace(generic.term, generic.value);
          }
        });
      }
    });

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID == 571 || item.termID == 572 || item.termID == 574) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
          }

          if (item.termID == 571 || item.termID == 572 || item.termID == 574) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });

    return texto;
  }

  MontaConclusaoLinfonodomegalia(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 566 || +term === 567 || +term === 568 || +term === 569) {
        this.genericTermsLinfonodomegalia.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoTireoide(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoTireoideliaDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoTireoideDescNormal(texto, contadorTexto);
    }
    return texto;
  }

  TextoTireoideDescNormal(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 640 || +term === 643 || +term === 644 || +term === 645 ||
          +term === 646 || +term === 647 || +term === 648 || +term === 645 ||
          +term === 653 || +term === 654 || +term === 657 || +term === 658 ||
          +term === 659 || +term === 660 || +term === 661 || +term === 662 ||
          +term === 663) {
          this.genericTermsTireoide.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 643 || +term === 644 || +term === 645 || +term === 646 ||
          +term === 657 || +term === 658 || +term === 659 || +term === 660 ||
          +term === 661 || +term === 662 || +term === 663 || +term === 653 || +term === 654) {
          this.genericTermsTireoide.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    // TODO - Lógica temporária, remover daqui posteriormente
    // Calcula Tirads caso seja um nodulo
    let valorTirads = 0;
    let tirads = 0;
    if (this.listaValorTirads.length > 0) {
      this.listaValorTirads.forEach(x => {
        valorTirads = +x.tiradsValue + valorTirads;
        if (valorTirads === 0) {
          tirads = 1;
        } else if (valorTirads === 2 || valorTirads === 1) {
          tirads = 2;
        } else if (valorTirads === 3) {
          tirads = 3;
        } else if (valorTirads >= 4 && valorTirads <= 6) {
          tirads = 4;
        } else {
          tirads = 5;
        }
      });

      textoRetorno = texto.replace('[X]', tirads);
      texto = textoRetorno;
    }

    return texto;
  }

  TextoTireoideliaDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
    }


    // Lista term
    this.term.listTermID.forEach(term => {
      if (+term === 640 || +term === 643 || +term === 644 || +term === 645 ||
        +term === 646 || +term === 647 || +term === 648 || +term === 645 ||
        +term === 653 || +term === 654 || +term === 657 || +term === 658 ||
        +term === 659 || +term === 660 || +term === 661 || +term === 662 ||
        +term === 663 || +term === 675 || +term === 676 || +term === 677) {
        this.genericTermsTireoide.forEach(generic => {
          if (+generic.termID === +term) {
            textoRetorno = textoRetorno.replace(generic.term, generic.value);
          }
        });
      }
    });
    // Injuries
    if (this.term.injuries[0].listInjuries.length > 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 665 || +term === 666 || +term === 667 || +term === 668 ||
          +term === 669 || +term === 670 || +term === 671 || +term === 673 ||
          +term === 674 || +term === 675 || +term === 676 || +term === 677 ||
          +term === 678 || +term === 679 || +term === 675 || +term === 676 ||
          +term === 677) {
          this.genericTermsTireoide.forEach(generic => {
            if (+generic.termID === +term) {
              textoRetorno = textoRetorno.replace(generic.term, generic.value);
            }
          });
        }
      });
    }

    // Lesões dinamicas
    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID == 665 || item.termID == 666 || item.termID == 667 || item.termID == 668 ||
            item.termID == 669 || item.termID == 670 || item.termID == 671 || item.termID == 673 ||
            item.termID == 674 || item.termID == 675 || item.termID == 676 || item.termID == 677 ||
            item.termID == 678 || item.termID == 679) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }

        } else {

          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
          }
          if (item.lesionID === 664 && this.iscisto) {
            textoDinamico = '</br>' + textoDinamico;
          }

          if (item.termID == 665 || item.termID == 666 || item.termID == 667 || item.termID == 668 ||
            item.termID == 669 || item.termID == 670 || item.termID == 671 || item.termID == 673 ||
            item.termID == 674 || item.termID == 675 || item.termID == 676 || item.termID == 677 ||
            item.termID == 678 || item.termID == 679) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }

        // Atribui valor Tirads
        const tirads = this.CalculaTirads(lesaoID, linha);
        textoDinamico = textoDinamico.replace('[X]', tirads.toString());
      }
    });

    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;



    this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });
    return texto;
  }

  MontaConclusaoTireoide(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 566 || +term === 567 || +term === 568 || +term === 569
        || +term === 665 || +term === 676 || +term === 657) {
        this.genericTermsTireoide.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  CalculaTirads(lesaoID, linha) {
    // Calcula Tirads caso seja dois ou mais nodulo
    let valorTirads = 0;
    let tirads = 0;
    if (lesaoID === 664 && this.listaValorTiradsNaoSem.length > 0) {
      valorTirads = 0;
      const listaNaoSem = this.listaValorTiradsNaoSem.filter(x => x.count === linha);
      listaNaoSem.forEach(x => {
        valorTirads = +x.tiradsValue + valorTirads;
        if (valorTirads === 0) {
          tirads = 1;
        } else if (valorTirads === 2 || valorTirads === 1) {
          tirads = 2;
        } else if (valorTirads === 3) {
          tirads = 3;
        } else if (valorTirads >= 4 && valorTirads <= 6) {
          tirads = 4;
        } else {
          tirads = 5;
        }

      });
      // Passa o Tirads
      return tirads;
    }
    if (lesaoID === 672 && this.listaValorTiradsSem.length > 0) {
      valorTirads = 0;
      const listaSem = this.listaValorTiradsSem.filter(x => x.count === linha);
      listaSem.forEach(x => {
        valorTirads = +x.tiradsValue + valorTirads;
        if (valorTirads === 0) {
          tirads = 1;
        } else if (valorTirads === 2 || valorTirads === 1) {
          tirads = 2;
        } else if (valorTirads === 3) {
          tirads = 3;
        } else if (valorTirads >= 4 && valorTirads <= 6) {
          tirads = 4;
        } else {
          tirads = 5;
        }

        if (+x.id === 674) {
          // POG: Mudar isso e criar lógica melhor. Ass: Gabs
          const formato = +this.listaValorTiradsSem[0].tiradsValue;
          const margem = +this.listaValorTiradsSem[1].tiradsValue;

          // Calcula focos ecogenicos
          let somaFocos = 0;
          const focoEcogenicos = this.listaValorTiradsSem.filter(y => +y.id === 677);
          focoEcogenicos.forEach(focosEco => {
            somaFocos = +focosEco.tiradsValue + somaFocos;
          });

          valorTirads = formato + margem + somaFocos;
        }
      });
      return tirads;
    }
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor(){
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao, index) => {
      if (conclusao.value && this.descricoes[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    if (this.tabelaReferencia) {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-achados`,
          'name': ''
        },
        'data': {
          'text': `<p>${this.tabelaReferencia}</p>`,
        }
      });
    }

    if(this.tabelaReferenciaAcr){
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-tabela`,
          'name': ''
        },
        'data': {
          'text': `<p>${this.tabelaReferenciaAcr}</p>`,
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

  }
  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|')

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.indicacaoTexto;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContent += maskSplit[1];
    for (i = 0; i < this.descricoes.length; i++) {
      var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value) {
        this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
      }
    }
    this.htmlContent += maskSplit[2];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }
    // if (this.conclusoes[1].value !== '') {
    //   this.htmlContent += 'ACR TI-RADS 2017: ' + this.tirads.toString() + '.';
    // }
    this.htmlContent += this.achadosAdTexto;

    if (this.tabelaReferencia) {
      this.htmlContent += this.tabelaReferencia;
    }

    if(this.tabelaReferenciaAcr){
      this.htmlContent += this.tabelaReferenciaAcr;
    }

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    // if (this.conclusoes.length <= 1) {
    texto = 'Estudo ultrassonográfico dentro dos padrões da normalidade.';
    // }
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      ReportCode: this.inCodLaudo.nativeElement.value,
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }
  escreveReferenciaAcr($event) {
    if ($event == null) {
      this.tabelaReferenciaAcr = '';
    }else {
      if ($event) {
        this.tabelaReferenciaAcr = this.criarTabelaAcr();
      }
    }
    this.AdicionaTexto(this.newEditor);
  }

  escreveReferencia($event) {
    if ($event == null) {
      this.tabelaReferencia = '';
    }
    else {
      if ($event) {
        this.tabelaReferencia = this.tabelaReferenciaPuncao();
      }
    }
    this.AdicionaTexto(this.newEditor);
  }

  criarTabelaAcr(): string{
    let strTable = '</br><style>' +
    'table {' +
    'width: 95%;' +
    '}' +
    'table, th, td {' +
    'border: 1px solid #c2c0c2;' +
    'border-collapse: collapse;'+
    '}' +
    'tr {' +
    ' text-align: center;}' +
    'th, td {' +
    '  padding: 5px;' +
    '  text-align: left;' +
    '}' +
    '.dflex{display:flex;justify-content: space-between;gap: 10px;font-size:11px;}'+
    '.align-center{text-align:center;}'+
    '</style>' +
    '<br><br>' +
    '<table>' +
    '<tr>'+
    ' <th colspan="5" class="align-center"><b>SISTEMA DE PONTOS ACR-TIRADS</b></th>'+
    '</tr>';
    strTable +=
        '<tr>' +
        '   <th>COMPOSIÇÃO</th>'+
        '   <th>ECOGENICIDADE</th>'+
        '   <th>FORMA</th>'+
        '   <th>MARGEM</th>'+
        '   <th>FOCO</th>'+
        '</tr>';
      strTable +=
        '<tr>' +
          '<td><div class="dflex"><div>Cística ou quase completamente cística</div><div>0</div></div></td>' +
          '<td><div class="dflex"><div>Anecóica</div><div> 0</div></div></td>' +
          '<td><div class="dflex"><div>Mais largo do que alto</div><div>0</div></div></td>' +
          '<td><div class="dflex"><div>Regular</div><div>0</div></div></td>' +
          '<td><div class="dflex"><div>Nenhum ou artefatos com "cauda de cometa"</div><div>0</div></div></td>' +
        '</tr>';
        strTable +=
        '<tr>' +
          '<td><div class="dflex"><div>Espongiforme</div><div>0</div></div></td>' +
          '<td><div class="dflex"><div>Hiperecoica ou isoecoides</div><div>1</div></div></td>' +
          '<td><div class="dflex"><div>Mais alto do que largo</div><div>3</div></div></td>' +
          '<td><div class="dflex"><div>Mal definido</div><div>0</div></div></td>' +
          '<td><div class="dflex"><div>Macrocalcificações</div><div>1</div></div></td>' +
        '</tr>';
        strTable +=
        '<tr>' +
          '<td><div class="dflex"><div>Cística misto e sólido</div><div>1</div></div></td>' +
          '<td><div class="dflex"><div>Hipoecóico</div><div>2</div></div></td>' +
          '<td><div class="dflex"><div></div><div></div></div></td>' +
          '<td><div class="dflex"><div>Lobulada ou Irregular</div><div>2</div></div></td>' +
          '<td><div class="dflex"><div>Periféricos (RIM) calcificações</div><div>2</div></div></td>' +
        '</tr>';
        strTable +=
        '<tr>' +
          '<td><div class="dflex"><div>Sólido ou quase completamente sólido</div><div>2</div></div></td>' +
          '<td><div class="dflex"><div>Muito Hipoecóico</div><div>3</div></div></td>' +
          '<td><div class="dflex"><div></div><div></div></div></td>' +
          '<td><div class="dflex"><div>Extensão extra-tireoidiana</div><div>3</div></div></td>' +
          '<td><div class="dflex"><div>Focos ecogênicos pontiformes</div><div>3</div></div></td>' +
        '</tr>';
    strTable += '</table>';
    strTable += '</br><style>' +
    'table {' +
    'width: 95%;' +
    '}' +
    'table, th, td {' +
    'border: 1px solid #c2c0c2;' +
    'border-collapse: collapse;'+
    '}' +
    'tr {' +
    ' text-align: center;}' +
    'th, td {' +
    '  padding: 5px;' +
    '  text-align: left;' +
    '}' +
    '.fs{font-size:11px;}'+
    '.align-center{text-align:center;}'+
    '</style>' +
    '<br><br>' +
    '<table>';
    strTable +=
        '<tr>' +
        '   <th class="fs">0 pontos</th>'+
        '   <th class="fs">2 pontos</th>'+
        '   <th class="fs">3 pontos</th>'+
        '   <th class="fs">4-6 pontos</th>'+
        '   <th class="fs">7 ou mais pontos</th>'+
        '</tr>';
      strTable +=
        '<tr>' +
          '<td ><b>TI-RADS 1</b></td>' +
          '<td ><b>TI-RADS 2</b></td>' +
          '<td ><b>TI-RADS 3</b></td>' +
          '<td ><b>TI-RADS 4</b></td>' +
          '<td ><b>TI-RADS 5</b></td>' +
        '</tr>';
        strTable +=
        '<tr>' +
          '<td class="fs">Achados</td>' +
          '<td class="fs">Achados</td>' +
          '<td class="fs">Achados</td>' +
          '<td class="fs">Achados</td>' +
          '<td class="fs">Achados</td>' +
          '</tr>';
        strTable +=
        '<tr>' +
        '<td class="fs">Benignos</td>' +
        '<td class="fs">Insuspeitos</td>' +
        '<td class="fs">Discretamente</td>' +
        '<td class="fs">Moderadamente</td>' +
        '<td class="fs">Altamente</td>' +
        '</tr>';
        strTable +=
        '<tr>' +
        '<td></td>' +
        '<td class="fs">Suspeitos</td>' +
        '<td class="fs">Suspeitos</td>' +
        '<td class="fs">Suspeitos</td>' +
        '<td class="fs">Suspeitos</td>' +
        '</tr>';
    strTable += '</table>';
    return strTable;
  }

  tabelaReferenciaPuncao(): string {

    let strTable = '</br><style>' +
      'table {' +
      'width: 80%;' +
      '}' +
      'table, th, td {' +
      'border: 1px solid #c2c0c2;' +
      'border-collapse: collapse;' +
      'tr {' +
      ' text-align: center;' +
      '}' +
      'th, td {' +
      '  padding: 5px;' +
      '  text-align: left;' +
      '}' +
      '.align-center{text-align:center;}'+
      '</style>' +
      '<br><br>' +
      '<table>' +
      '  <tr>' +
      '    <th class="align-center"><b>RECOMENDAÇÕES ACR-TIRADS 2017</b></th>' +
      '  </tr>';
    strTable += '  <tr>' +
      '    <td>TIRADS 1 - Não há necessidade de PAAF ou controle ultrassonográfico.</td>' +
      '  </tr>';
    strTable += '  <tr>' +
      '    <td>TIRADS 2 - Não há necessidade de PAAF ou controle ultrassonográfico.</td>' +
      '  </tr>';
    strTable += '  <tr>' +
      '    <td>TIRADS 3 - PAAF se ≥ 25 mm; 15 - 24 mm controle ultrassonográfico em 1, 2, 3 e 5 anos.</td>' +
      '  </tr>';
    strTable += '  <tr>' +
      '    <td>TIRADS 4 - PAAF se ≥ 15 mm; 10 - 14 mm controle ultrassonográfico em 1, 2, 3 e 5 anos.</td>' +
      '  </tr>';
    strTable += '  <tr>' +
      '    <td>TIRADS 5 - PAAF se ≥ 10 mm; 5 - 9 mm controle ultrassonográfico anual durante 5 anos.</td>' +
      '  </tr>';
    strTable += '</table>';


    return strTable;
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

}
