import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatExpansionModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTabsModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSelectModule,
  MatTooltipModule,
  MatSlideToggleModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from 'src/app/utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { ObsCalculadoraComponent } from '../obs-calculadora/obs-calculadora.component';
import { ObsCalculadoraModule } from '../obs-calculadora/obs-calculadora.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';
import { ObsSegTercGemelarComponent } from './obs-seg-terc-gemelar.component';
import { UteroComponent } from '../transv-seg-terc-trimestre/TermosComponents/utero/utero.component';
import { FetoComponent } from '../transv-seg-terc-trimestre/TermosComponents/feto/feto.component';
import { ParametrosBiometricosComponent } from '../transv-seg-terc-trimestre/TermosComponents/parametros-biometricos/parametros-biometricos.component';
import { OstAnatomiaFetalComponent } from '../transv-seg-terc-trimestre/TermosComponents/ost-anatomia-fetal/ost-anatomia-fetal.component';
import { PlacentaComponent } from '../transv-seg-terc-trimestre/TermosComponents/placenta/placenta.component';
import { CordaoUmbilicalComponent } from '../transv-seg-terc-trimestre/TermosComponents/cordao-umbilical/cordao-umbilical.component';
import { LiquidoAmnioticoComponent } from '../transv-seg-terc-trimestre/TermosComponents/liquido-amniotico/liquido-amniotico.component';
import { TransvSegTercTrimestreModule } from '../transv-seg-terc-trimestre/transv-seg-terc-trimestre.module';
import { FetoGemelarComponent } from './TermosComponents/feto-gemelar/feto-gemelar.component';
import { PlacentaGemelarComponent } from './TermosComponents/placenta-gemelar/placenta-gemelar.component';
import { CordaoUmbilicalGemelarComponent } from './TermosComponents/cordao-umbilical-gemelar/cordao-umbilical-gemelar.component';
import { LiquidoAmnioticoGemelarComponent } from './TermosComponents/liquido-amniotico-gemelar/liquido-amniotico-gemelar.component';
import { BiometriaFetalGemelarComponent } from './TermosComponents/biometria-fetal-gemelar/biometria-fetal-gemelar.component';
import { AnatomiaFetalGemelarComponent } from './TermosComponents/anatomia-fetal-gemelar/anatomia-fetal-gemelar.component';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    ObsCalculadoraModule,
    LaudoFooterModule,
    SharedModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
    TransvSegTercTrimestreModule
  ],
  exports: [
    ObsSegTercGemelarComponent,
    FetoGemelarComponent,
    BiometriaFetalGemelarComponent,
    AnatomiaFetalGemelarComponent,
  ],
  declarations: [
    ObsSegTercGemelarComponent,
    FetoGemelarComponent,
    PlacentaGemelarComponent,
    CordaoUmbilicalGemelarComponent,
    LiquidoAmnioticoGemelarComponent,
    BiometriaFetalGemelarComponent,
    AnatomiaFetalGemelarComponent,
  ]
})
export class ObsSegTercGemelarModule { }
